import SAMLSettingsGroups from './SAMLSettingsGroups';
import Toggle from '../../../../ts/components/inputs/Toggle';
import translations from '../../../../ts/translations';
import ClipboardInput from '../../../../ts/components/inputs/ClipboardInput';

import { connect } from 'react-redux';
import {
    changeSecuritySetting,
    changeSAMLSetting,
} from '../../../actions/reduxActions/currentTenant';
import { RUNTIME_URI } from '../../../../ts/constants';
import SAMLCookieSettings from '../../../../ts/components/tenant/saml/SAMLCookieSettings';

const algorithms = [
    { key: 'http://www.w3.org/2001/04/xmldsig-more#rsa-sha256', value: 'SHA256' },
    { key: 'http://www.w3.org/2001/04/xmldsig-more#rsa-sha384', value: 'SHA384' },
    { key: 'http://www.w3.org/2001/04/xmldsig-more#rsa-sha512', value: 'SHA512' },
    { key: 'http://www.w3.org/2000/09/xmldsig#rsa-sha1', value: 'SHA1' },
];

/**
 * @param {Object} SAMLSettings
 * @param {boolean} SAMLSettings.isActive - True if the user has checked Enable SAML Single Sign-On
 * @param {Object} SAMLSettings.settings - These are the SAML settings for the Tenant
 * @param {string} SAMLSettings.tenantId - TenantId to configure SAML SSO for
 * @param {boolean} IsSso - Is the user currently logged in via SAML
 * @param {Function} SAMLSettings.changeSAMLSetting - Changes one of the SAML settings for the Tenant
 * @param {Function} SAMLSettings.changeSecuritySetting - Changes one of the security settings for the Tenant
 * @description Let the user set up an IdP to authenticate to this Tenant
 * following SAML SSO protocol
 */
export const SAMLSettings = ({
    isActive,
    settings,
    tenantId,
    isSso,
    changeSAMLSetting,
    changeSecuritySetting,
}) => {
    const onChangeCertificate = (e) =>
        changeSAMLSetting({
            settingPath: ['certificate'],
            value: e.currentTarget.value,
        });

    const onChangeRedirectUri = (e) =>
        changeSAMLSetting({
            settingPath: ['redirectUri'],
            value: e.currentTarget.value,
        });

    const onChangeAlgorithm = (e) =>
        changeSAMLSetting({
            settingPath: ['algorithm'],
            value: e.currentTarget.value,
        });

    const onChangeLogoutUri = (e) =>
        changeSAMLSetting({
            settingPath: ['logoutUri'],
            value: e.currentTarget.value,
        });

    const onChangeFirstNameClaim = (e) =>
        changeSAMLSetting({
            settingPath: ['claims', 'firstName'],
            value: e.currentTarget.value,
        });

    const onChangeLastNameClaim = (e) =>
        changeSAMLSetting({
            settingPath: ['claims', 'lastName'],
            value: e.currentTarget.value,
        });

    const onChangeEmailClaim = (e) =>
        changeSAMLSetting({
            settingPath: ['claims', 'emailAddress'],
            value: e.currentTarget.value,
        });

    const onChangeGroupClaim = (e) =>
        changeSAMLSetting({
            settingPath: ['groupSettings', 'groupAttribute'],
            value: e.currentTarget.value,
        });

    const onToggleEnabled = () => {
        changeSecuritySetting({
            settingName: 'isSamlEnabled',
            value: !isActive,
        });
    };

    let ssoAlert;
    if (isSso) {
        ssoAlert = (
            <div className="alert alert-warning">
                <strong>Warning:</strong> {translations.SAML_warning}
            </div>
        );
    }

    const SAMLLoginURL = `${RUNTIME_URI}/api/draw/1/authentication/saml/${tenantId}`;

    return (
        <>
            <div className="form-group">
                <label>
                    <Toggle
                        isOn={isActive}
                        onChange={onToggleEnabled}
                        className="enable-SAML"
                        testId="enable-SAML"
                    />
                    {translations.SAML_enable_label}
                </label>
            </div>

            {isActive ? (
                <div className="SAML-settings" data-testid="SAML-settings">
                    {ssoAlert}
                    <div className="form-group">
                        <label>{translations.SAML_login_url_label}</label>
                        <ClipboardInput
                            value={SAMLLoginURL}
                            buttonTitle={translations.copy_saml_login_url_title}
                        />
                        <p className="help-block">
                            {translations.SAML_login_url_help_1}
                            <br />
                            {translations.SAML_login_url_help_2}
                        </p>
                    </div>

                    <div className="form-group">
                        <label htmlFor="SAMLCert">{translations.SAML_certificate_label}</label>
                        <span className="input-required"> *</span>
                        <textarea
                            id="SAMLCert"
                            className="form-control certificate"
                            onChange={onChangeCertificate}
                            rows={12}
                            value={settings?.certificate}
                        />

                        <p className="help-block">{translations.SAML_certificate_help}</p>
                    </div>

                    <div className="form-group">
                        <label htmlFor="saml-algorithm">{translations.SAML_algorithm_label}</label>
                        <select
                            id="saml-algorithm"
                            className="form-control form-control-dynamic"
                            value={settings?.algorithm}
                            onChange={onChangeAlgorithm}
                        >
                            {algorithms
                                ? algorithms.map((algorithm) => (
                                      <option key={algorithm.key} value={algorithm.key}>
                                          {algorithm.value}
                                      </option>
                                  ))
                                : null}
                        </select>

                        <p className="help-block">{translations.SAML_algorithm_help}</p>
                    </div>

                    <div className="form-group">
                        <label htmlFor="saml-redirect-uri">
                            {translations.SAML_redirect_url_label}
                        </label>
                        <span className="input-required"> *</span>
                        <input
                            id="saml-redirect-uri"
                            className="form-control saml-redirect-uri"
                            onChange={onChangeRedirectUri}
                            type="text"
                            value={settings?.redirectUri}
                        />

                        <p className="help-block">{translations.SAML_redirect_url_help}</p>
                    </div>

                    <div className="form-group">
                        <label htmlFor="saml-logout-uri">
                            {translations.SAML_logout_url_label}
                        </label>
                        <input
                            id="saml-logout-uri"
                            className="form-control saml-logout-uri"
                            onChange={onChangeLogoutUri}
                            type="text"
                            value={settings?.logoutUri}
                        />

                        <p className="help-block">{translations.SAML_logout_url_help}</p>
                    </div>

                    <h4>Configuring SAML claims</h4>
                    <div className="form-group">
                        <label htmlFor="saml-firstname-claim">
                            {translations.SAML_firstname_label}
                        </label>

                        <input
                            id="saml-firstname-claim"
                            className="form-control saml-firstname-claim"
                            onChange={onChangeFirstNameClaim}
                            type="text"
                            value={settings?.claims?.firstName}
                        />

                        <p className="help-block">
                            {translations.SAML_firstname_help_1}
                            <br />
                            {translations.SAML_firstname_help_2}
                        </p>
                    </div>

                    <div className="form-group">
                        <label htmlFor="saml-lastname-claim">
                            {translations.SAML_lastname_label}
                        </label>

                        <input
                            id="saml-lastname-claim"
                            className="form-control saml-lastname-claim"
                            onChange={onChangeLastNameClaim}
                            type="text"
                            value={settings?.claims?.lastName}
                        />

                        <p className="help-block">
                            {translations.SAML_lastname_help_1}
                            <br />
                            {translations.SAML_lastname_help_2}
                        </p>
                    </div>

                    <div className="form-group">
                        <label htmlFor="saml-email-claim">{translations.SAML_email_label}</label>

                        <input
                            id="saml-email-claim"
                            className="form-control saml-email-claim"
                            onChange={onChangeEmailClaim}
                            type="text"
                            value={settings?.claims?.emailAddress}
                        />

                        <p className="help-block">
                            {translations.SAML_email_help_1}
                            <br />
                            {translations.SAML_email_help_2}
                        </p>
                    </div>

                    <div className="form-group">
                        <label htmlFor="saml-group-claim">
                            {translations.SAML_groupname_label}
                        </label>

                        <input
                            id="saml-group-claim"
                            className="form-control saml-group-claim"
                            onChange={onChangeGroupClaim}
                            type="text"
                            value={settings?.groupSettings?.groupAttribute}
                        />

                        <p className="help-block">{translations.SAML_groupname_help}</p>
                    </div>

                    <SAMLSettingsGroups settings={settings} />

                    <SAMLCookieSettings settings={settings} />
                </div>
            ) : null}
        </>
    );
};

const mapStateToProps = (state) => ({
    tenantId: state.currentTenant.id,
});

const mapDispatchToProps = {
    changeSecuritySetting,
    changeSAMLSetting,
};

export default connect(mapStateToProps, mapDispatchToProps)(SAMLSettings);
