import classnames from 'classnames';
import DebugTabs from './DebugTabs';
import CallStack from './call-stack/CallStack';

import {
    type ElementRef,
    type ForwardedRef,
    forwardRef,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { connect } from 'react-redux';
import type { NavigateFunction } from 'react-router-dom';
import { updateUrlAndTab as updateUrlAndTabAction } from '../../../../js/actions/reduxActions/tabs';
import { useGraph } from '../../../../js/components/graph/GraphProvider';
import { RUNTIME_URI } from '../../../constants';
import type { AddNotification, Tab } from '../../../types';
import { DebugProvider } from './DebugProvider';
import SwitchFlowConfirmation from './SwitchFlowConfirmation';
import './css/debug.less';
import { useDebugConfig } from './DebugConfigProvider';

interface DebugInitializeEventData {
    source: string;
    type: string;
    payload: {
        flowId: string;
        stateId: string;
    };
}

interface Props {
    flowId: string;
    stateId?: string | null;
    addNotification: AddNotification;
    zoomToMapElement: (elementId: string) => void;
    updateUrlAndTab: (
        {
            key,
            type,
            title,
            elementId,
            tenantId,
        }: {
            key: string;
            type: string;
            title: string | null;
            elementId: string;
            tenantId: string;
        },
        navigate: NavigateFunction,
    ) => void;
    tabs: Tab[];
}

const Debug = (
    {
        flowId,
        addNotification,
        zoomToMapElement,
        updateUrlAndTab,
        tabs,
        stateId: testStateId = null,
    }: Props,
    ref: ForwardedRef<ElementRef<'div'>>,
) => {
    const viewport = document.body;

    const debuggingWindowRef = useRef<Window | null>(null);
    const [stateId, setStateId] = useState<string | null>(testStateId);

    const { debugConfig, setDebugConfig } = useDebugConfig();

    const { startElementId } = useGraph();

    const paneWrapper = useRef<HTMLDivElement>(null);
    const leftPane = useRef<HTMLDivElement>(null);
    const rightPane = useRef<HTMLDivElement>(null);
    const debugPanel = useRef<HTMLDivElement>(null);

    const onClickClose = () => {
        setStateId(null);
    };

    const dragPanelClasses = classnames({
        'debug-panel': true,
        'ex-theme-dark': true,
        bottom: debugConfig.position === 'BOTTOM',
        left: debugConfig.position === 'LEFT',
        right: debugConfig.position === 'RIGHT',
    });

    const dragHandleClasses = classnames({
        'drag-handle': true,
        bottom: debugConfig.position === 'BOTTOM',
        left: debugConfig.position === 'LEFT',
        right: debugConfig.position === 'RIGHT',
    });

    const subDragHandleClasses = classnames({
        'sub-drag-handle': true,
        bottom: debugConfig.position === 'BOTTOM',
        left: debugConfig.position === 'LEFT',
        right: debugConfig.position === 'RIGHT',
    });

    const columnWrapperClasses = classnames({
        'debug-panel-content': true,
        vertical: debugConfig.position === 'LEFT' || debugConfig.position === 'RIGHT',
    });

    const columnClasses = classnames({
        'column-pane': true,
        vertical: debugConfig.position === 'LEFT' || debugConfig.position === 'RIGHT',
    });

    const rightColumnClasses = classnames({
        'column-pane': true,
        'right-column-pane': true,
        vertical: debugConfig.position === 'LEFT' || debugConfig.position === 'RIGHT',
    });

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    const resizePane = useCallback(() => {
        const limits = {
            horizontal: {
                min: 25,
                max: 65,
            },
            vertical: {
                min: 25,
                max: 75,
            },
        };

        const flowCanvas = typeof ref !== 'function' ? ref?.current : null;

        window.addEventListener('mouseup', mouseup);
        window.addEventListener('mousemove', mouseMove);

        const resizeMapper: { [key: string]: (e: MouseEvent) => void } = {
            BOTTOM: resizeHorizontally,
            LEFT: resizeVertically,
            RIGHT: resizeVertically,
        };

        function mouseMove(e: MouseEvent) {
            resizeMapper[debugConfig.position](e);
        }

        function percentage(partialValue: number, totalValue: number) {
            return (100 * partialValue) / totalValue;
        }

        let leftBottomPane = leftPane.current?.style.width as string;
        let rightTopPane = rightPane.current?.style.width as string;

        function resizeHorizontally(e: MouseEvent) {
            if (paneWrapper.current?.clientWidth && leftPane.current && rightPane.current) {
                const offset = viewport.clientWidth - paneWrapper.current.clientWidth;
                const newWidth = e.x - offset;
                const per = percentage(newWidth, paneWrapper.current.clientWidth);

                if (per > limits.horizontal.min && per < limits.horizontal.max) {
                    leftPane.current.style.width = `${per}%`;
                    rightPane.current.style.width = `${100 - per}%`;

                    leftBottomPane = `${per}%`;
                    rightTopPane = `${100 - per}%`;
                }
            }
        }

        function resizeVertically(e: MouseEvent) {
            if (paneWrapper.current && leftPane.current && rightPane.current && flowCanvas) {
                const offset = viewport.clientHeight - flowCanvas.clientHeight;
                const newHeight = flowCanvas.clientHeight - (e.y - offset + 26);
                const per = percentage(newHeight, paneWrapper.current.clientHeight);

                if (per > limits.vertical.min && per < limits.vertical.max) {
                    rightPane.current.style.height = `${per}%`;
                    leftPane.current.style.height = `${100 - per}%`;

                    leftBottomPane = `${per}%`;
                    rightTopPane = `${100 - per}%`;
                }
            }
        }

        function mouseup() {
            window.removeEventListener('mousemove', mouseMove);
            window.removeEventListener('mouseup', mouseup);

            if (debugConfig.position === 'BOTTOM') {
                setDebugConfig({
                    ...debugConfig,
                    paneDimensions: {
                        ...debugConfig.paneDimensions,
                        BOTTOM: { LEFT: { width: leftBottomPane }, RIGHT: { width: rightTopPane } },
                    },
                });
            }

            if (debugConfig.position === 'LEFT') {
                setDebugConfig({
                    ...debugConfig,
                    paneDimensions: {
                        ...debugConfig.paneDimensions,
                        LEFT: { LEFT: { height: rightTopPane }, RIGHT: { height: leftBottomPane } },
                    },
                });
            }

            if (debugConfig.position === 'RIGHT') {
                setDebugConfig({
                    ...debugConfig,
                    paneDimensions: {
                        ...debugConfig.paneDimensions,
                        RIGHT: {
                            LEFT: { height: rightTopPane },
                            RIGHT: { height: leftBottomPane },
                        },
                    },
                });
            }
        }
    }, [debugConfig, debugConfig.position]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    const resizePanel = useCallback(() => {
        const limits = {
            vertical: {
                min: 200,
                max: 800,
            },
            horizontal: {
                min: 600,
                max: 900,
            },
        };

        const flowCanvas = typeof ref !== 'function' ? ref?.current : null;

        const resizeMapper: { [key: string]: (e: MouseEvent) => void } = {
            BOTTOM: resizeVertically,
            LEFT: resizeHorizontallyLeft,
            RIGHT: resizeHorizontallyRight,
        };

        window.addEventListener('mouseup', mouseup);
        window.addEventListener('mousemove', mouseMove);

        let height = debugPanel.current?.style.minHeight as string;
        let width = debugPanel.current?.style.width as string;

        function mouseMove(e: MouseEvent) {
            resizeMapper[debugConfig.position](e);
        }

        function resizeVertically(e: MouseEvent) {
            if (debugPanel.current && flowCanvas) {
                const offset = viewport.clientHeight - flowCanvas.clientHeight;
                const newHeight = flowCanvas.clientHeight - (e.y - offset + 26);

                if (newHeight > limits.vertical.min && newHeight < limits.vertical.max) {
                    debugPanel.current.style.minHeight = `${newHeight}px`;
                    height = `${newHeight}px`;
                }
            }
        }

        function resizeHorizontallyLeft(e: MouseEvent) {
            if (debugPanel.current && flowCanvas) {
                const offset = viewport.clientWidth - flowCanvas.clientWidth;
                const newWidth = e.x - offset;

                if (newWidth > limits.horizontal.min && newWidth < limits.horizontal.max) {
                    debugPanel.current.style.width = `${newWidth}px`;
                    width = `${newWidth}px`;
                }
            }
        }

        function resizeHorizontallyRight(e: MouseEvent) {
            if (debugPanel.current && flowCanvas) {
                const newWidth = viewport.clientWidth - e.x;

                if (newWidth > limits.horizontal.min && newWidth < limits.horizontal.max) {
                    debugPanel.current.style.width = `${newWidth}px`;
                    width = `${newWidth}px`;
                }
            }
        }

        function mouseup() {
            window.removeEventListener('mousemove', mouseMove);
            window.removeEventListener('mouseup', mouseup);

            if (debugConfig.position === 'BOTTOM') {
                const currentDimensions = debugConfig.dimensions[debugConfig.position];
                setDebugConfig({
                    ...debugConfig,
                    dimensions: {
                        ...debugConfig.dimensions,
                        BOTTOM: { ...currentDimensions, minHeight: height },
                    },
                });
            }

            if (debugConfig.position === 'LEFT') {
                const currentDimensions = debugConfig.dimensions[debugConfig.position];
                setDebugConfig({
                    ...debugConfig,
                    dimensions: {
                        ...debugConfig.dimensions,
                        LEFT: { ...currentDimensions, width },
                    },
                });
            }

            if (debugConfig.position === 'RIGHT') {
                const currentDimensions = debugConfig.dimensions[debugConfig.position];
                setDebugConfig({
                    ...debugConfig,
                    dimensions: {
                        ...debugConfig.dimensions,
                        RIGHT: { ...currentDimensions, width },
                    },
                });
            }
        }
    }, [debugConfig, debugConfig.position]);

    const closeDebuggingWindow = () => {
        if (debuggingWindowRef.current !== null) {
            debuggingWindowRef.current.close();
        }
    };

    // Subscribe to the debugging window initialize event
    useEffect(() => {
        const onDebuggingWindowMessage = (event: MessageEvent<DebugInitializeEventData>) => {
            if (event.origin !== RUNTIME_URI || event.data.source !== 'runtime-ui') {
                return;
            }
            if (
                event.data.type === 'initialize' &&
                event.data.payload.flowId === flowId &&
                event.data.payload.stateId
            ) {
                debuggingWindowRef.current = event.source as Window;
                setStateId(event.data.payload.stateId);
            }
        };

        window.addEventListener('message', onDebuggingWindowMessage);

        return () => {
            window.removeEventListener('message', onDebuggingWindowMessage);
        };
    }, [flowId]);

    // When unmounting the debugger close the debugging browser window
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => closeDebuggingWindow, []);

    // When the debugger has no state id to observe close the debugging browser window
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (stateId === null) {
            closeDebuggingWindow();
        }
    }, [stateId]);

    // Subscribe to the debugging window close event
    useEffect(() => {
        const onDebuggingWindowClose = () => {
            setStateId(null);
        };

        if (!stateId || debuggingWindowRef.current === null) {
            return;
        }

        debuggingWindowRef.current.addEventListener('beforeunload', onDebuggingWindowClose);

        return () => {
            if (debuggingWindowRef.current !== null) {
                debuggingWindowRef.current.addEventListener('beforeunload', onDebuggingWindowClose);
            }
        };
    }, [stateId]);

    if (!stateId) {
        return null;
    }

    return (
        <DebugProvider
            flowId={flowId}
            stateId={stateId}
            startElementId={startElementId}
            addNotification={addNotification}
            zoomToMapElement={zoomToMapElement}
            updateUrlAndTab={updateUrlAndTab}
            tabs={tabs}
        >
            <div
                style={debugConfig.dimensions[debugConfig.position]}
                ref={debugPanel}
                className={dragPanelClasses}
                data-testid="debugger"
            >
                <button onMouseDown={resizePanel} className={dragHandleClasses} type="button" />
                <div className="full-width full-height">
                    <div ref={paneWrapper} className={columnWrapperClasses}>
                        <div
                            style={debugConfig.paneDimensions[debugConfig.position]['LEFT']}
                            ref={leftPane}
                            className={columnClasses}
                            data-testid="left-pane"
                        >
                            <CallStack />
                        </div>
                        <div
                            style={debugConfig.paneDimensions[debugConfig.position]['RIGHT']}
                            ref={rightPane}
                            className={rightColumnClasses}
                            data-testid="right-pane"
                        >
                            <button
                                className={subDragHandleClasses}
                                onMouseDown={resizePane}
                                type="button"
                            />
                            <DebugTabs onClose={onClickClose} />
                        </div>
                    </div>
                </div>
            </div>
            <SwitchFlowConfirmation />
        </DebugProvider>
    );
};

const mapStateToProps = ({ tabs }: { tabs: Tab[] }) => {
    return {
        tabs,
    };
};

const mapDispatchToProps = {
    updateUrlAndTab: updateUrlAndTabAction,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    forwardRef(Debug),
);
