import { useNavigate } from 'react-router-dom';
import { toggleSidebarCollapsed } from '../../actions/reduxActions/sidebar';
import { groups } from '../../../ts/config/admin';
import { HOSTS, TAB_TYPES } from '../../../ts/constants';
import { isNullOrEmpty } from '../../../ts/utils/guard';
import { generateRouteUrl, generateTabKey } from '../../../ts/utils/routing';
import { connect } from 'react-redux';
import Sidebar from '../sidebar';
import { useAuth } from '../../../ts/components/AuthProvider';
import ComponentWithTooltip from '../../../ts/components/generic/tooltip/ComponentWithTooltip';
import TooltipWithHeader from '../../../ts/components/generic/tooltip/TooltipWithHeader';
import { getFlag } from '../../../ts/utils/flags';

const AdminSidebar = ({ toggleCollapsed, tabs }) => {
    const { tenant } = useAuth();

    const navigate = useNavigate();

    const tenantId = tenant?.id;

    const isFederal = window.location.hostname === HOSTS.federal;

    const openAdminTab = (tabType) => {
        const route = generateRouteUrl({
            tabType,
            tenantId,
            options: {
                tabKey: generateTabKey(),
            },
        });
        navigate(route);
    };

    const filterNavigationItems = (item) => {
        if (item.type === TAB_TYPES.themes && !tenant?.tenantSettings?.themes) {
            return false;
        }

        if (item.type === TAB_TYPES.players && (tenant?.tenantSettings?.themes ?? false)) {
            return false;
        }

        if (item.type === TAB_TYPES.anomalyDetection) {
            return getFlag('ANOMLY');
        }

        if (item.type === TAB_TYPES.assets && isFederal) {
            return false;
        }
        return true;
    };

    const isItemActive = (item) => {
        const activeTab = tabs.find((tab) => tab.isActive);

        if (isNullOrEmpty(activeTab)) {
            return false;
        }

        if (activeTab.type === TAB_TYPES.flow && item.type === 'flows') {
            return true;
        }

        if (
            (activeTab.type === TAB_TYPES.page || activeTab.type === TAB_TYPES.newPage) &&
            item.type === 'pages'
        ) {
            return true;
        }

        return activeTab.type === item.type;
    };

    const sidebarItems = groups?.map((group) => {
        const items = [
            <div className="heading" key={group.name}>
                {group.label}
            </div>,
        ];

        if (group.items) {
            return items.concat(
                group.items.filter(filterNavigationItems).map((item) => {
                    let classes = ['sidebar-btn'];
                    let element = null;

                    if (isItemActive(item)) {
                        classes.push('active');
                    }

                    classes = classes.concat(item.classes);

                    if (item.href && item.href !== '') {
                        element = (
                            <a
                                key={item.type}
                                className={classes.join(' ')}
                                href={item.href}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <div className="glyphicon">
                                    <item.icon width="20" height="20" />
                                </div>
                                <div className="sidebar-item-text">{item.title}</div>
                            </a>
                        );
                    } else {
                        element = (
                            <>
                                <button
                                    key={item.type}
                                    className={classes.join(' ')}
                                    onClick={() => {
                                        if (item.action) {
                                            item.action(item.type);
                                        } else {
                                            openAdminTab(item.type);
                                        }
                                    }}
                                    type="button"
                                >
                                    <div className="glyphicon">
                                        <item.icon width="20" height="20" />
                                    </div>
                                    <div className="sidebar-item-text">{item.title}</div>
                                </button>
                            </>
                        );
                    }

                    if (item.description) {
                        return (
                            <ComponentWithTooltip
                                key={`tooltip-${item.type}`}
                                trigger={['hover', 'focus']}
                                tooltipPlacement="right"
                                fadeTime={0.1}
                                tooltipContent={
                                    <TooltipWithHeader
                                        header={item.title}
                                        content={item.description}
                                    />
                                }
                                wrapperClass="full-width"
                                tooltipClass="graph-tooltip"
                                tooltipArrowClass="graph-tooltip-arrow"
                                clearance={10}
                                arrowClearance={7}
                            >
                                {element}
                            </ComponentWithTooltip>
                        );
                    }

                    return element;
                }),
            );
        }
    });

    return (
        <Sidebar className="sidebar-admin" onCollapse={toggleCollapsed}>
            {sidebarItems}
        </Sidebar>
    );
};

const mapStateToProps = ({ tabs }) => ({ tabs });

const mapDispatchToProps = {
    toggleCollapsed: toggleSidebarCollapsed,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminSidebar);
