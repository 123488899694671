import { isArray, isObject, isString } from './guard';

// Escape string for use in a RegEx
// https://stackoverflow.com/questions/3561493/is-there-a-regexp-escape-function-in-javascript/3561711#3561711
export const escapeStringForRegex = (str: string) => str.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

export const stringContains = (string: string | null, searchTerm: string, caseSensitive = true) => {
    if (!string) {
        return false;
    }

    if (caseSensitive) {
        return string.includes(searchTerm);
    }

    return string.toLowerCase().includes(searchTerm.toLowerCase());
};

export const replaceAll = (value: string, substring: string, newSubstring: string) =>
    value.replace(RegExp(escapeStringForRegex(substring), 'g'), newSubstring);

export const stringReplace = (template: string, ...replacementParameters: unknown[]) =>
    replacementParameters.length > 0
        ? (replacementParameters.reduce((reduceString, parameter) => {
              // / \${.*?} /
              // \$ matches the character $ literally (case sensitive)
              // { matches the character { literally (case sensitive)
              // .*? matches any character (except for line terminators)
              // *? Quantifier — Matches between zero and unlimited times, as few times as possible, expanding as needed (lazy)
              // } matches the character } literally (case sensitive)
              const valueReferenceRegEx = /\${.*?}/;

              // This function accepts different types of parameters
              // e.g. stringReplace('${test}','replaceFirstReference','replaceSecondReference')

              if (isString(reduceString) && isString(parameter)) {
                  return reduceString.replace(valueReferenceRegEx, parameter);
              }

              // e.g. stringReplace('${test}',['replaceFirstReference','replaceSecondReference'])
              if (isArray(parameter)) {
                  return parameter.reduce((reduceStringList, value) => {
                      if (!isString(reduceStringList)) {
                          return '';
                      }

                      return reduceStringList.replace(valueReferenceRegEx, value);
                  }, reduceString);
              }

              // e.g. stringReplace('${test}',{test,'replace'})
              if (isObject(parameter)) {
                  return Object.entries(parameter).reduce((reduceStringObject, [key, value]) => {
                      if (!isString(reduceStringObject)) {
                          return '';
                      }

                      return replaceAll(
                          reduceStringObject,
                          `\${${key}}`,
                          isString(value) ? value : String(value),
                      );
                  }, reduceString);
              }

              // any invalid case
              return reduceString;
          }, template) as string)
        : template;

export const safeToLower = <T>(maybeString: T) =>
    typeof maybeString === 'string' ? maybeString.toLowerCase() : maybeString;

export const isEqualCaseInsensitive = (left: string, right: string) => {
    return left.toLowerCase() === right.toLowerCase();
};

export const generateRandomString = (length: number) => {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i += 1) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
};
