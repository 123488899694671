import type { ChangeEventHandler } from 'react';
import { getDateString, getTimeString } from '../../utils/date';

interface DateTimeInputProps {
    className?: string;
    onChange: (value: string) => void;
    value: string;
    isUTC?: boolean;
}

const DateTimeInput = ({ className, onChange, value, isUTC = false }: DateTimeInputProps) => {
    const initialDateValue = getDateString(new Date(value), isUTC);
    const initialTimeValue = getTimeString(new Date(value), isUTC);

    const defaultValue =
        initialDateValue && initialTimeValue ? `${initialDateValue}T${initialTimeValue}` : '';

    const updateDateTime: ChangeEventHandler<HTMLInputElement> = ({
        target: { value, valueAsNumber },
    }) => {
        if (value === '') {
            onChange('');
            return;
        }

        if (isUTC) {
            const dateUTC = new Date(valueAsNumber);
            onChange(dateUTC.toISOString());
        } else {
            const date = new Date(value);
            onChange(date.toISOString());
        }
    };

    return (
        <input
            data-testid="input-datetime"
            className={className}
            defaultValue={defaultValue}
            onChange={updateDateTime}
            type="datetime-local"
        />
    );
};

export default DateTimeInput;
