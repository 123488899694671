import {
    ExAlertBanner,
    ExEmptyState,
    ExPagination,
    ExStructuredList,
    ExStructuredListBody,
    ExStructuredListCol,
    ExStructuredListRow,
} from '@boomi/exosphere';
import { format } from 'date-fns';
import { addMonths, endOfDay, startOfDay } from 'date-fns/fp';
import flow from 'lodash/fp/flow';
import { stringify } from 'query-string';
import { useEffect, useState } from 'react';
import '../../../../css/audit-logs.less';
import { stringReplace } from '../../../ts/utils/string';
import CopyableText from '../../../ts/components/generic/CopyableText';
import translations from '../../../ts/translations';
import { getAuditLogs, downloadAuditLogsCsv } from '../../../ts/sources/logs';
import AuditLogsFilters, { PUBLIC_USER, humanizeAuditUser } from './AuditLogsFilters';
import { eventTypes } from './EventTypes';
import { getAllUsersForCurrentTenant } from '../../../ts/sources/user';
import {
    AUDIT_TIME_PERIODS,
    DATE_FNS_FORMAT_NO_ZONE,
} from '../../../ts/components/auditLogs/constants';

const AuditLogs = ({ tenantId }) => {
    const [logs, setLogs] = useState();
    const [users, setUsers] = useState([]);
    const [pageSize, setPageSize] = useState(15);
    const [usersFilter, setUsersFilter] = useState([]);
    const [typesFilter, setTypesFilter] = useState([]);
    const [periodFilter, setPeriodFilter] = useState(AUDIT_TIME_PERIODS.day);
    const [dateFilter, setDateFilter] = useState(new Date());
    const [errorMessage, setErrorMessage] = useState(null);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const loadInitialLogs = async () => {
            try {
                const tenantUsers = await getAllUsersForCurrentTenant();
                tenantUsers.push(PUBLIC_USER);
                setUsers(tenantUsers);
                await loadLogs(1, pageSize, periodFilter, dateFilter, typesFilter, usersFilter);
            } catch (error) {
                setErrorMessage(error?.message);
            }
        };

        loadInitialLogs();
    }, []);

    const typeOptions = Object.entries(eventTypes).map(([key, value]) => ({
        label: value.name,
        value: key,
        logTemplate: value.logTemplate,
    }));

    const getLogRecords = async (detail) => {
        if (detail.selectedPage && detail.pageSize) {
            setPageSize(detail.pageSize);
            await loadLogs(
                detail.selectedPage,
                detail.pageSize,
                periodFilter,
                dateFilter,
                typesFilter,
                usersFilter,
            );
        }
    };

    const updatePeriodFilter = async (period) => {
        setPeriodFilter(period);
        await loadLogs(1, pageSize, period, dateFilter, typesFilter, usersFilter);
    };

    const updateDateFilter = async (date) => {
        setDateFilter(date);
        await loadLogs(1, pageSize, periodFilter, date, typesFilter, usersFilter);
    };

    const updateTypeFilter = async (types) => {
        setTypesFilter(types);
        await loadLogs(1, pageSize, periodFilter, dateFilter, types, usersFilter);
    };

    const updateUserFilter = async (users) => {
        setUsersFilter(users);
        await loadLogs(1, pageSize, periodFilter, dateFilter, typesFilter, users);
    };

    const refreshLogs = async () => {
        await loadLogs(1, pageSize, periodFilter, dateFilter, typesFilter, usersFilter);
    };

    const loadLogs = async (pageNumber, pageSize, period, date, types, users) => {
        try {
            const from = calculateFromDate(date, period.value);
            const to = calculateToDate(date, period.value);

            const parameters = {
                from: from ? format(from, DATE_FNS_FORMAT_NO_ZONE) : null,
                to: format(to, DATE_FNS_FORMAT_NO_ZONE),
                type: types.map((type) => type.value),
                user: users.map((user) => user.value),
                page: pageNumber,
                pageSize,
                orderBy: 'occurredAt',
                orderDirection: 'DESC',
            };

            setLogs(await getAuditLogs(stringify(parameters)));
            setErrorMessage(null);
        } catch (error) {
            setErrorMessage(error?.message);
        }
    };

    const downloadCsv = async () => {
        try {
            const from = calculateFromDate(dateFilter, periodFilter.value);
            const to = calculateToDate(dateFilter, periodFilter.value);

            const parameters = {
                from: from ? format(from, DATE_FNS_FORMAT_NO_ZONE) : null,
                to: format(to, DATE_FNS_FORMAT_NO_ZONE),
                type: typesFilter.map((type) => type.value),
                user: usersFilter.map((user) => user.value),
                page: 1,
                pageSize,
                orderBy: 'occurredAt',
                orderDirection: 'DESC',
            };

            return await downloadAuditLogsCsv(stringify(parameters));
        } catch (error) {
            setErrorMessage(error?.message);
        }
    };

    const loadLogTemplate = (log) => {
        let logMessage = translations.AUDIT_event_default;

        const event = typeOptions.find(({ value }) => value === log.type);
        if (event?.logTemplate) {
            logMessage = event.logTemplate;
        }

        return stringReplace(logMessage, {
            datetime: format(new Date(log.occurredAt), 'LLL do yyyy, k:mm:ss'),
            user: humanizeAuditUser(log.user),
            eventType: log.type,
            flowName: log.flow ? log.flow.developerName : null,
            newUserFirstName: log?.data?.firstName ?? log?.data?.data?.Member?.firstName,
            newUserLastName: log?.data?.lastName ?? log?.data?.data?.Member?.lastName,
            newUserEmail: log?.data?.email ?? log?.data?.data?.Member?.email,
            oldRoleName: log?.data?.data?.Role?.from ?? log?.data?.role?.from,
            newRoleName: log?.data?.data?.Role?.to ?? log?.data?.role?.to,
            releaseName: log.data?.releaseName,
            environmentName: log.data?.environmentName,
            variableName: log.data?.environmentVariableName,
            themeName: log.data?.themeName,
        });
    };

    const calculateFromDate = (filterDate, period) => {
        switch (period) {
            case AUDIT_TIME_PERIODS.day.value:
                return flow(startOfDay())(new Date(filterDate));
            case AUDIT_TIME_PERIODS.month.value:
                return flow(addMonths(-1), startOfDay())(new Date());
            case AUDIT_TIME_PERIODS.threeMonths.value:
                return flow(addMonths(-3), startOfDay())(new Date());
            case AUDIT_TIME_PERIODS.sixMonths.value:
                return flow(addMonths(-6), startOfDay())(new Date());
            case AUDIT_TIME_PERIODS.twelveMonths.value:
                return flow(addMonths(-12), startOfDay())(new Date());
        }
    };

    const calculateToDate = (filterDate, period) => {
        if (AUDIT_TIME_PERIODS.day.value === period) {
            return flow(endOfDay())(new Date(filterDate));
        }
        return flow(endOfDay())(new Date());
    };

    let listContent;
    if (errorMessage) {
        listContent = (
            <ExAlertBanner open={errorMessage} type="status-fail">
                {errorMessage}
            </ExAlertBanner>
        );
    } else if (logs?.items?.length > 0) {
        listContent = (
            <ExStructuredList className="ex-mt-medium overflow-y-scroll">
                <ExStructuredListBody>
                    {logs?.items.map((log) => (
                        <ExStructuredListRow key={log.id}>
                            <ExStructuredListCol class="action-column">
                                <div>
                                    {loadLogTemplate(log)}
                                    <CopyableText
                                        copyableText={
                                            loadLogTemplate(log) +
                                            (log.data?.comments ? ` - ${log.data?.comments}` : '')
                                        }
                                        hasCopyButton={true}
                                        showCopyText={false}
                                    />
                                </div>
                                {log.data.comments === null ? (
                                    ''
                                ) : (
                                    <div className="ex-fs-micro">{log.data.comments}</div>
                                )}
                            </ExStructuredListCol>
                        </ExStructuredListRow>
                    ))}
                </ExStructuredListBody>
                <ExPagination
                    totalitems={logs?._meta.total}
                    pagesize={logs?._meta.pageSize ?? 15}
                    selectedpage={logs?._meta.page}
                    onChange={(e) => getLogRecords(e.detail)}
                    pageSizeOptions={[15, 25, 50, 100]}
                />
            </ExStructuredList>
        );
    } else {
        listContent = <ExEmptyState text="" />;
    }

    return (
        <div className="admin-page">
            <div className="editor-container macro-editor">
                <h1>{translations.AUDIT_title}</h1>
                <AuditLogsFilters
                    tenantId={tenantId}
                    users={users}
                    filterDate={dateFilter}
                    filterPeriod={periodFilter}
                    filterTypes={typesFilter}
                    filterUsers={usersFilter}
                    fetchLogs={refreshLogs}
                    updateTimePeriodFilter={updatePeriodFilter}
                    updateDateFilter={updateDateFilter}
                    updateLogTypeFilter={updateTypeFilter}
                    updateUserFilter={updateUserFilter}
                    downloadCsv={downloadCsv}
                />
                {listContent}
            </div>
        </div>
    );
};

export default AuditLogs;
