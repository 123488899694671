import classNames from 'classnames';
import type { ReactNode } from 'react';

interface Props {
    label?: string;
    labelId?: string;
    htmlFor?: string;
    isRequired?: boolean;
    isValid?: boolean;
    validationMessage?: string;
    showValidation?: boolean;
    children?: ReactNode;
    className?: string;
    indicatorType?: 'symbol' | 'text';
}

const FormGroup = ({
    label,
    labelId: labelIdProp = '',
    htmlFor,
    isRequired,
    isValid,
    validationMessage,
    showValidation,
    children,
    className,
    indicatorType = 'symbol',
}: Props) => {
    const containerClassName = classNames('form-group', className, {
        'has-error': !isValid && showValidation,
    });

    const labelId = labelIdProp ?? `${htmlFor ?? ''}-input-label`;

    return (
        <div className={containerClassName}>
            {label && (
                <label id={labelId} data-testid={labelId} htmlFor={htmlFor}>
                    {label}
                </label>
            )}
            {isRequired && (
                <span data-testid={`${htmlFor ?? ''}-input-required`} className="input-required">
                    {' '}
                    {indicatorType === 'symbol' ? '*' : '(required)'}
                </span>
            )}
            {children}
            {!isValid && showValidation && (
                <span
                    data-testid={`${htmlFor ?? ''}-validation`}
                    className="help-block error-state"
                >
                    {validationMessage}
                </span>
            )}
        </div>
    );
};

export default FormGroup;
