import { type ElementRef, useEffect, useRef, useState } from 'react';
import type { NotifyError } from '../../types';
import { getStateErrors } from '../../sources/dashboard';
import type { StateError } from '../../types/dashboard';
import translations from '../../translations';
import Table, { type TableColumnList } from '../generic/Table';
import {
    convertDateFromUnixTime,
    convertDateToUnixTime,
    formatChartDate,
} from '../../utils/dashboard';
import { groupBy } from 'ramda';
import { Chart, type ChartConfiguration } from 'chart.js';

interface Props {
    notifyError: NotifyError;
}

interface LineData {
    x: string;
    y: number;
}

interface FormatedChartData {
    data: LineData[];
    label: string;
    tension: number;
}

const DeveloperDashboardErrorsSummary = ({ notifyError }: Props) => {
    const [stateErrors, setStateErrors] = useState<StateError[]>([]);
    const canvasRef = useRef<ElementRef<'canvas'>>(null);

    useEffect(() => {
        getStateErrors('day')
            .then((response) => setStateErrors(response))
            .catch(notifyError);
    }, [notifyError]);

    useEffect(() => {
        // Pass the stateErrors in to keep the function pure.
        const getChartData = (_stateErrors: StateError[]) => {
            const formattedChartData: FormatedChartData[] = [];

            const toDate = new Date();
            const fromDate = new Date(toDate); // Copy the toDate and then subtract a day off of it.
            fromDate.setDate(fromDate.getDate() - 1);
            fromDate.setMinutes(0, 0, 0); // Remove the minutes or some data ends up in the next time block.
            const hourInSeconds = 3600;

            // Group all the stateErrors by flowId so we have key, value pairs of <Guid (FlowId), StateErrors[]>.
            const allGroupedData = groupBy((a) => a.flowId, _stateErrors);

            // Each key represents a different line as data is on a per flow basis.
            for (const key in allGroupedData) {
                const lineData: LineData[] = [];
                const groupedData = allGroupedData[key];

                // Loop through a day by an hour at a time, for each flow id.
                // We get 0 entries on the Y-axis when there isn't grouped data, filling out the full line across the X-axis.
                for (
                    let time = convertDateToUnixTime(fromDate);
                    time <= convertDateToUnixTime(toDate);
                    time += hourInSeconds
                ) {
                    // Filter out all data that isn't within an hour range.
                    const dataWithinTimeRange = groupedData.filter((d) => {
                        const dataUnix = convertDateToUnixTime(d.dateTime);
                        return dataUnix > time && dataUnix < time + hourInSeconds;
                    });

                    // Use the start of the time range as the X-axis and the total number of entries (how many errors there are) for the Y-axis.
                    lineData.push({
                        x: formatChartDate(convertDateFromUnixTime(time), 'day'),
                        y: dataWithinTimeRange.length,
                    });
                }

                // This structure represents a single line on the chart.
                formattedChartData.push({
                    data: lineData, // An array of points within the line (each X-axis step).
                    label: groupedData[0].flowName, // All the entries for a group of data share the same Flow name so we can just use the first.
                    tension: 0.3, // This is how curvy the line is.
                });
            }

            return formattedChartData;
        };

        const context = canvasRef.current?.getContext('2d');

        if (!context) {
            return;
        }

        const chartConfig: ChartConfiguration<'line', { x: string; y: number }[]> = {
            type: 'line',
            data: { datasets: getChartData(stateErrors) },
            options: {
                responsive: true,
                elements: { point: { radius: 2, hoverRadius: 5, hitRadius: 6 } }, // This can be adjusted based on feedback.
            },
        };

        const chart = new Chart(context, chartConfig);

        // Cleanup the chart to prevent it from wildly animating when switching away and then back to Dashboard tab.
        return () => {
            chart.destroy();
        };
    }, [stateErrors]);

    const columns: TableColumnList<StateError> = [
        {
            renderHeader: () => translations.COMMON_TABLE_message,
            renderCell: ({ item }) => <span title={item.message}>{item.message}</span>,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_status_code,
            renderCell: ({ item }) => item.statusCode,
            size: '6rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_flow_name,
            renderCell: ({ item }) => <span title={item.flowName}>{item.flowName}</span>,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_map_element_name,
            renderCell: ({ item }) => item.mapElementName,
        },
        {
            renderHeader: () => translations.DASHBOARD_time_of_error,
            renderCell: ({ item }) => new Date(item.dateTime).toLocaleString(navigator.language),
            size: '13rem',
        },
    ];

    const getTableData = (_stateErrors: StateError[]) =>
        // Sort by date and cut off all elements past 5.
        _stateErrors
            .sort((a, b) => {
                if (a.dateTime < b.dateTime) {
                    return 1;
                }

                if (a.dateTime > b.dateTime) {
                    return -1;
                }

                return 0;
            })
            .slice(0, 5);

    return (
        <>
            <span className="title-bar">
                <h2>{translations.DASHBOARD_errors_summary_header}</h2>
            </span>
            <div className="developer-dash-wrapper">
                <div className="developer-dash-chart-wrapper">
                    <div className="dash-chart">
                        <canvas ref={canvasRef} data-testid="chart-canvas" />
                    </div>
                </div>
                <div className="developer-dash-table-wrapper">
                    <h2 className="developer-dash-table-header">
                        {translations.DASHBOARD_top_errors}
                    </h2>
                    <Table
                        columns={columns}
                        items={getTableData(stateErrors)}
                        rowClassName={() => 'generic-row generic-row-tall'}
                    />
                </div>
            </div>
        </>
    );
};

export default DeveloperDashboardErrorsSummary;
