import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ButtonIcon from '../../../../ts/components/buttons/ButtonIcon';
import translations from '../../../../ts/translations';
import { TAB_TYPES, DIRECTION } from '../../../../ts/constants';
import ConfirmModal from '../../../../ts/components/generic/modal/ConfirmModal';
import FormGroup from '../../../../ts/components/generic/FormGroup';
import Modal from '../../../../ts/components/generic/modal/GenericModal';
import Table from '../../../../ts/components/generic/Table';
import SearchInput from '../../SearchInput';
import Sortable from '../../../../ts/components/generic/Sortable';
import { useEnvironmentsProviders } from './EnvironmentsProvider';
import Toggle from '../../../../ts/components/inputs/Toggle';
import { isNullOrEmpty } from '../../../../ts/utils/guard';
import { stringReplace } from '../../../../ts/utils/string';

const EnvironmentVariables = ({ environmentId, modalContainerRef, tabs }) => {
    const [showVariableModal, setShowVariableModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [id, setId] = useState(null);
    const [name, setName] = useState(null);
    const [value, setValue] = useState(null);
    const [isSecret, setIsSecret] = useState(false);
    const [isSecretToggleDisabled, setIsSecretToggleDisabled] = useState(false);
    const [valid, setValid] = useState(null);
    const [sortBy, setSortBy] = useState('name');
    const [sortDirection, setSortDirection] = useState(DIRECTION.asc.toUpperCase());
    const [paging, updatePaging] = useState({
        page: 1,
        pageSize: 20,
        total: 0,
        searchTerm: '',
    });
    const {
        environmentVariablesLoading,
        environmentVariables,
        getEnvironmentVariables,
        saveEnvironmentVariable,
        deleteEnvironmentVariable,
    } = useEnvironmentsProviders();

    useEffect(() => {
        const environmentTab = tabs.find((t) => t.type === TAB_TYPES.environment && t.isActive);
        if (!isNullOrEmpty(environmentTab)) {
            loadEnvironmentVariables(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- Treat warnings as errors, fix later
    }, [tabs]);

    const loadEnvironmentVariables = async (page) => {
        const request = {
            searchTerm: paging.searchTerm,
            pageSize: paging.pageSize,
            page: page,
            orderBy: sortBy,
            orderDirection: sortDirection,
        };

        const results = await getEnvironmentVariables(environmentId, request);
        updatePaging({
            ...paging,
            total: results._meta.total,
            pageSize: results._meta.pageSize,
            page: results._meta.page,
        });
    };

    const onSave = async () => {
        const variable = {
            id: id,
            name: name,
            value: value,
            isSecret: isSecret,
        };

        await saveEnvironmentVariable(environmentId, variable);
        setShowVariableModal(false);
        await loadEnvironmentVariables(1, paging.orderBy, paging.orderDirection);
    };

    const resetPaging = () => {
        updatePaging({
            ...paging,
            page: 1,
            total: 0,
            searchTerm: '',
        });
        setSortBy('name');
        setSortDirection(DIRECTION.asc.toUpperCase());
    };

    const resetForm = () => {
        setId(null);
        setName('');
        setValue('');
        setIsSecret(false);
        setIsSecretToggleDisabled(false);
    };

    const onNameChange = (newName) => {
        setName(newName);
        setValid(newName && value);
    };

    const onValueChange = (newValue) => {
        setValue(newValue);
        setValid(name && newValue);
    };

    const onSearchTermFilterChange = (searchTerm) => {
        updatePaging({
            ...paging,
            searchTerm: searchTerm,
            page: 1,
        });
        loadEnvironmentVariables(1);
    };

    const onSort = ({ orderBy, direction }) => {
        setSortBy(orderBy);
        setSortDirection(direction);
        loadEnvironmentVariables(1);
    };

    const deleteVariable = async () => {
        await deleteEnvironmentVariable(environmentId, id);
        resetPaging();
        await loadEnvironmentVariables(1, 'name', DIRECTION.asc.toUpperCase());
        setShowDeleteModal(false);
    };

    const columns = [
        {
            renderHeader: () => 'Actions',
            renderCell: ({ item }) => (
                <div className="flex">
                    <ButtonIcon
                        glyph="Edit"
                        aria-label={`Edit ${item.name}`}
                        onClick={() => {
                            setId(item.id);
                            setName(item.name);
                            setValue(item.value);
                            setIsSecret(item.isSecret);
                            setIsSecretToggleDisabled(item.isSecret);
                            setShowVariableModal(true);
                            setValid(item.name && item.value);
                        }}
                        title={`Edit ${item.name}`}
                        iconClass="icon-medium"
                    />
                    <ButtonIcon
                        className="margin-left-small danger"
                        glyph="Delete"
                        aria-label={`Delete ${item.name}`}
                        onClick={() => {
                            setId(item.id);
                            setName(item.name);
                            setShowDeleteModal(true);
                        }}
                        title={`Delete ${item.name}`}
                        iconClass="icon-medium"
                    />
                </div>
            ),
        },
        {
            renderHeader: () => (
                <Sortable
                    defaultDirection={DIRECTION.asc.toUpperCase()}
                    direction={sortBy === 'name' ? sortDirection : null}
                    onSort={(direction) => onSort({ orderBy: 'name', direction })}
                >
                    {translations.COMMON_TABLE_name}
                </Sortable>
            ),
            renderCell: ({ item }) => item.name,
            size: '30%',
        },
        {
            renderHeader: () => (
                <Sortable
                    defaultDirection={DIRECTION.asc.toUpperCase()}
                    direction={sortBy === 'value' ? sortDirection : null}
                    onSort={(direction) => onSort({ orderBy: 'value', direction })}
                >
                    {translations.COMMON_TABLE_value}
                </Sortable>
            ),
            renderCell: ({ item }) => item.value,
            size: '65%',
        },
    ];

    return (
        <div>
            <div className="flex margin-bottom margin-top filterHeading padding">
                <SearchInput
                    className="tab-header-search inline-block"
                    onSearch={(searchText) => {
                        onSearchTermFilterChange(searchText);
                    }}
                    testId="search-term-filter"
                    value={paging.searchTerm}
                    onChange={(e) => {
                        updatePaging({
                            ...paging,
                            searchTerm: e.target.value,
                        });
                    }}
                />
            </div>
            <button
                className="btn btn-success btn-sm"
                onClick={() => {
                    resetForm();
                    setShowVariableModal(true);
                }}
                title={translations.ENVIRONMENT_new_environment_variable}
                type="button"
            >
                <span className="glyphicon glyphicon-plus" />
                <span>{translations.ENVIRONMENT_new_environment_variable}</span>
            </button>
            <Table
                tableClass="margin-top"
                items={environmentVariables}
                columns={columns}
                isLoading={environmentVariablesLoading}
                pagination={{
                    page: paging.page,
                    pageSize: paging.pageSize,
                    total: paging.total,
                    changePage: (pageNumber) => {
                        updatePaging({
                            ...paging,
                            page: pageNumber,
                        });
                        loadEnvironmentVariables(pageNumber);
                    },
                }}
            />
            <Modal
                show={showVariableModal}
                container={modalContainerRef.current}
                onHide={() => setShowVariableModal(false)}
                title={
                    id
                        ? translations.ENVIRONMENT_edit_environment_variable
                        : translations.ENVIRONMENT_new_environment_variable
                }
                renderBody={() => (
                    <>
                        <FormGroup label="Name" htmlFor="environment-variable-name">
                            <input
                                id="environment-variable-name"
                                className="form-control form-control-dynamic"
                                type="text"
                                onChange={(e) => onNameChange(e.target.value)}
                                value={name}
                            />
                        </FormGroup>

                        <FormGroup label="Value" htmlFor="environment-variable-value">
                            <input
                                id="environment-variable-value"
                                className="form-control form-control-dynamic"
                                type="text"
                                onChange={(e) => onValueChange(e.target.value)}
                                value={value}
                            />
                        </FormGroup>

                        <label>
                            <Toggle
                                isOn={isSecret}
                                onChange={({ isOn }) => setIsSecret(isOn)}
                                testId="environment-variable-is-secret"
                                id="environment-variable-value"
                                isDisabled={isSecretToggleDisabled}
                            />
                            Is Secret
                        </label>
                    </>
                )}
                className="config-modal"
                renderFooter={() => (
                    <>
                        <button
                            type="button"
                            className="btn btn-default"
                            onClick={() => setShowVariableModal(false)}
                        >
                            {translations.ENVIRONMENT_cancel}
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => onSave()}
                            disabled={!valid}
                        >
                            {translations.ENVIRONMENT_save}
                        </button>
                    </>
                )}
            />
            <ConfirmModal
                show={showDeleteModal}
                title={translations.ENVIRONMENT_delete_environment_variable_title}
                messages={[
                    stringReplace(translations.ENVIRONMENT_delete_environment_variable_message, {
                        variableName: name ?? '',
                    }),
                ]}
                buttonStyle="danger"
                buttonCaption="Delete"
                onCancel={() => setShowDeleteModal(false)}
                onConfirm={() => {
                    deleteVariable();
                    setShowDeleteModal(false);
                }}
                container={modalContainerRef.current}
            />
        </div>
    );
};

const mapStateToProps = ({ tabs }) => ({
    tabs,
});

export default connect(mapStateToProps)(EnvironmentVariables);
