import classNames from 'classnames';
import '../../../css/search.less';

const keyCodes = {
    ENTER: 13,
    DELETE: 46,
    G: 71,
    ESCAPE: 27,
};

/**
 * Provides either a Controlled or Uncontrolled search input
 *
 * Omitting both the onChange handler and value parameters will mean this component
 * is Uncontrolled. Supplying both makes the component Controlled.
 *
 * This option is available because in most cases an Uncontrolled search box is
 * adequate and provides backwards compatibility for existing callers.
 */
const SearchInput = ({ onSearch, onChange, value, defaultValue, className, testId }) => {
    const classes = classNames(className, 'search-input');

    const onButtonClick =
        (onSearchCallback) =>
        ({ target }) => {
            const searchText = target
                .closest('.search-input')
                .querySelector('.search-input-text').value;
            onSearchCallback(searchText);
        };

    const onKeyUp =
        (onSearchCallback) =>
        ({ keyCode, target }) => {
            if (keyCode === keyCodes.ENTER) {
                onSearchCallback(target.value);
            }
        };

    // Default uncontrolled input
    const props = {
        type: 'text',
        className: 'search-input-text',
        placeholder: 'Search',
        onKeyUp: onKeyUp(onSearch),
        'data-testid': testId,
    };

    if (
        typeof onChange === 'undefined' &&
        typeof value === 'undefined' &&
        typeof defaultValue !== 'undefined'
    ) {
        // Optional default value for uncontrolled input
        props.defaultValue = defaultValue;
    }

    if (typeof onChange !== 'undefined' && typeof value !== 'undefined') {
        // Controlled input
        props.value = value;
        props.onChange = onChange;
    }

    return (
        <div className={classes}>
            <input {...props} aria-label="Search" />
            <button
                title="Search"
                aria-label="Get Search Results"
                className="search-input-button"
                type="button"
                onClick={onButtonClick(onSearch)}
            >
                <span className="glyphicon glyphicon-search" />
            </button>
        </div>
    );
};

export default SearchInput;
