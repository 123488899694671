import { ArrowsClockwise, DownloadSimple } from '@phosphor-icons/react';
import { createRef, useState } from 'react';
import '../../../../css/audit-logs.less';
import ButtonDefault from '../../../ts/components/buttons/ButtonDefault';
import translations from '../../../ts/translations';
import { AUDIT_TIME_PERIODS } from '../../../ts/components/auditLogs/constants';
import { exportBlobToCsv } from '../../../ts/utils/csv';
import { eventTypes } from './EventTypes';
import Select from 'react-select';
import { getSharedStyles } from '../../../ts/utils/select';

const datePickerRef = createRef();

export const getTypeOptions = () =>
    Object.entries(eventTypes).map(([key, value]) => ({
        label: value.name,
        value: key,
        logTemplate: value.logTemplate,
    }));

export const getPeriodOptions = () =>
    Object.entries(AUDIT_TIME_PERIODS).map(([_key, value]) => ({
        label: value.label,
        value: value.value,
    }));

// The Metrics API returns this hardcoded Public user
export const PUBLIC_USER = {
    id: '52df1a90-3826-4508-b7c2-cde8aa5b72cf',
    email: null,
    firstName: 'Public',
    lastName: 'user',
};

/**
 * Users may not have first or last name set when logged in via SSO
 *
 * @param {Object} user
 */
export const humanizeAuditUser = (user) => {
    if (!user) {
        return 'NA';
    }

    if (user.id === PUBLIC_USER.id) {
        return `${user.firstName} ${user.lastName}`;
    }
    if (user.firstName && user.lastName) {
        return `${user.firstName} ${user.lastName} (${user.email})`;
    }
    if (user.lastName) {
        return `${user.lastName} (${user.email})`;
    }
    if (user.firstName) {
        return `${user.firstName} (${user.email})`;
    }
    // We always have an email because it's the login key, except Public users
    return user.email;
};

export const getUserOptions = (users) => {
    if (users) {
        return users.map((user) => ({
            label: humanizeAuditUser(user),
            value: user ? user.id : null,
        }));
    }
};

const AuditLogsFilters = ({
    tenantId,
    users,
    filterDate,
    filterPeriod,
    filterTypes,
    filterUsers,
    fetchLogs,
    updateTimePeriodFilter,
    updateDateFilter,
    updateLogTypeFilter,
    updateUserFilter,
    downloadCsv,
}) => {
    const [downloadingCsv, setDownloadingCsv] = useState(false);
    const typeOptions = getTypeOptions();
    const userOptions = getUserOptions(users);
    const periods = getPeriodOptions();

    const dateString =
        typeof filterDate === 'string' ? filterDate : filterDate.toISOString().split('T')[0];

    const isDate = (date) => {
        return new Date(date) !== 'Invalid Date' && !Number.isNaN(new Date(date));
    };

    const generateCsv = async () => {
        setDownloadingCsv(true);
        const results = await downloadCsv(tenantId);
        exportBlobToCsv(`AuditLogs_${tenantId}.csv`, results);
        setDownloadingCsv(false);
    };

    return (
        <div className="audit-filters">
            <div className="type-filter filter-column">
                <Select
                    styles={getSharedStyles()}
                    options={periods}
                    id="time-period-filter"
                    onChange={(e) => updateTimePeriodFilter(e)}
                    value={filterPeriod}
                />
            </div>

            <div
                className="date-filter filter-column"
                ref={datePickerRef}
                hidden={filterPeriod.value !== AUDIT_TIME_PERIODS.day.value}
            >
                <input
                    className="form-control date-range-filter-input"
                    value={dateString}
                    type="date"
                    onChange={(e) =>
                        isDate(e.target.value) ? updateDateFilter(e.target.value) : null
                    }
                />
            </div>

            <div className="type-filter filter-column">
                <Select
                    styles={getSharedStyles()}
                    isMulti
                    options={typeOptions}
                    onChange={(selectValue) => updateLogTypeFilter(selectValue)}
                    placeholder={translations.AUDIT_FILTER_all_event_types}
                    value={filterTypes}
                    noOptionsMessage={() => translations.COMMON_SELECT_no_results_found}
                    id="type-filter"
                />
            </div>

            <div className="user-filter filter-column">
                <Select
                    styles={getSharedStyles()}
                    isMulti
                    options={userOptions}
                    onChange={(selectValue) => updateUserFilter(selectValue)}
                    placeholder={translations.AUDIT_FILTER_all_users}
                    value={filterUsers}
                    noOptionsMessage={() => translations.COMMON_SELECT_no_results_found}
                    id="user-filter"
                />
            </div>

            <div className="filter-column">
                <ButtonDefault
                    title={translations.AUDIT_FILTER_refresh_results}
                    onClick={() => fetchLogs(tenantId)}
                >
                    <ArrowsClockwise weight="fill" size={18} />
                </ButtonDefault>

                <ButtonDefault
                    title={translations.AUDIT_FILTER_download_csv}
                    onClick={generateCsv}
                    disabled={downloadingCsv}
                >
                    <DownloadSimple weight="fill" size={18} />
                </ButtonDefault>
            </div>
        </div>
    );
};

export default AuditLogsFilters;
