import { useState } from 'react';
import translations from '../../../../ts/translations';
import { connect } from 'react-redux';
import { append, equals, filter, not, pathOr } from 'ramda';
import { changeSAMLSetting } from '../../../actions/reduxActions/currentTenant';
import { addNotification } from '../../../actions/reduxActions/notification';
import Table from '../../../../ts/components/generic/Table';
import { Trash } from '@phosphor-icons/react';
import { isNullOrWhitespace } from '../../../../ts/utils/guard';

/**
 * @param {Object} settings - These are the SAML settings for the Tenant
 * @param {Function} updateGroups - Changes one of the SAML settings for the Tenant
 * @param {Function} onError - Displays an error notification
 * @description Add and remove groups from tenant SAML settings
 */
const SAMLSettingsGroups = ({ settings, updateGroups, onError }) => {
    const [groupToAdd, updateGroupToAdd] = useState('');

    const onAddGroup = (newGroup) => {
        const groups = pathOr([], ['groupSettings', 'groups'], settings);

        if (isNullOrWhitespace(newGroup)) {
            onError({
                type: 'error',
                message: 'Please enter a valid group name',
                isPersistent: true,
            });
        } else if (groups.find((g) => g === newGroup)) {
            onError({
                type: 'error',
                message: `The group named ${newGroup} has already been added`,
                isPersistent: true,
            });
        } else {
            updateGroups({
                settingPath: ['groupSettings', 'groups'],
                value: append(newGroup, groups),
            });
        }
    };

    const onRemoveGroup = (groupToRemove) => {
        updateGroups({
            settingPath: ['groupSettings', 'groups'],
            value: filter((g) => not(equals(g, groupToRemove)), settings.groupSettings.groups),
        });
    };

    const columns = [
        {
            renderHeader: () => translations.COMMON_TABLE_group_name,
            renderCell: ({ item }) => item,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item }) => (
                <div className="action-btn-wrapper">
                    <button
                        title="Remove group"
                        className="table-icon table-icon-delete"
                        aria-label="Remove group"
                        onClick={() => onRemoveGroup(item)}
                        type="button"
                    >
                        <Trash />
                    </button>
                </div>
            ),
            size: '5rem',
        },
    ];

    return (
        <div className="form-group">
            <div className="restriction-widget">
                <label htmlFor="saml-groups-label">{translations.SAML_groups_label}</label>
                <div className="flex">
                    <input
                        id="saml-groups-label"
                        className="form-control"
                        type="text"
                        onChange={(e) => updateGroupToAdd(e.target.value)}
                        placeholder="Enter a group to add"
                    />
                    <button
                        className="btn btn-sm btn-success add-restriction"
                        onClick={() => onAddGroup(groupToAdd)}
                        type="button"
                    >
                        Add
                    </button>
                </div>
                <Table
                    wrapperClassName="margin-top"
                    columns={columns}
                    items={pathOr([], ['groupSettings', 'groups'], settings)}
                />
                <p className="help-block">
                    {translations.SAML_groups_help_1}
                    <br />
                    {translations.SAML_groups_help_2}
                </p>
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    updateGroups: changeSAMLSetting,
    onError: addNotification,
};

export default connect(null, mapDispatchToProps)(SAMLSettingsGroups);
