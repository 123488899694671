import { useState } from 'react';
import DeveloperDashboardLaunchesSummary from './DeveloperDashboardLaunchesSummary';
import type { NotifyError } from '../../types';
import ScreenRouter from '../generic/ScreenRouter';
import translations from '../../translations';
import DeveloperDashboardErrorsSummary from './DeveloperDashboardErrorsSummary';
import DeveloperDashboardLaunchesFull from './DeveloperDashboardLaunchesFull';
import { AnomalyDashboardProvider } from '../anomalyDetection/AnomalyProvider';
import AnomalySummary from './AnomalySummary';
import AnomalyDashboard from '../anomalyDetection/AnomalyDashboard';

import './developer-dashboard.less';

export enum View {
    summary = 0,
    launches = 1,
    anomaly = 2,
}

interface Props {
    notifyError: NotifyError;
}

const DeveloperDashboard = ({ notifyError }: Props) => {
    const [currentView, setCurrentView] = useState<View>(View.summary);

    const onViewAllAnomalies = () => {
        setCurrentView(View.anomaly);
    };

    const SummaryDashboardView = (_: { screen?: string }) => {
        return (
            <>
                <span className="title-bar">
                    <h1>{translations.DASHBOARD_header}</h1>
                </span>
                <DeveloperDashboardLaunchesSummary
                    setCurrentView={setCurrentView}
                    notifyError={notifyError}
                />
                <AnomalySummary onViewAll={onViewAllAnomalies} />
                <DeveloperDashboardErrorsSummary notifyError={notifyError} />
            </>
        );
    };

    const FullLaunchesDashboardView = (_: { screen?: string }) => (
        <DeveloperDashboardLaunchesFull notifyError={notifyError} setCurrentView={setCurrentView} />
    );

    return (
        <div className="admin-page">
            <AnomalyDashboardProvider notifyError={notifyError} initialDateIndex={0}>
                <ScreenRouter currentScreen={currentView.toString()}>
                    <SummaryDashboardView screen={View.summary.toString()} />
                    <FullLaunchesDashboardView screen={View.launches.toString()} />
                    <AnomalyDashboard
                        screen={View.anomaly.toString()}
                        setCurrentView={setCurrentView}
                    />
                </ScreenRouter>
            </AnomalyDashboardProvider>
        </div>
    );
};

export default DeveloperDashboard;
