import translations from '../../../ts/translations';
import CreateSubtenant from '../../../ts/components/tenant/CreateSubtenant';
import Tenant from './Tenant';

export const TENANT_VIEWS = {
    tenant: 'tenant',
    userDetails: 'userDetails',
    newSubtenant: 'newSubtenant',
    IPRestrictionDetails: 'IPRestrictionDetails',
};

export const getTenantPages = (isSubtenant) => {
    /**
     * Configuration object holding all the side menu links for each page
     *
     * If a page does not have a side menu then there is no need to add any config
     * for it here.
     *
     * The side menu component will handle the highlighting as links are clicked by the user.
     *
     * @param {Array} <page_id> Property that holds the set of link definitions for a specific page
     * @param {String} label Text shown in the link
     * @param {String} link Link's hash id used for navigating within the parent page
     * @param {Boolean} [visible = true] Determines if the link is visible
     *
     * @example
     *
     *  pageSideMenuItems = {
     *      unique_page_id: [
     *          {
     *              label: 'Link 1',
     *              link: '#link_id',
     *          },
     *          {
     *              label: 'Link 2',
     *              link: '#link_2_1',
     *              visible: false,
     *          },
     *          ...
     *      ],
     *      ...
     *  };
     */

    const pageSideMenuItems = {
        tenant: [
            {
                label: 'Tenant Name',
                link: '#tenant-name',
            },
            {
                label: 'Users',
                link: '#tenant-users',
            },
            {
                label: 'Environments',
                link: '#tenant-environment',
                visible: true,
            },
            {
                label: 'Themes',
                link: '#tenant-themes',
            },
            {
                label: 'Integration Accounts',
                link: '#tenant-integration-accounts',
            },
            {
                label: 'Subtenants',
                link: '#tenant-subtenants',
                visible: !isSubtenant,
            },
            {
                label: 'Single Sign-on',
                link: '#tenant-single-sign-on',
            },
            {
                label: 'Security',
                link: '#tenant-security',
            },
            {
                label: 'Reporting',
                link: '#tenant-reporting',
            },
            {
                label: 'Observability',
                link: '#tenant-observability',
            },
            {
                label: 'Export',
                link: '#tenant-export',
            },
            {
                label: 'Import',
                link: '#tenant-import',
            },
            {
                label: 'Comments',
                link: '#tenant-comments',
            },
        ],
    };

    /**
     * Configuration array holding all the pages that belong to the same main view
     *
     * @param {String} id A unique page id
     * @param {String} label Name of the page, also used for main page title
     * @param {(null|String)} parent Id of the parent page if current page is a child of it
     * @param {(null|pageSideMenuItems|Array)} menu Set of side menu links for the page (prefer pageSideMenuItems.<unique_page_id>)
     * @param {React.Component} component React component of the current page
     *
     * @example
     *
     *  orgPages = [
     *      {
     *          id: 'uniquePageNameOrId',
     *          label: 'Page Title',
     *          parent: null | 'parentPageNameOrId',
     *          menu: null | pageSideMenuItems.uniquePageNameOrId | [...]
     *          component: React component (imported at the top of the file)
     *      },
     *      ...
     *  ];
     */

    const tenantPages = [
        {
            id: TENANT_VIEWS.tenant,
            label: translations.TENANT_page_title_tenant,
            parent: null,
            menu: pageSideMenuItems.tenant,
            component: Tenant,
        },
        {
            id: TENANT_VIEWS.newSubtenant,
            label: translations.TENANT_page_title_create_subtenant,
            parent: 'tenant',
            menu: null,
            component: CreateSubtenant,
        },
    ];

    return tenantPages;
};
