import { useEffect, useRef, useState } from 'react';
import { PageEditorProvider } from '../PageEditorProvider';
import { ComposerProvider } from './composer/ComposerProvider';
import type { AddNotification, Tab } from '../../../../types';

import Main from './Main';

import '../../../../../../css/builder/page-builder.less';

interface Props {
    addNotification: AddNotification;
    updateTab: (tab: Tab) => void;
    updateUrlAndTab: ({
        key,
        type,
        title,
        elementId,
        tenantId,
    }: {
        key: string;
        type: string;
        title: string | null;
        elementId: string;
        tenantId: string;
    }) => void;
    tabConfig: Tab;
}

const PageEditor = ({ addNotification, updateTab, updateUrlAndTab, tabConfig }: Props) => {
    const modalContainerRef = useRef<HTMLDivElement>(null);
    const [container, setContainer] = useState(modalContainerRef?.current);
    const pageBuilderClass = tabConfig.isActive
        ? 'nextgen-page-builder admin active'
        : 'nextgen-page-builder';

    // Using useEffect as it seems that a ref change alone is not enough to get React to
    // re-render/update a prop, so we always end up with ref.current being 'undefined'
    // and modals going to the top level above the page builder.
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setContainer(modalContainerRef?.current);
    }, [modalContainerRef]);

    return (
        <PageEditorProvider
            addNotification={addNotification}
            updateTab={updateTab}
            // Passing the modal container through the provider makes it easier for modals several
            // levels down the component tree to get to it without the need to pass it down via
            // several layers of (parent/child) component props.
            container={container}
        >
            <ComposerProvider updateUrlAndTab={updateUrlAndTab}>
                <div
                    className={pageBuilderClass}
                    ref={modalContainerRef}
                    data-tab-content={tabConfig.key}
                >
                    <Main tabConfig={tabConfig} />
                </div>
            </ComposerProvider>
        </PageEditorProvider>
    );
};

export default PageEditor;
