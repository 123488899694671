import { Trash } from '@phosphor-icons/react';
import { useMapElement } from '../../../../../js/components/flow/elementConfigurations/contextProviders/MapElementProvider';
import translations from '../../../../translations';
import type { MapElementProvider, OpenApiAction } from '../../../../types';
import { useOpenApi } from '../contextProviders/OpenApiProvider';
import ButtonPrimary from '../../../buttons/ButtonPrimary';
import Glyphicon from '../../../generic/Glyphicon';
import Table from '../../../generic/Table';

const OpenApiActionList = () => {
    const { mapElement }: MapElementProvider = useMapElement();
    const { onCreateOpenApiAction, onEditOpenApiAction, onDeleteOpenApiAction } = useOpenApi();

    const columns = [
        {
            renderHeader: () => translations.COMMON_TABLE_name,
            renderCell: ({ item, rowIndex }: { item: OpenApiAction; rowIndex: number }) => (
                <button
                    className="link-emulate"
                    title={`Edit ${item.developerName ?? 'OpenApiAction'}`}
                    onClick={() => onEditOpenApiAction(item, rowIndex)}
                    type="button"
                >
                    {item.developerName ?? ''}
                </button>
            ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ rowIndex, item }: { rowIndex: number; item: OpenApiAction }) => (
                <div className="action-btn-wrapper">
                    <button
                        title={`Delete ${item.developerName ?? 'Wait'}`}
                        className="table-icon table-icon-delete"
                        aria-label="Delete"
                        onClick={() => onDeleteOpenApiAction(rowIndex)}
                        type="button"
                    >
                        <Trash />
                    </button>
                </div>
            ),
            size: '5rem',
        },
    ];

    return (
        <>
            <h4>OpenApi Actions</h4>
            <div className="margin-bottom flex">
                <ButtonPrimary onClick={onCreateOpenApiAction}>
                    <Glyphicon glyph="plus" />
                    Add OpenApi Action
                </ButtonPrimary>
            </div>
            <Table<OpenApiAction>
                wrapperClassName="margin-bottom-large"
                columns={columns}
                items={mapElement?.openApiActions ?? []}
                testId="openApiAction-list"
            />
        </>
    );
};

export default OpenApiActionList;
