import type { SSOSettings } from '../../../types/auth';
import { changeSAMLSetting } from '../../../../js/actions/reduxActions/currentTenant';
import { connect } from 'react-redux';
import translations from '../../../translations';
import Toggle from '../../inputs/Toggle';
import { isNullOrUndefined } from '../../../utils/guard';

interface Props {
    settings: SSOSettings;
    changeSAMLSetting: (obj: { settingPath: string[]; value: string | boolean }) => void;
}

const SAMLCookieSettings = ({ settings, changeSAMLSetting }: Props) => {
    const onChangeCookieType = (e: React.ChangeEvent<HTMLSelectElement>) =>
        changeSAMLSetting({
            settingPath: ['cookieType'],
            value: e.currentTarget.value,
        });

    const onChangeMaxSession = (e: React.ChangeEvent<HTMLInputElement>) =>
        changeSAMLSetting({
            settingPath: ['maxSession'],
            value: e.currentTarget.value,
        });

    const onChangeIdleTimeout = (e: React.ChangeEvent<HTMLInputElement>) =>
        changeSAMLSetting({
            settingPath: ['idleTimeout'],
            value: e.currentTarget.value,
        });

    const onChangeShowUri = (args: { event: React.ChangeEvent<HTMLInputElement>; isOn: boolean }) =>
        changeSAMLSetting({
            settingPath: ['showUri'],
            value: args.isOn,
        });

    if (!settings || isNullOrUndefined(settings.cookieType)) {
        changeSAMLSetting({
            settingPath: ['cookieType'],
            value: 'persistent',
        });
    }

    return (
        <>
            <div className="form-group">
                <label htmlFor="saml-cookie-type">{translations.SAML_cookietype_label}</label>
                <select
                    id="saml-cookie-type"
                    value={settings?.cookieType}
                    onChange={onChangeCookieType}
                    className="form-control"
                >
                    <option value="persistent">{translations.SAML_cookietype_persistent}</option>
                    <option value="transient">{translations.SAML_cookietype_transient}</option>
                </select>
                <p className="help-block">{translations.SAML_cookietype_help}</p>
            </div>
            <div className="form-group">
                <label htmlFor="saml-max-session">{translations.SAML_max_session_label}</label>
                <input
                    id="saml-max-session"
                    type="number"
                    value={settings?.maxSession ?? undefined}
                    placeholder={translations.SAML_max_session_placeholder}
                    onChange={onChangeMaxSession}
                    className="form-control"
                />
                <p className="help-block">{translations.SAML_max_session_help}</p>
            </div>
            <div className="form-group">
                <label>
                    <Toggle
                        className="saml-enable-show-url"
                        isOn={settings?.showUri ?? false}
                        onChange={onChangeShowUri}
                    />
                    {translations.SAML_max_session_show_uri}
                </label>
                <p className="help-block">{translations.SAML_max_session_show_uri_help}</p>
            </div>
            <div className="form-group">
                <label htmlFor="saml-idle-timeout">{translations.SAML_idle_timeout}</label>
                <input
                    id="saml-idle-timeout"
                    type="number"
                    value={settings?.idleTimeout ?? undefined}
                    placeholder={translations.SAML_idle_timeout_placeholder}
                    onChange={onChangeIdleTimeout}
                    className="form-control"
                />
                <p className="help-block">{translations.SAML_idle_timeout_help}</p>
            </div>
        </>
    );
};

const mapDispatchToProps = {
    changeSAMLSetting: changeSAMLSetting,
};

export default connect(null, mapDispatchToProps)(SAMLCookieSettings);
