import {
    ButtonFlavor,
    ButtonSize,
    ButtonType,
    ExButton,
    ExIconButton,
    ExInput,
    ExTextarea,
    IconButtonType,
} from '@boomi/exosphere';
import { useState, type RefObject } from 'react';
import AssetManagerModal from '../assets/AssetManagerModal';
import type { ThemeEdit, ThemeProperties, AddNotification } from '../../types';
import Footer from '../generic/Footer';
import './themes.less';
import { exportTheme, saveTheme } from '../../sources/theme';
import BuildThemeModal from './BuildThemeModal';
import { useAuth } from '../AuthProvider';
import { REGEX_PLAYER_NAME } from '../../constants';
import translations from '../../translations';
import Preview from './preview/Preview';
import { isNullOrEmpty } from '../../utils/guard';
interface Props {
    theme: ThemeEdit;
    returnToList: () => void;
    addNotification: AddNotification;
    container?: RefObject<HTMLDivElement>;
}

interface ColorInputProps {
    label: string;
    tooltip: string;
    onChange: (propertyName: keyof ThemeProperties, value: string) => void;
    property: keyof ThemeProperties;
    value: string | undefined;
}

type FileType = 'css' | 'js' | 'img';

const ThemeColorInput = ({ value = '', label, tooltip, onChange, property }: ColorInputProps) => (
    <label className="theme-color-input">
        <input
            title={tooltip}
            type="color"
            value={value}
            onChange={(event) => onChange(property, event.target.value)}
        />
        <span>{label}</span>
    </label>
);

const ThemeDetail = ({ theme: themeToEdit, returnToList, addNotification, container }: Props) => {
    const [theme, setTheme] = useState(themeToEdit);
    const [showAssetModal, setShowAssetModal] = useState(false);
    const [assetFileType, setAssetFileType] = useState<FileType>('css');
    const [assetIndex, setAssetIndex] = useState<number>(0);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [showBuildModal, setShowBuildModal] = useState(false);
    const [savedThemeId, setSavedThemeId] = useState('');
    const imgFileTypes = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp', 'apng', 'avif'];

    const isValid =
        !isNullOrEmpty(theme.developerName) && REGEX_PLAYER_NAME.test(theme.developerName);

    const pickFile = (fileType: FileType, index: number) => {
        setAssetFileType(fileType);
        setAssetIndex(index);
        setShowAssetModal(true);
    };

    const { tenant } = useAuth();
    const environmentsIsOn = tenant?.tenantSettings?.environments;

    const setAssetURL = (assetURL: string, fileType: FileType, index: number) => {
        const newCSSURLs = [...(theme.cssURLs ?? ([] as string[]))];
        const newJSURLs = [...(theme.jsURLs ?? ([] as string[]))];

        if (fileType === 'css') {
            newCSSURLs[index] = assetURL;
        } else if (fileType === 'js') {
            newJSURLs[index] = assetURL;
        }

        const newTheme = {
            ...theme,
            cssURLs: newCSSURLs,
            jsURLs: newJSURLs,
        };

        if (fileType === 'img') {
            newTheme.logoURL = assetURL;
        }

        setTheme(newTheme);
    };

    const removeAsset = (fileType: FileType, index: number) => {
        if (fileType === 'css') {
            setTheme({
                ...theme,
                cssURLs: (theme.cssURLs ?? []).filter((_, i) => i !== index),
            });
        }

        if (fileType === 'js') {
            setTheme({
                ...theme,
                jsURLs: (theme.jsURLs ?? []).filter((_, i) => i !== index),
            });
        }
    };

    const addAsset = (fileType: FileType) => {
        if (fileType === 'css') {
            setTheme({
                ...theme,
                cssURLs: [...(theme.cssURLs ?? []), ''],
            });
        }

        if (fileType === 'js') {
            setTheme({
                ...theme,
                jsURLs: [...(theme.jsURLs ?? []), ''],
            });
        }
    };

    const onNameChange = (event: Event) =>
        setTheme({
            ...theme,
            developerName: (event.target as HTMLInputElement).value,
        });

    const onDocumentTitleChange = (event: Event) =>
        setTheme({
            ...theme,
            documentTitle: (event.target as HTMLInputElement).value,
        });

    const onTitleChange = (event: Event) =>
        setTheme({
            ...theme,
            title: (event.target as HTMLInputElement).value,
        });

    const onLogoURLChange = (event: Event) =>
        setTheme({
            ...theme,
            logoURL: (event.target as HTMLInputElement).value,
        });

    const onColorChange = (propertyName: keyof ThemeProperties, value: string) => {
        if (theme.properties === null) {
            theme.properties = {
                '--color-primary': '',
                '--color-font': '',
                '--color-background': '',
                '--color-border': '',
                '--color-highlight-background': '',
                '--color-highlight-font': '',
                '--color-selected-background': '',
                '--color-selected-font': '',
                '--color-background-alternate': '',
            };
        }

        setTheme({
            ...theme,
            properties: {
                ...theme.properties,
                [propertyName]: value,
            },
        });
    };

    const onChangeSummary = (value: string) => {
        setTheme({
            ...theme,
            developerSummary: value,
        });
    };

    const onSave = async (skipReturn: boolean) => {
        setHasSubmitted(true);

        if (!isValid) {
            return null;
        }

        const updatedTheme: ThemeEdit = {
            ...theme,
            cssURLs: (theme.cssURLs ?? []).filter((x) => !isNullOrEmpty(x)),
            jsURLs: (theme.jsURLs ?? []).filter((x) => !isNullOrEmpty(x)),
        };
        let savedTheme = null;

        try {
            savedTheme = await saveTheme(updatedTheme);
            //set the theme so we don't hit the unique name protection if the screen is saved twice
            setTheme(savedTheme);
        } catch (error) {
            if (error instanceof Error) {
                addNotification({
                    type: 'error',
                    message: error.message,
                    isPersistent: true,
                });
            }
            return null;
        }

        if (!skipReturn) {
            returnToList();
        }
        return savedTheme;
    };

    const onBuild = async () => {
        const saveResult = await onSave(true);
        if (!saveResult) {
            return;
        }
        setShowBuildModal(true);
        setSavedThemeId(saveResult.id);
    };

    const onExport = async () => {
        const saveResult = await onSave(true);
        if (!saveResult) {
            return;
        }
        await exportTheme(saveResult.id, saveResult.developerName);
    };

    return (
        <>
            <div className="admin-page theme-detail">
                <AssetManagerModal
                    onInsertAsset={(assetURL: string) =>
                        setAssetURL(assetURL, assetFileType, assetIndex)
                    }
                    showAssetModal={showAssetModal}
                    setShowAssetModal={setShowAssetModal}
                    allowedExtensions={assetFileType === 'img' ? imgFileTypes : [assetFileType]}
                    filterListToAllowedExtensions
                    container={container?.current}
                />

                <BuildThemeModal
                    showBuildModal={showBuildModal}
                    setShowBuildModal={setShowBuildModal}
                    addNotification={addNotification}
                    themeId={savedThemeId}
                    container={container?.current ?? undefined}
                    callback={returnToList}
                />

                <div className="theme-detail-layout">
                    <div>
                        <h1>{`Theme: ${theme.developerName}`}</h1>
                        <form>
                            <span className="flex">
                                <ExInput
                                    autofocus
                                    required
                                    label="Name"
                                    id="theme-name"
                                    data-testid="theme-name"
                                    className="input-med"
                                    value={theme.developerName}
                                    type="text"
                                    error-msg={translations.THEME_invalid_theme_name_message}
                                    invalid={hasSubmitted && !isValid}
                                    onChange={onNameChange}
                                    helpText={translations.THEME_theme_name_help_message}
                                />
                            </span>
                            <span className="flex">
                                <ExInput
                                    label="Document Title"
                                    id="document-title"
                                    data-testid="document-title"
                                    className="input-med"
                                    value={theme.documentTitle ?? ''}
                                    type="text"
                                    onChange={onDocumentTitleChange}
                                />
                            </span>
                            <span className="flex">
                                <ExInput
                                    label="Title"
                                    id="title"
                                    data-testid="title"
                                    className="input-med"
                                    value={theme.title ?? ''}
                                    type="text"
                                    onChange={onTitleChange}
                                />
                            </span>
                        </form>

                        <div className="theme-colors">
                            <h2>Colors</h2>
                            <fieldset className="theme-color-group">
                                <legend>General</legend>
                                <ThemeColorInput
                                    label="Background"
                                    property="--color-background"
                                    value={theme.properties?.['--color-background']}
                                    onChange={onColorChange}
                                    tooltip="The main background color of the application."
                                />
                                <ThemeColorInput
                                    label="Background alternate"
                                    property="--color-background-alternate"
                                    value={theme.properties?.['--color-background-alternate']}
                                    onChange={onColorChange}
                                    tooltip="The alternate background color of repeating elements such as table rows."
                                />
                                <ThemeColorInput
                                    label="Font"
                                    property="--color-font"
                                    value={theme.properties?.['--color-font']}
                                    onChange={onColorChange}
                                    tooltip="The text color."
                                />
                                <ThemeColorInput
                                    label="Primary"
                                    property="--color-primary"
                                    value={theme.properties?.['--color-primary']}
                                    onChange={onColorChange}
                                    tooltip="The accent color. Used for button borders, primary buttons (Save, Submit etc.), table headings."
                                />
                                <ThemeColorInput
                                    label="Border"
                                    property="--color-border"
                                    value={theme.properties?.['--color-border']}
                                    onChange={onColorChange}
                                    tooltip="The color of borders."
                                />
                            </fieldset>

                            <fieldset className="theme-color-group">
                                <legend>Selected</legend>
                                <ThemeColorInput
                                    label="Selected background"
                                    property="--color-selected-background"
                                    value={theme.properties?.['--color-selected-background']}
                                    onChange={onColorChange}
                                    tooltip="The background color of selected items such as selected table rows, checked checkboxes, toggles etc."
                                />
                                <ThemeColorInput
                                    label="Selected font"
                                    property="--color-selected-font"
                                    value={theme.properties?.['--color-selected-font']}
                                    onChange={onColorChange}
                                    tooltip="The text color of selected items such as selected table rows, checked checkboxes, toggles etc."
                                />
                            </fieldset>

                            <fieldset className="theme-color-group">
                                <legend>Highlighted</legend>
                                <ThemeColorInput
                                    label="Highlight background"
                                    property="--color-highlight-background"
                                    value={theme.properties?.['--color-highlight-background']}
                                    onChange={onColorChange}
                                    tooltip="The background color of highlighted items such as hovered table rows, hovered select options etc."
                                />
                                <ThemeColorInput
                                    label="Highlight font"
                                    property="--color-highlight-font"
                                    value={theme.properties?.['--color-highlight-font']}
                                    onChange={onColorChange}
                                    tooltip="The text color of highlighted items such as hovered table rows, hovered select options etc."
                                />
                            </fieldset>
                        </div>

                        <h2>Files</h2>

                        <fieldset className="resource-list" data-testid="resource-list-css">
                            <legend>Custom Logo</legend>

                            <span className="flex gap-x-small">
                                <ExInput
                                    data-testid="custom-logo"
                                    className="input-wide"
                                    placeholder="Logo URL"
                                    value={theme.logoURL ?? ''}
                                    type="text"
                                    onChange={onLogoURLChange}
                                />
                                <ExIconButton
                                    type={IconButtonType.SECONDARY}
                                    icon="document-search"
                                    onClick={() => pickFile('img', 0)}
                                />
                            </span>
                            <legend>Custom CSS Stylesheets</legend>

                            {(theme.cssURLs ?? []).map((URL, index) => (
                                // biome-ignore lint/suspicious/noArrayIndexKey: Unsure value other than index to use as a key
                                <span key={index} className="flex gap-x-small">
                                    <ExInput
                                        data-testid={`CSS-URL-${index}`}
                                        className="input-wide"
                                        placeholder="Stylesheet URL"
                                        value={URL}
                                        type="text"
                                        required
                                        error-msg="URL is required"
                                        onChange={(event: Event) =>
                                            setAssetURL(
                                                (event.target as HTMLInputElement).value,
                                                'css',
                                                index,
                                            )
                                        }
                                    />
                                    <ExIconButton
                                        type={IconButtonType.SECONDARY}
                                        icon="document-search"
                                        onClick={() => pickFile('css', index)}
                                    />
                                    <ExIconButton
                                        data-testid={`delete-css-${index}`}
                                        type={IconButtonType.SECONDARY}
                                        icon="delete"
                                        onClick={() => removeAsset('css', index)}
                                    />
                                </span>
                            ))}

                            <span className="flex">
                                <ExButton
                                    onClick={() => addAsset('css')}
                                    flavor={ButtonFlavor.BRANDED}
                                    type={ButtonType.SECONDARY}
                                    size={ButtonSize.DEFAULT}
                                >
                                    Add CSS Stylesheet
                                </ExButton>
                            </span>
                        </fieldset>

                        <fieldset className="resource-list" data-testid="resource-list-js">
                            <legend>Custom JavaScript files</legend>

                            {(theme.jsURLs ?? []).map((URL, index) => (
                                <span key={URL} className="flex gap-x-small">
                                    <ExInput
                                        className="input-wide"
                                        placeholder="JavaScript file URL"
                                        value={URL}
                                        type="text"
                                        required
                                        error-msg="URL is required"
                                        onChange={(event: Event) =>
                                            setAssetURL(
                                                (event.target as HTMLInputElement).value,
                                                'js',
                                                index,
                                            )
                                        }
                                    />
                                    <ExIconButton
                                        type={IconButtonType.SECONDARY}
                                        icon="document-search"
                                        onClick={() => pickFile('js', index)}
                                    >
                                        Search
                                    </ExIconButton>
                                    <ExIconButton
                                        data-testid={`delete-js-${index}`}
                                        type={IconButtonType.SECONDARY}
                                        icon="delete"
                                        onClick={() => removeAsset('js', index)}
                                    />
                                </span>
                            ))}

                            <span className="flex">
                                <ExButton
                                    onClick={() => addAsset('js')}
                                    flavor={ButtonFlavor.BRANDED}
                                    type={ButtonType.SECONDARY}
                                    size={ButtonSize.DEFAULT}
                                >
                                    Add JavaScript file
                                </ExButton>
                            </span>
                        </fieldset>

                        <ExTextarea
                            label="Comments"
                            optional
                            value={theme.developerSummary ?? ''}
                            className="input-wide"
                            onChange={(event: Event) =>
                                onChangeSummary((event.target as HTMLTextAreaElement).value)
                            }
                        />
                    </div>
                    <div className="runtime-preview">
                        <h2>Preview example</h2>
                        <div className="runtime-preview-frame">
                            <Preview theme={theme} />
                        </div>
                    </div>
                </div>
            </div>
            <Footer>
                <ExButton
                    onClick={returnToList}
                    slot="footer"
                    flavor={ButtonFlavor.BASE}
                    type={ButtonType.SECONDARY}
                    size={ButtonSize.LARGE}
                >
                    Back
                </ExButton>
                {!environmentsIsOn && (
                    <>
                        <ExButton
                            onClick={() => onSave(false)}
                            slot="footer"
                            flavor={ButtonFlavor.BRANDED}
                            type={ButtonType.PRIMARY}
                            size={ButtonSize.LARGE}
                        >
                            Save
                        </ExButton>
                        <ExButton
                            onClick={onExport}
                            slot="footer"
                            flavor={ButtonFlavor.BRANDED}
                            type={ButtonType.PRIMARY}
                            size={ButtonSize.LARGE}
                        >
                            Export for MCR
                        </ExButton>
                    </>
                )}
                {environmentsIsOn && (
                    <ExButton
                        onClick={onBuild}
                        slot="footer"
                        flavor={ButtonFlavor.BRANDED}
                        type={ButtonType.PRIMARY}
                        size={ButtonSize.LARGE}
                    >
                        Build
                    </ExButton>
                )}
            </Footer>
        </>
    );
};

export default ThemeDetail;
