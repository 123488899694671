import type { ItemCollectionResponse, AppUser } from '../types';
import { CheckStatus, fetchAndParse } from '../utils/ajax';
import { getTenantId } from '../utils/tenant';

interface AuditEventFlowResponse {
    id: string;
    version: string;
    developerName: string;
}

interface AuditEvent {
    id: string;
    type: string;
    occurredAt: string | null;
    userId: string;
    user: AppUser;
    tenantId: string;
    stateId: string | null;
    flow: AuditEventFlowResponse;
    data: { [key: string]: unknown };
}

export const getAuditLogs = (parameters: string) =>
    fetchAndParse<ItemCollectionResponse<AuditEvent>>({
        url: `/api/audit/1/search?${parameters}`,
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const downloadAuditLogsCsv = (parameters: string) =>
    fetch(`/api/audit/1/csv?${parameters}`, {
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    })
        .then(CheckStatus)
        .then((response) => response.blob());
