import { type ReactNode, createContext, useContext, useEffect, useState } from 'react';
import {
    type AnomalyResponse,
    type LimitReachedError,
    getAnomalyEvents,
} from '../../sources/anomalyEvents';
import translations from '../../translations';
import { uniq } from 'ramda';
import { ANOMALY_TYPE_ERROR, ANOMALY_TYPE_TIME, ANOMALY_TYPE_USAGE } from '../../constants/anomaly';
import {
    convertDateFromUnixTime,
    convertDateToUnixTime,
    formatChartDate,
} from '../../utils/dashboard';
import { AlertBannerType, ExAlertBanner } from '@boomi/exosphere';

interface ExDetail {
    selectedPage?: number;
    pageSize?: number;
}

interface ChartData {
    x: string;
    y: number;
    z: string;
}

interface ChartTooltip {
    header?: string;
    subHeader?: string;
    visible?: boolean;
}

interface ChartConfig {
    type: string;
    width: number;
    height: number;
    data: ChartData[];
    showLegends: boolean;
    tooltip: ChartTooltip;
    customization: { [key: string]: { color: string } };
}

interface AnomalyDashboardProviderContext {
    anomalyFilter: string | undefined;
    setAnomalyFilter: (anomalyFilter: string | undefined) => void;
    flowFilter: string | undefined;
    setFlowFilter: (flowFilter: string | undefined) => void;
    stateFilter: string | undefined;
    setStateFilter: (stateFilter: string | undefined) => void;
    flows: string[];
    stateIds: string[];
    dateDaysAgo: Date;
    dateIndexFilter: number;
    setDateIndexFilter: (dateIndexFilter: number) => void;
    changePagination: (detail: ExDetail) => void;
    isLoadingData: boolean;
    barConfig: ChartConfig;
    donutConfig: ChartConfig;
    errorData: AnomalyResponse[];
    timeData: AnomalyResponse[];
    usageData: AnomalyResponse[];
    data: AnomalyResponse[];
    filteredData: AnomalyResponse[];
    paginatedData: AnomalyResponse[];
    page: number;
    pageSize: number;
}

interface AnomalyDashboardProviderProps {
    children: ReactNode;
    notifyError: (error: string | Error) => void;
    initialDateIndex?: number;
}

const Context = createContext<AnomalyDashboardProviderContext | undefined>(undefined);

const AnomalyDashboardProvider = ({
    children,
    notifyError,
    initialDateIndex = 2,
}: AnomalyDashboardProviderProps) => {
    const [data, setData] = useState<AnomalyResponse[]>([]);
    const [flows, setFlows] = useState<string[]>([]);
    const [stateIds, setStateIds] = useState<string[]>([]);
    const [anomalyFilter, setAnomalyFilter] = useState<string | undefined>();
    const [flowFilter, setFlowFilter] = useState<string | undefined>();
    const [stateFilter, setStateFilter] = useState<string | undefined>();
    // | 0 -> 1D | 1 -> 3D | 2 -> 7D |
    const [dateIndexFilter, setDateIndexFilter] = useState(initialDateIndex);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(15);
    const [limitReachedError, setLimitReachedError] = useState<string | undefined>();

    useEffect(() => {
        const fetchData = async () => {
            setIsLoadingData(true);
            await getAnomalyEvents()
                .then((data) => {
                    setData(data);
                    setFlows(uniq(data.map((d) => d.flowName)));
                    setStateIds(uniq(data.map((d) => d.stateId)));
                    setIsLoadingData(false);
                    setLimitReachedError(undefined);
                })
                .catch((e: Error | LimitReachedError) => {
                    if ((e as LimitReachedError)?.wasLimitReached) {
                        notifyError((e as LimitReachedError)?.message);
                        setLimitReachedError((e as LimitReachedError)?.message);
                    } else {
                        notifyError(e as Error);
                        setLimitReachedError(undefined);
                    }
                });
        };
        fetchData();
    }, [notifyError]);

    const changePagination = (detail: ExDetail) => {
        setPage(detail?.selectedPage ?? 1);
        setPageSize(detail?.pageSize ?? 15);
    };

    const daysAgoFilter = dateIndexFilter === 0 ? 1 : dateIndexFilter === 1 ? 3 : 7;
    const weekNumberDaysAgo = new Date().getDate() - daysAgoFilter;
    const dateDaysAgo = new Date();
    dateDaysAgo.setDate(weekNumberDaysAgo);
    dateDaysAgo.setMinutes(0, 0, 0);

    const timeSlice6Hours = 3600 * 6;
    const timeSlice3Hours = 3600 * 3;
    const timeSlice1Hour = 3600;

    const timeWidth = [timeSlice1Hour, timeSlice3Hours, timeSlice6Hours][dateIndexFilter];
    const barData = [];

    const filteredData = data
        .filter((d) => d.isAnomalous)
        .filter(
            (d) =>
                (!anomalyFilter || d.anomalyType === anomalyFilter) &&
                (!flowFilter || d.flowName === flowFilter) &&
                (!stateFilter || d.stateId === stateFilter) &&
                new Date(d.dateTime) > dateDaysAgo,
        );

    const startIndex = (page - 1) * pageSize;
    let endIndex = startIndex + pageSize;
    if (endIndex > filteredData.length) {
        endIndex = filteredData.length;
    }
    const paginatedData = filteredData.slice(startIndex, endIndex);

    const errorData = paginatedData.filter((d) => d.anomalyType === ANOMALY_TYPE_ERROR);
    const timeData = paginatedData.filter((d) => d.anomalyType === ANOMALY_TYPE_TIME);
    const usageData = paginatedData.filter((d) => d.anomalyType === ANOMALY_TYPE_USAGE);

    for (
        let index = convertDateToUnixTime(dateDaysAgo);
        index < convertDateToUnixTime(new Date());
        index += timeWidth
    ) {
        const timeLabel = formatChartDate(convertDateFromUnixTime(index), 'long-day');

        const countDataInTimeRange = (data: AnomalyResponse[]) =>
            data.filter((d) => {
                const unix = convertDateToUnixTime(d.dateTime);
                return unix > index && unix < index + timeWidth;
            }).length;

        const numError = countDataInTimeRange(errorData);
        const numTime = countDataInTimeRange(timeData);
        const numUsage = countDataInTimeRange(usageData);

        barData.push({
            x: translations.ANOMALY_type_error,
            y: numError,
            z: timeLabel,
        });

        barData.push({
            x: translations.ANOMALY_type_time,
            y: numTime,
            z: timeLabel,
        });

        barData.push({
            x: translations.ANOMALY_type_usage,
            y: numUsage,
            z: timeLabel,
        });
    }

    const customization = {
        [translations.ANOMALY_type_error]: {
            color: 'var(--exo-color-set-1-1)',
        },
        [translations.ANOMALY_type_time]: {
            color: 'var(--exo-color-set-1-2)',
        },
        [translations.ANOMALY_type_usage]: {
            color: 'var(--exo-color-set-1-3)',
        },
    };

    const barConfig = {
        type: 'stack-bar',
        width: 1000,
        height: 250,
        data: barData,
        showLegends: false,
        tooltip: {
            visible: true,
            subheader: translations.ANOMALY_number_tooltip,
        },
        customization,
    };

    const donutConfig = {
        type: 'donut-chart',
        width: 300,
        height: 300,
        data: [
            {
                x: translations.ANOMALY_type_time,
                y: timeData.length,
                z: translations.ANOMALY_type_time,
            },
            {
                x: translations.ANOMALY_type_error,
                y: errorData.length,
                z: translations.ANOMALY_type_error,
            },
            {
                x: translations.ANOMALY_type_usage,
                y: usageData.length,
                z: translations.ANOMALY_type_usage,
            },
        ],
        showLegends: false,
        tooltip: {
            header: translations.ANOMALY_total_tooltip,
            subheader: '',
        },
        customization,
    };

    const contextValue: AnomalyDashboardProviderContext = {
        anomalyFilter,
        setAnomalyFilter,
        flowFilter,
        setFlowFilter,
        stateFilter,
        setStateFilter,
        flows,
        stateIds,
        dateDaysAgo,
        dateIndexFilter,
        setDateIndexFilter,
        changePagination,
        isLoadingData,
        barConfig,
        donutConfig,
        errorData,
        timeData,
        usageData,
        data,
        filteredData,
        paginatedData,
        pageSize,
        page,
    };

    if (limitReachedError) {
        return (
            <ExAlertBanner data-testid="anomalyLimitBanner" type={AlertBannerType.ERROR} open>
                <div>
                    {limitReachedError}
                    <p>{translations.ANOMALY_limit_reached_suggestion}</p>
                    <b>
                        {translations.ANOMALY_limit_reached_learn_more_1}
                        <a
                            href="https://help.boomi.com/docs/Atomsphere/Flow/topics/flo-Observability_configuration_0b36d4b4-5f27-49d2-b9e2-487954d6df5b"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            {translations.ANOMALY_limit_reached_learn_more_2}
                        </a>
                        {translations.ANOMALY_limit_reached_learn_more_3}
                    </b>
                </div>
            </ExAlertBanner>
        );
    }

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

const useAnomalyDashboard = () => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('useAnomalyDashboard must be used within a AnomalyDashboardProvider');
    }
    return context;
};

export { AnomalyDashboardProvider, useAnomalyDashboard };
