import { Trash } from '@phosphor-icons/react';
import { connect } from 'react-redux';
import {
    addIPRange,
    changeSecuritySetting,
    removeIPRange,
    updateIPRange,
} from '../../../js/actions/reduxActions/currentTenant';
import TextEditor from '../../../js/components/TextEditor';
import translations from '../../translations';
import Table, { type TableColumnList } from '../generic/Table';

export interface IPRangeEntry {
    developerName: string;
    developerSummary: string;
    startIPAddress: string;
    endIPAddress: string;
}

interface SecurityIPSettingProps {
    IPRange: string;
    isActive: boolean;
    settingPropertyName: string;
    rangeEntries: IPRangeEntry[];
    labelText: string;
    updateIPRange: typeof updateIPRange;
    removeIPRange: typeof removeIPRange;
    addIPRange: typeof addIPRange;
    changeSecuritySetting: typeof changeSecuritySetting;
}

const SecurityIPSetting = ({
    IPRange,
    isActive,
    settingPropertyName,
    rangeEntries,
    labelText,
    updateIPRange,
    removeIPRange,
    addIPRange,
    changeSecuritySetting,
}: SecurityIPSettingProps) => {
    const columns: TableColumnList<IPRangeEntry> = [
        {
            renderHeader: () => translations.COMMON_TABLE_name,
            renderCell: ({ item: rangeEntry, rowIndex }) => (
                <TextEditor
                    isEditing={Object.values(rangeEntry).every((value) => value === '')}
                    text={rangeEntry.developerName}
                    // @ts-expect-error TextEditor is written in JS
                    onChange={({ target }) =>
                        updateIPRange({
                            IPRange: IPRange,
                            index: rowIndex,
                            rangeEntry: {
                                ...rangeEntries[rowIndex],
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                                developerName: target.value,
                            },
                        })
                    }
                />
            ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_summary,
            renderCell: ({ item: rangeEntry, rowIndex }) => (
                <TextEditor
                    text={rangeEntry.developerSummary}
                    // @ts-expect-error TextEditor is written in JS
                    onChange={({ target }) =>
                        updateIPRange({
                            IPRange: IPRange,
                            index: rowIndex,
                            rangeEntry: {
                                ...rangeEntries[rowIndex],
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                                developerSummary: target.value,
                            },
                        })
                    }
                />
            ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_start_ip_address,
            renderCell: ({ item: rangeEntry, rowIndex }) => (
                <TextEditor
                    text={rangeEntry.startIPAddress}
                    // @ts-expect-error TextEditor is written in JS
                    onChange={({ target }) =>
                        updateIPRange({
                            IPRange: IPRange,
                            index: rowIndex,
                            rangeEntry: {
                                ...rangeEntries[rowIndex],
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                                startIPAddress: target.value,
                            },
                        })
                    }
                />
            ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_end_ip_address,
            renderCell: ({ item: rangeEntry, rowIndex }) => (
                <TextEditor
                    text={rangeEntry.endIPAddress}
                    // @ts-expect-error TextEditor is written in JS
                    onChange={({ target }) =>
                        updateIPRange({
                            IPRange: IPRange,
                            index: rowIndex,
                            rangeEntry: {
                                ...rangeEntries[rowIndex],
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                                endIPAddress: target.value,
                            },
                        })
                    }
                />
            ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ rowIndex }) => (
                <div className="action-btn-wrapper">
                    <button
                        className="table-icon table-icon-delete"
                        onClick={() =>
                            removeIPRange({
                                IPRange: IPRange,
                                index: rowIndex,
                            })
                        }
                        type="button"
                    >
                        <Trash />
                    </button>
                </div>
            ),
            size: '5rem',
        },
    ];

    return (
        <>
            <div className="checkbox">
                <label>
                    <input
                        className="ip-setting-enable"
                        type="checkbox"
                        checked={isActive}
                        onChange={({ target }) =>
                            changeSecuritySetting({
                                settingName: settingPropertyName,
                                value: target.checked,
                            })
                        }
                    />
                    {labelText}
                </label>
            </div>
            <div className="security-ip-range" hidden={!isActive} data-testid="security-ip-range">
                <Table columns={columns} items={rangeEntries} />
                <button
                    className="link-emulate add-range"
                    onClick={() =>
                        addIPRange({
                            IPRange: IPRange,
                            rangeEntry: {
                                developerName: '',
                                developerSummary: '',
                                startIPAddress: '',
                                endIPAddress: '',
                            },
                        })
                    }
                    type="button"
                >
                    <span className="glyphicon glyphicon-plus-sign" />
                    {translations.TENANT_security_add_ip_range_button_label}
                </button>
            </div>
        </>
    );
};

const mapDispatchToProps = {
    updateIPRange,
    removeIPRange,
    addIPRange,
    changeSecuritySetting,
};

export default connect(null, mapDispatchToProps)(SecurityIPSetting);
