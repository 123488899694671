import translations from '../../../ts/translations';

export const eventTypes = {
    'environment.created': {
        name: 'Environment Created',
        description: 'An environment was created',
        logTemplate: translations.AUDIT_event_environment_created,
    },
    'environment.deleted': {
        name: 'Environment Deleted',
        description: 'An environment was deleted',
        logTemplate: translations.AUDIT_event_environment_deleted,
    },
    'environment.variable.created': {
        name: 'Environment Variable Created',
        description: 'An environment variable was created',
        logTemplate: translations.AUDIT_event_environment_variable_added,
    },
    'environment.variable.updated': {
        name: 'Environment Variable Updated',
        description: 'An environment variable was updated',
        logTemplate: translations.AUDIT_event_environment_variable_updated,
    },
    'environment.variable.deleted': {
        name: 'Environment Variable Deleted',
        description: 'An environment variable was deleted',
        logTemplate: translations.AUDIT_event_environment_variable_removed,
    },
    'flow.activated': {
        name: 'Flow activated',
        description: 'A flow version was set as the active one',
        logTemplate: translations.AUDIT_event_flow_activated,
    },
    'flow.deactivated': {
        name: 'Flow deactivated',
        description: 'A flow version was deactivated',
        logTemplate: translations.AUDIT_event_flow_deactivated,
    },
    'flow.added.release': {
        name: 'Flow added to release',
        description: 'A flow was added to a release',
        logTemplate: translations.AUDIT_event_flow_added_to_release,
    },
    'flow.loaded': {
        name: 'Flow loaded',
        description: 'A flow was loaded',
        logTemplate: translations.AUDIT_event_flow_loaded,
    },
    'flow.package.exported': {
        name: 'Flow package exported',
        description: 'A flow package was exported',
        logTemplate: translations.AUDIT_event_flow_package_exported,
    },
    'flow.package.imported': {
        name: 'Flow package imported',
        description: 'A flow package was imported',
        logTemplate: translations.AUDIT_event_flow_package_imported,
    },
    'flow.package.shared': {
        name: 'Flow package shared',
        description: 'A flow package was shared using a sharing token',
        logTemplate: translations.AUDIT_event_flow_package_shared,
    },
    'flow.removed.release': {
        name: 'Flow removed from release',
        description: 'A flow was removed from a release',
        logTemplate: translations.AUDIT_event_flow_removed_from_release,
    },
    'flow.saved': {
        name: 'Flow saved',
        description: 'A flow was saved',
        logTemplate: translations.AUDIT_event_flow_saved,
    },
    'release.created': {
        name: 'Release Created',
        description: 'A release was created',
        logTemplate: translations.AUDIT_event_release_created,
    },
    'release.deleted': {
        name: 'Release Deleted',
        description: 'A release was deleted',
        logTemplate: translations.AUDIT_event_release_deleted,
    },
    'release.deployed': {
        name: 'Release Deployed',
        description: 'A release was deployed',
        logTemplate: translations.AUDIT_event_release_deployed,
    },
    'release.rolledback': {
        name: 'Release Rolled Back',
        description: 'Release rolled back',
        logTemplate: translations.AUDIT_event_release_rolled_back,
    },
    'tenant.clearing.applied': {
        name: 'Tenant clearing applied',
        description: 'Deletion of all data in a tenant was performed',
        logTemplate: translations.AUDIT_event_tenant_clearing_applied,
    },
    'tenant.clearing.requested': {
        name: 'Tenant clearing requested',
        description: 'Deletion of all data in a tenant was requested',
        logTemplate: translations.AUDIT_event_tenant_clearing_request,
    },
    'tenant.created': {
        name: 'Tenant created',
        description: 'A new tenant was created',
        logTemplate: translations.AUDIT_event_tenant_created,
    },
    'tenant.deletion.applied': {
        name: 'Tenant deletion applied',
        description: 'A tenant was deleted',
        logTemplate: translations.AUDIT_event_tenant_deletion_applied,
    },
    'tenant.deletion.requested': {
        name: 'Tenant deletion requested',
        description: 'A request was made to delete a tenant',
        logTemplate: translations.AUDIT_event_tenant_deletion_requested,
    },
    'tenant.exported': {
        name: 'Tenant exported',
        description: 'A tenant package was created and exported',
        logTemplate: translations.AUDIT_event_tenant_exported,
    },
    'tenant.imported': {
        name: 'Tenant imported',
        description: 'A tenant package containing flows was imported into this tenant',
        logTemplate: translations.AUDIT_event_tenant_imported,
    },
    'tenant.import.started': {
        name: 'Tenant import started',
        description: 'The process of importing a tenant with flows has begun',
        logTemplate: translations.AUDIT_event_tenant_import_started,
    },
    'tenant.saved': {
        name: 'Tenant saved',
        description: 'A tenant was saved',
        logTemplate: translations.AUDIT_event_tenant_saved,
    },
    'tenant.user.added': {
        name: 'Tenant user added',
        description: 'A user was added to a tenant',
        logTemplate: translations.AUDIT_event_user_added,
    },
    'tenant.user.role.updated': {
        name: 'Tenant user role updated',
        description: 'User role was updated and saved',
        logTemplate: translations.AUDIT_event_user_updated_role,
    },
    'tenant.user.removed': {
        name: 'Tenant user removed',
        description: 'A user was removed from a tenant',
        logTemplate: translations.AUDIT_event_user_removed,
    },
    'tenant.key.added': {
        name: 'Tenant key added',
        description: 'An API key was created for a tenant',
        logTemplate: translations.AUDIT_event_tenant_key_added,
    },
    'tenant.key.removed': {
        name: 'Tenant key removed',
        description: 'An API key was removed for a tenant',
        logTemplate: translations.AUDIT_event_tenant_key_removed,
    },
    'theme.deleted': {
        name: 'Theme Deleted',
        description: 'The user deleted a theme',
        logTemplate: translations.AUDIT_event_theme_deleted,
    },
    'theme.release.added': {
        name: 'Theme Added To Release',
        description: 'The user added a theme to a release',
        logTemplate: translations.AUDIT_event_theme_added_to_release,
    },
    'theme.release.removed': {
        name: 'Theme Removed From Release',
        description: 'The user removed a theme from a release',
        logTemplate: translations.AUDIT_event_theme_removed_from_release,
    },
    'theme.saved': {
        name: 'Theme Saved',
        description: 'The user saved a theme',
        logTemplate: translations.AUDIT_event_theme_saved,
    },
    'user.logged.in': {
        name: 'User Logged In',
        description: 'The user logged into a tenant',
        logTemplate: translations.AUDIT_event_user_logged_in,
    },
};

export function getEventType(type) {
    if (type in eventTypes) {
        return eventTypes[type];
    }

    return {
        name: type,
        description: '',
    };
}
