import { useEffect, useState } from 'react';
import type {
    ContentType,
    ValueElementIdAPI,
    ValueElementIdReferenceAPI,
    ValueElementResponseAPI,
} from '../../../types';
import GenericModal from '../../generic/modal/GenericModal';
import ValueSelector from './ValueSelector';
import ValueSelectorValues from './ValueSelectorValues';
import {
    AlertBannerType,
    ButtonFlavor,
    ButtonType,
    ExAlertBanner,
    ExButton,
    ExLoader,
} from '@boomi/exosphere';
import { getValueList } from '../../../sources/value';
import '../../../../../css/value-selector.less';
import translations from '../../../translations';
import { pick } from 'ramda';
import ValueEditorModal from '../editor/ValueEditorModal';

type ViewState =
    | 'LOADING'
    | 'SELECTED'
    | 'SELECTING'
    | 'CREATE_NEW'
    | 'EDITING_SELECTED'
    | 'EDITING_FROM_VALUES'
    | 'ERROR';

interface Props {
    value: ValueElementIdAPI | ValueElementIdReferenceAPI | null;
    contentType?: ContentType;
    typeElementId?: string | null;
    includeSystemValues: boolean;
    container: HTMLElement | null | undefined;
    startingValueName?: string | undefined;
    forgetValueSelection?: boolean | undefined;
    onChange?: (value: ValueElementIdAPI | null) => void;
    onChangeAsValueReference?: (value: ValueElementIdReferenceAPI | null) => void;
}

const ValueSelectorModal = ({
    value,
    contentType,
    typeElementId,
    includeSystemValues,
    container,
    startingValueName,
    forgetValueSelection,
    onChange,
    onChangeAsValueReference,
}: Props) => {
    const [view, setView] = useState<ViewState>('LOADING');
    const [valueToEdit, setValueToEdit] = useState<ValueElementIdAPI | null>(null);
    const [valueReference, setValueReference] = useState<ValueElementIdReferenceAPI | null>(null);
    const [error, setError] = useState<string | null>(null);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const isValueElementIdReference = (
            value: ValueElementIdAPI | ValueElementIdReferenceAPI | null,
        ) => {
            return !!(value as ValueElementIdReferenceAPI)?.contentType;
        };

        const load = async () => {
            if (value) {
                try {
                    await loadValueReference(value.id, value.typeElementPropertyId);
                    setView('SELECTED');
                } catch (error) {
                    setError((error as Error).message);
                    setView('ERROR');
                }
            }
        };

        if (value) {
            if (isValueElementIdReference(value)) {
                setValueReference(value as ValueElementIdReferenceAPI);

                if (view === 'LOADING') {
                    setView('SELECTED');
                }
            } else {
                load();
            }
        } else {
            setValueReference(null);

            if (view === 'LOADING') {
                setView('SELECTED');
            }
        }
    }, [value]);

    const loadValueReference = async (
        id: string | undefined | null,
        typeElementPropertyId: string | null | undefined,
    ) => {
        if (id) {
            const references = await getValueList({
                id: [id],
                includeSystemValues: true,
            });

            if (typeElementPropertyId) {
                setValueReference(
                    references.find(
                        (ref) =>
                            ref.id === id && ref.typeElementPropertyId === typeElementPropertyId,
                    ) || null,
                );
            } else {
                setValueReference(references[0]);
            }
        }
    };

    const onValueChanged = (value: ValueElementIdReferenceAPI | null) => {
        setView('SELECTED');

        if (!forgetValueSelection) {
            setValueReference(value);
        }

        onChange?.(
            value
                ? {
                      ...pick(['id', 'typeElementPropertyId'], value),
                      command: null,
                  }
                : null,
        );
        onChangeAsValueReference?.(value);
    };

    const onNewValueSave = (value: ValueElementResponseAPI) => {
        onValueChanged({
            id: value.id,
            access: value.access,
            developerName: value.developerName,
            contentType: value.contentType,
            typeElementId: value.typeElementId,
            typeElementDeveloperName: value.typeElementDeveloperName,
            typeElementPropertyDeveloperName: null,
            typeElementPropertyId: null,
            typeElementPropertyTypeElementDeveloperName: null,
            typeElementPropertyTypeElementId: null,
            confidence: null,
            elementType: 'VARIABLE',
            serviceElementId: null,
            serviceElementDeveloperName: null,
            parentContentType: null,
            command: null,
        });
    };

    const onEditSelectedValue = (value: ValueElementIdReferenceAPI) => {
        setView('EDITING_SELECTED');
        setValueToEdit(value);
    };

    const onEditFromSelectingValue = (value: ValueElementIdReferenceAPI) => {
        setView('EDITING_FROM_VALUES');
        setValueToEdit(value);
    };

    const onEditingSelectedClose = async () => {
        setView('LOADING');
        setValueToEdit(null);

        try {
            await loadValueReference(value?.id, value?.typeElementPropertyId);
            setView('SELECTED');
        } catch (error) {
            setError((error as Error).message);
            setView('ERROR');
        }
    };

    const onEditingFromListClose = () => {
        setView('SELECTING');
        setValueToEdit(null);
    };

    let modal = null;

    switch (view) {
        case 'LOADING': {
            modal = <ExLoader />;
            break;
        }

        case 'SELECTING': {
            modal = (
                <GenericModal
                    onHide={() => setView('SELECTED')}
                    className="value-selector-modal"
                    renderBody={() => {
                        return (
                            <ValueSelectorValues
                                contentType={contentType}
                                typeElementId={typeElementId}
                                includeSystemValues={includeSystemValues}
                                onChange={onValueChanged}
                                onCreate={() => setView('CREATE_NEW')}
                                onEdit={onEditFromSelectingValue}
                            />
                        );
                    }}
                    renderFooter={() => {
                        return (
                            <ExButton
                                onClick={() => setView('SELECTED')}
                                flavor={ButtonFlavor.BASE}
                                type={ButtonType.SECONDARY}
                                className="value-selector-modal-cancel"
                            >
                                {translations.COMMON_cancel}
                            </ExButton>
                        );
                    }}
                    container={container}
                />
            );
            break;
        }

        case 'CREATE_NEW': {
            modal = (
                <ValueEditorModal
                    title={translations.VALUE_EDITOR_modal_title_new}
                    onCancel={() => setView('SELECTING')}
                    onSave={onNewValueSave}
                    container={container}
                    startingValueName={startingValueName}
                    startingValueContentType={contentType}
                    startingValueTypeElementId={typeElementId}
                />
            );
            break;
        }

        case 'EDITING_SELECTED': {
            modal = (
                <ValueEditorModal
                    id={valueToEdit?.id}
                    title={translations.VALUE_EDITOR_modal_title_edit}
                    onCancel={onEditingSelectedClose}
                    onSave={onEditingSelectedClose}
                    container={container}
                />
            );
            break;
        }

        case 'EDITING_FROM_VALUES': {
            modal = (
                <ValueEditorModal
                    id={valueToEdit?.id}
                    title={translations.VALUE_EDITOR_modal_title_edit}
                    onCancel={onEditingFromListClose}
                    onSave={onEditingFromListClose}
                    container={container}
                />
            );
            break;
        }

        case 'ERROR': {
            modal = (
                <GenericModal
                    onHide={() => setView('SELECTED')}
                    className="value-selector-modal"
                    renderBody={() => {
                        return (
                            <ExAlertBanner open={true} type={AlertBannerType.ERROR}>
                                {error}
                            </ExAlertBanner>
                        );
                    }}
                    renderFooter={() => {
                        return (
                            <ExButton
                                onClick={() => setView('SELECTED')}
                                flavor={ButtonFlavor.BASE}
                                type={ButtonType.SECONDARY}
                                className="value-selector-modal-cancel"
                            >
                                {translations.COMMON_cancel}
                            </ExButton>
                        );
                    }}
                    container={container}
                />
            );
            break;
        }
    }

    return (
        <>
            {view !== 'LOADING' ? (
                <ValueSelector
                    value={valueReference}
                    onOpen={() => setView('SELECTING')}
                    onRemove={() => onValueChanged(null)}
                    onEdit={onEditSelectedValue}
                    buttonType={ButtonType.SECONDARY}
                />
            ) : null}
            {modal}
        </>
    );
};

export default ValueSelectorModal;
