import { type ComponentPropsWithoutRef, useState } from 'react';
import { connect } from 'react-redux';
import '../../../../css/create-subtenant.less';
import { addNotification } from '../../../js/actions/reduxActions/notification';
import { createSubtenant } from '../../sources/tenant';
import translations from '../../translations';
import TenantNameInput from './TenantNameInput';
import { TENANT_VIEWS } from '../../../js/components/tenant/tenantPages';
import { useAuth } from '../AuthProvider';
import type { AddNotification } from '../../types';
import { tryGetErrorMessage } from '../../utils';
import { NOTIFICATION_TYPES } from '../../constants';

interface CreateSubtenantProps {
    navigateTo: (pageId: string) => void;
    addNotification: AddNotification;
}

const CreateSubtenant = ({ navigateTo, addNotification }: CreateSubtenantProps) => {
    const [subtenantName, setSubtenantName] = useState('');

    const { tenant } = useAuth();

    if (!tenant) {
        return null;
    }

    const { id: tenantId, developerName: parentTenantName } = tenant;

    const handleInput: ComponentPropsWithoutRef<typeof TenantNameInput>['onChange'] = ({
        value,
    }) => {
        setSubtenantName(value);
    };

    const onCreate = async () => {
        try {
            await createSubtenant(tenantId, subtenantName);

            navigateTo(TENANT_VIEWS.tenant);
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: tryGetErrorMessage(error),
                isPersistent: true,
            });
        }
    };

    return (
        <>
            <div className="admin-page">
                <div className="create-subtenant-name-input">
                    <TenantNameInput
                        inputId="tenant-name-input"
                        labelText={translations.TENANT_create_subtenant_name_input_label}
                        value={subtenantName}
                        onChange={handleInput}
                        parentTenantName={parentTenantName}
                        autoFocus={true}
                    />
                </div>
            </div>
            <div className="admin-footer">
                <button
                    type="button"
                    className="outcome btn btn-default create-subtenant-cancel"
                    onClick={() => navigateTo(TENANT_VIEWS.tenant)}
                >
                    {translations.TENANT_create_subtenant_cancel_button_label}
                </button>
                <button
                    type="button"
                    className="btn btn-primary outcome create-subtenant"
                    onClick={onCreate}
                >
                    {translations.TENANT_create_subtenant_create_button_label}
                </button>
            </div>
        </>
    );
};

const mapDispatchToProps = {
    addNotification,
};

export default connect(null, mapDispatchToProps)(CreateSubtenant);
