import { useGraph } from '../../../../js/components/graph/GraphProvider';
import { House } from '@phosphor-icons/react';
import ButtonDanger from '../../buttons/ButtonDanger';
import ButtonDefault from '../../buttons/ButtonDefault';
import ButtonPrimary from '../../buttons/ButtonPrimary';
import {
    BadgeColor,
    BadgeSize,
    ExBadge,
    ExBreadcrumb,
    ExBreadcrumbItem,
    ExEmptyState,
} from '@boomi/exosphere';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';
import type {
    ElementGroupedChange,
    ElementInfo,
    FlowResponseAPI,
    ReleasedSnapshot,
} from '../../../types';
import type { Environment } from '../../../types/environment';
import { formatDistanceStrict } from 'date-fns';
import ComponentWithTooltip from '../../generic/tooltip/ComponentWithTooltip';
import translations from '../../../translations';

interface UseGraph {
    snapshots: ReleasedSnapshot[] | FlowResponseAPI[];
    inspectHistory: (path: string[] | null) => void;
    calculateHistory: (beforeVersionId: string, afterVersionId: string) => void;
    historyData: (ElementInfo | ElementGroupedChange)[];
    historyPath: string[];
    environments: Environment[];
}

const History = () => {
    const {
        snapshots,
        inspectHistory,
        calculateHistory,
        historyData,
        historyPath,
        environments,
    }: UseGraph = useGraph();

    const currentHistoryItemPath =
        historyData.length > 0 && 'path' in historyData[0] ? historyData[0]?.path : [];

    // path of displayed items, their paths are deeper than the current view
    const nextPath = historyData.length > 0 ? [historyData[0].id, ...currentHistoryItemPath] : null;
    // path of the current view, this has resulted in the current list of diffs
    const currentPath = nextPath?.slice(0, -1);
    // path of the previous view
    const backPath = currentPath?.slice(0, -1);

    const goBackInHistory = () => {
        if (currentHistoryItemPath.length === 0) {
            return inspectHistory(null);
        }
        inspectHistory(backPath ?? null);
    };

    const diffSection = (change: ElementInfo | ElementGroupedChange) => {
        if ('diffs' in change) {
            return change.diffs.length > 0 ? (
                <ButtonDefault onClick={() => inspectHistory([change.id, ...change.path])}>
                    {change?.diffs?.length}
                    {change?.diffs?.length === 1 ? ' Change' : ' Changes'}
                </ButtonDefault>
            ) : (
                <div className="diff">
                    <ReactDiffViewer
                        oldValue={change.before ?? ''}
                        newValue={change.after ?? ''}
                        compareMethod={DiffMethod.WORDS}
                        hideLineNumbers={true}
                    />
                </div>
            );
        }
        return null;
    };

    const environmentTypeToColour = (environmentType?: string) =>
        environmentType === 'Production'
            ? BadgeColor.GREEN
            : environmentType === 'Test'
              ? BadgeColor.BLUE
              : BadgeColor.WHITE;

    const getVersionId = (snapshot: ReleasedSnapshot | FlowResponseAPI) => {
        if ('versionId' in snapshot) {
            return (snapshot as ReleasedSnapshot).versionId;
        }
        return (snapshot as FlowResponseAPI).id.versionId;
    };

    return (
        <div className="history-panel">
            <h4>Flow History</h4>
            {snapshots.length > 0 && historyData?.length === 0
                ? snapshots.map((s, index) => {
                      let greyBadgeName = null;
                      let colouredBadgeColour = null;
                      let colouredBadgeName = null;

                      if ('versionId' in s) {
                          const snapshot = s as ReleasedSnapshot;
                          greyBadgeName = snapshot.releaseName;
                          colouredBadgeColour = environmentTypeToColour(
                              environments.find((e) => e.id === snapshot.environmentId)
                                  ?.classificationType,
                          );
                          colouredBadgeName = snapshot.environmentName;
                      } else {
                          const snapshot = s as FlowResponseAPI;
                          greyBadgeName = snapshot.isActive ? 'Publish' : 'Build';
                          colouredBadgeColour = BadgeColor.GREEN;
                          colouredBadgeName = snapshot.isDefault ? 'Default' : null;
                      }

                      const versionId = getVersionId(s);

                      return (
                          <div className="snapshot" key={versionId}>
                              <div className="content">
                                  <span className="collaboration-user collaboration-user-1">
                                      TF
                                  </span>
                                  <span className="text">
                                      <span className="version-and-date">
                                          <span className="version">
                                              Version {versionId.substring(0, 6)}
                                          </span>
                                          <ComponentWithTooltip
                                              trigger={['hover', 'focus']}
                                              showDelay={500}
                                              fadeTime={0.2}
                                              tooltipPlacement="top"
                                              tooltipContent={new Date(
                                                  s.dateCreated,
                                              ).toLocaleString(undefined, {
                                                  dateStyle: 'medium',
                                                  timeStyle: 'short',
                                              })}
                                          >
                                              <span className="quiet">
                                                  {formatDistanceStrict(
                                                      new Date(s.dateCreated),
                                                      Date.now(),
                                                      { addSuffix: true },
                                                  )}
                                              </span>
                                          </ComponentWithTooltip>
                                      </span>
                                      <span className="summary" title={s.comment ?? ''}>
                                          {s.comment}
                                      </span>
                                      <span className="badge-group">
                                          <ExBadge color={BadgeColor.GRAY} size={BadgeSize.SMALL}>
                                              {greyBadgeName}
                                          </ExBadge>
                                          {colouredBadgeName && (
                                              <ExBadge
                                                  color={colouredBadgeColour}
                                                  size={BadgeSize.SMALL}
                                              >
                                                  {colouredBadgeName}
                                              </ExBadge>
                                          )}
                                      </span>
                                  </span>
                              </div>
                              <div className="flex">
                                  <ButtonDanger>Restore</ButtonDanger>
                                  {snapshots.length > index + 1 ? (
                                      <ButtonDefault
                                          onClick={() =>
                                              calculateHistory(
                                                  getVersionId(snapshots[index + 1]),
                                                  versionId,
                                              )
                                          }
                                      >
                                          View Changes
                                      </ButtonDefault>
                                  ) : null}
                                  <ButtonPrimary>Publish</ButtonPrimary>
                              </div>
                          </div>
                      );
                  })
                : null}
            {snapshots.length > 0 || historyData?.length > 0 ? null : (
                <ExEmptyState
                    label={translations.FLOW_HISTORY_no_snapshots}
                    text={translations.FLOW_HISTORY_no_snapshots_description}
                />
            )}
            {historyData?.length > 0 ? (
                <>
                    <ButtonDefault onClick={goBackInHistory}>Back</ButtonDefault>
                    <ExBreadcrumb>
                        <ExBreadcrumbItem onClick={() => inspectHistory(null)}>
                            <House
                                size={24}
                                style={{
                                    translate: '5px 2px',
                                }}
                                className="link-emulate"
                            />
                        </ExBreadcrumbItem>
                        {historyPath.map((t, index) => (
                            <ExBreadcrumbItem
                                onClick={() => inspectHistory(currentPath?.slice(0, index) ?? null)}
                                // biome-ignore lint/suspicious/noArrayIndexKey: No alternative to index
                                key={index}
                            >
                                <span
                                    className={
                                        index !== historyPath.length - 1 ? 'link-emulate' : ''
                                    }
                                >
                                    {t}
                                </span>
                            </ExBreadcrumbItem>
                        ))}
                    </ExBreadcrumb>
                    {historyData.map((d) => (
                        <div className="snapshot" key={'path' in d ? d.path.join() : d.id}>
                            <div className="content">
                                <span className="collaboration-user collaboration-user-1">TF</span>
                                <span className="text">
                                    <span>{d.developerName}</span>
                                    <span className="quiet">
                                        {d.elementType &&
                                            d.elementType.substring(0, 1).toUpperCase() +
                                                d.elementType.substring(1).toLowerCase()}{' '}
                                        {'operationType' in d ? d.operationType : null}
                                    </span>
                                </span>
                            </div>
                            {diffSection(d)}
                        </div>
                    ))}
                </>
            ) : null}
        </div>
    );
};

export default History;
