import { useEffect } from 'react';
import { useAuth } from '../AuthProvider';
import addMinutes from 'date-fns/addMinutes';
import { NOTIFICATION_TYPES } from '../../constants';

const IdleTimeout = () => {
    const { getSSOMetadata, signOutUser, addNotification, idleTimeoutKey } = useAuth();
    const ssoMetadata = getSSOMetadata();
    useEffect(() => {
        if (!(ssoMetadata?.idleTimeout && ssoMetadata?.idleTimeout > 0)) {
            localStorage.removeItem(idleTimeoutKey);
            return;
        }
        const updateTimeout = () =>
            localStorage.setItem(
                idleTimeoutKey,
                addMinutes(new Date(), ssoMetadata.idleTimeout as number).toISOString(),
            );
        window.addEventListener('click', updateTimeout);
        window.addEventListener('keyup', updateTimeout);
        updateTimeout();
        const interval = setInterval(() => {
            const storedIdleTimeout = localStorage.getItem(idleTimeoutKey);

            if (storedIdleTimeout && Date.now() > new Date(storedIdleTimeout).getTime()) {
                clearInterval(interval);
                localStorage.removeItem(idleTimeoutKey);
                signOutUser();
            }

            if (storedIdleTimeout) {
                const storedTimeoutDate = Date.parse(storedIdleTimeout);
                const roundedMinutesLeft = Math.round((storedTimeoutDate - Date.now()) / 1000 / 60);
                // Don't display a warning at less than a minute as it looks buggy
                if (roundedMinutesLeft < 6 && roundedMinutesLeft !== 0) {
                    addNotification({
                        type: NOTIFICATION_TYPES.warning,
                        message: `You will be signed out due to inactivity in ${roundedMinutesLeft} minute(s) at ${new Date(
                            storedIdleTimeout,
                        ).toLocaleString()}.`,
                        isPersistent: true,
                    });
                }
            }
        }, 60000); //every minute

        return () => {
            if (interval) {
                clearInterval(interval);
            }
            localStorage.removeItem(idleTimeoutKey);
            window.removeEventListener('click', updateTimeout);
            window.removeEventListener('keyup', updateTimeout);
        };
    }, [addNotification, idleTimeoutKey, signOutUser, ssoMetadata]);
    return <></>;
};

export default IdleTimeout;
