import translations from '../../../../ts/translations';
import { connect } from 'react-redux';
import { changeOtlpSetting } from '../../../actions/reduxActions/currentTenant';

/**
 * @param {Object} settings e.g. { otlpEndpoint: "https://example.com", otlpHeaders: "foo=bar,fizz=buzz", otlpSensitiveDataLogging: false }
 * @param {Function} changeOtlpSetting Redux action
 * @description Form for updating OTLP setting for a tenant
 */
const OtlpSettings = ({ settings, changeOtlpSetting: updateSettings }) => {
    const onChangeOtlpEndpoint = (e) =>
        updateSettings({
            settingPath: ['otlpEndpoint'],
            value: e.currentTarget.value,
        });

    const onChangeOtlpHeaders = (e) =>
        updateSettings({
            settingPath: ['otlpHeaders'],
            value: e.currentTarget.value,
        });

    const onChangeOtlpSensitiveData = (e) =>
        updateSettings({
            settingPath: ['otlpSensitiveDataLogging'],
            value: e.currentTarget.value,
        });

    return (
        <>
            <h2 id="tenant-observability">Observability</h2>
            <p>
                {translations.TENANT_observability_description_1}
                <br />
                {translations.TENANT_observability_description_2}
            </p>
            <div className="form-group">
                <label htmlFor="otlp-endpoint">{translations.OTLP_endpoint_label}</label>
                <span className="input-required"> *</span>
                <input
                    id="otlp-endpoint"
                    className="form-control"
                    type="text"
                    onChange={onChangeOtlpEndpoint}
                    value={settings?.otlpEndpoint || ''}
                />

                <p className="help-block">
                    {translations.OTLP_endpoint_help_1}
                    <br />
                    {translations.OTLP_endpoint_help_2}
                </p>
            </div>

            <div className="form-group">
                <label htmlFor="otlp-headers">{translations.OTLP_headers_label}</label>
                <input
                    id="otlp-headers"
                    className="form-control"
                    type="text"
                    onChange={onChangeOtlpHeaders}
                    value={settings?.otlpHeaders || ''}
                />

                <p className="help-block">
                    {translations.OTLP_headers_help_1}
                    <br />
                    {translations.OTLP_headers_help_2}
                </p>
            </div>

            <div className="form-group">
                <label htmlFor="otlp-sensitive-data">
                    {translations.OTLP_sensitive_data_label}
                </label>
                <select
                    id="otlp-sensitive-data"
                    value={settings?.otlpSensitiveDataLogging}
                    onChange={onChangeOtlpSensitiveData}
                    className="form-control"
                >
                    <option value="0">{translations.OTLP_sensitive_none}</option>
                    <option value="1">{translations.OTLP_sensitive_on_error}</option>
                    <option value="2">{translations.OTLP_sensitive_all}</option>
                </select>
                <p className="help-block">
                    {translations.OTLP_sensitive_data_help_1}
                    <br />
                    {translations.OTLP_sensitive_data_help_2}
                </p>
            </div>
        </>
    );
};

const mapDispatchToProps = {
    changeOtlpSetting,
};

export default connect(null, mapDispatchToProps)(OtlpSettings);
