import SearchInput from '../../SearchInput';
import CopyableText from '../../../../ts/components/generic/CopyableText';
import Table from '../../../../ts/components/generic/Table';
import translations from '../../../../ts/translations';
import { useEffect } from 'react';
import { useTypes } from './TypesProvider';
import { stringContains } from '../../../../ts/utils/string';
import { Trash } from '@phosphor-icons/react';

const AddPropertyButton = ({ createProperty, isValid, showValidation }) => (
    <div className="flex-child-right">
        <button
            className="btn btn-success btn-sm"
            onClick={createProperty}
            title="New Property"
            type="button"
        >
            <span className="glyphicon glyphicon-plus" />
            <span>New Property</span>
        </button>
        {!isValid && showValidation && (
            <span className="help-block error-state">You must add properties</span>
        )}
    </div>
);

const TypeProperties = ({ hasSubmitted, onPropertyDelete }) => {
    const {
        typeToEdit,
        createNewTypeProperty,
        editProperty,
        propertyPaging: pager,
        updatePropertyPaging: updatePager,
    } = useTypes();

    const properties = typeToEdit.properties || [];

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        updatePager({ ...pager, total: properties ? properties.length : 0 });
    }, []);

    const propertyColumns = [
        {
            renderHeader: () => translations.COMMON_TABLE_name,
            renderCell: ({ item }) => (
                <button className="link-emulate" onClick={() => editProperty(item)} type="button">
                    {item.developerName}
                </button>
            ),
            size: '400px',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_type,
            renderCell: ({ item }) => <div>{item.contentType}</div>,
            size: '400px',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_type_name,
            renderCell: ({ item }) => <div>{item.typeElementDeveloperName}</div>,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_id,
            renderCell: ({ item }) => <CopyableText copyableText={item.id} hasCopyButton={true} />,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item }) => (
                <div className="action-btn-wrapper">
                    <button
                        title={`Delete ${item.developerName}`}
                        className="table-icon table-icon-delete"
                        aria-label={`Delete ${item.developerName}`}
                        onClick={() => onPropertyDelete(item)}
                        type="button"
                    >
                        <Trash />
                    </button>
                </div>
            ),
            size: '5rem',
        },
    ];

    const onPage = (page) => {
        updatePager({ ...pager, page });
    };

    const onSearch = (search) => {
        updatePager({ ...pager, search });
    };

    const filterResults = () => {
        const queryResult = properties.filter((property) =>
            stringContains(property.developerName, pager.search, false),
        );

        const startIndex = (page - 1) * pageSize;
        let endIndex = startIndex + pageSize;

        if (endIndex > queryResult.length) {
            endIndex = queryResult.length;
        }

        return queryResult.slice(startIndex, endIndex);
    };

    const { page, search, total, pageSize } = pager;
    const hasProperties = properties.length > 0;

    const propertiesList = hasProperties ? (
        <>
            <h2>Properties</h2>
            <div className="flex">
                <SearchInput onSearch={onSearch} defaultValue={search} />
                <AddPropertyButton
                    createProperty={createNewTypeProperty}
                    isValid={hasProperties}
                    showValidation={hasSubmitted}
                />
            </div>
            <Table
                wrapperClassName="margin-top"
                columns={propertyColumns}
                items={filterResults()}
                pagination={{
                    page,
                    total,
                    pageSize,
                    changePage: onPage,
                }}
                testId="type-properties"
            />
        </>
    ) : (
        <AddPropertyButton
            createProperty={createNewTypeProperty}
            isValid={hasProperties}
            showValidation={hasSubmitted}
        />
    );

    return propertiesList;
};

export default TypeProperties;
