import { useNavigate } from 'react-router-dom';
import type { FlowLaunchesDashboardResponse } from '../../sources/dashboard';
import translations from '../../translations';
import { stringReplace } from '../../utils';
import {
    getLaunchesPercentage,
    getNumberOfAnomalyEventsForFlow,
    onGoToFlow,
} from '../../utils/dashboard';
import Table, { type TableColumnList } from '../generic/Table';
import { useAnomalyDashboard } from '../anomalyDetection/AnomalyProvider';

interface Props {
    flowLaunches: FlowLaunchesDashboardResponse[];
}

const FlowLaunchesTable = ({ flowLaunches }: Props) => {
    const navigate = useNavigate();
    const { data: anomalyEvents } = useAnomalyDashboard();

    const columns: TableColumnList<FlowLaunchesDashboardResponse> = [
        {
            renderHeader: () => translations.COMMON_TABLE_flow_name,
            renderCell: ({ item }) => (
                <button
                    className="link-emulate"
                    onClick={() => onGoToFlow(item.flowId, navigate)}
                    title={stringReplace(translations.DASHBOARD_go_to_flow_title, item.flowName)}
                    type="button"
                >
                    {item.flowName}
                </button>
            ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_usage,
            renderCell: ({ item }) => <span className="dash-pill">{item.flowLaunchTotal}</span>,
            size: '7rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_usage_percentage,
            renderCell: ({ item }) => (
                <span className="dash-pill">
                    {getLaunchesPercentage(item.flowLaunchTotal, flowLaunches)}%
                </span>
            ),
            size: '8rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_anomalies,
            renderCell: ({ item }) => (
                <span className="dash-pill">
                    {getNumberOfAnomalyEventsForFlow(item.flowId, 'Time', anomalyEvents)}
                </span>
            ),
            size: '6rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_errors,
            renderCell: ({ item }) => (
                <span className="dash-pill">
                    {getNumberOfAnomalyEventsForFlow(item.flowId, 'Error', anomalyEvents)}
                </span>
            ),
            size: '5rem',
        },
        {
            renderHeader: () => '',
            renderCell: () => (
                <button className="link-emulate" type="button">
                    {translations.DASHBOARD_inpect}
                </button>
            ),
            size: '5rem',
        },
    ];

    return (
        <Table
            columns={columns}
            items={flowLaunches}
            rowClassName={() => 'generic-row generic-row-tall'}
        />
    );
};

export default FlowLaunchesTable;
