import { TAB_TYPES } from '../constants';
import type { AnomalyResponse } from '../sources/anomalyEvents';
import type { FlowLaunchesDashboardResponse } from '../sources/dashboard';
import type { TimeFilters } from '../types/dashboard';
import { generateRouteUrl } from './routing';
import { getTenantId } from './tenant';

export const onGoToFlow = (flowId: string, navigate: (route: string) => void) => {
    const route = generateRouteUrl({
        tabType: TAB_TYPES.flow,
        tenantId: getTenantId(),
        options: { elementId: flowId },
    });

    if (!route) {
        return;
    }

    navigate(route);
};

export const getLaunchesPercentage = (count: number, flows: FlowLaunchesDashboardResponse[]) => {
    const totalCount = flows.reduce((accumulator, flow) => accumulator + flow.flowLaunchTotal, 0);
    const percentage = (count / totalCount) * 100;
    return Math.round((percentage + Number.EPSILON) * 100) / 100;
};

export const getNumberOfAnomalyEventsForFlow = (
    flowId: string,
    eventType: 'Time' | 'Error',
    anomalyEvents: AnomalyResponse[],
) =>
    anomalyEvents.filter(
        (anomalyEvent) => anomalyEvent.flowId === flowId && anomalyEvent.anomalyType === eventType,
    ).length;

export const sortFlowLaunchesByCount = (flowsLaunches: FlowLaunchesDashboardResponse[]) =>
    flowsLaunches.sort((a, b) => {
        if (a.flowLaunchTotal > b.flowLaunchTotal) {
            return -1;
        }

        if (a.flowLaunchTotal < b.flowLaunchTotal) {
            return 1;
        }

        return 0;
    });

export const formatChartDate = (date: Date, dateRange: TimeFilters) => {
    if (dateRange === 'year') {
        return date.toLocaleString(navigator.language, {
            month: 'short',
            year: 'numeric',
        });
    }

    if (dateRange === 'month' || dateRange === 'week') {
        return date.toLocaleString(navigator.language, {
            day: '2-digit',
            month: 'short',
        });
    }

    // This is for if we want hourly (day) but with the month and day included.
    if (dateRange === 'long-day') {
        return date.toLocaleString(navigator.language, {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            hourCycle: 'h12',
        });
    }

    return date.toLocaleString(navigator.language, {
        weekday: 'short',
        hour: 'numeric',
        hourCycle: 'h12',
    });
};

export const convertDateToUnixTime = (date: Date) => Math.floor(new Date(date).getTime() / 1000);

export const convertDateFromUnixTime = (date: number) => new Date(date * 1000);
