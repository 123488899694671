import { connect } from 'react-redux';
import ErrorBoundary from '../../ts/components/generic/ErrorBoundary';
import { getTenantPages } from './tenant/tenantPages';
import Build from './build';
import PlayerEditor from '../../ts/components/player-editor/PlayerEditor';
import Package from './package';
import MacroEditor from '../../ts/components/macro-editor/MacroEditor';
import Dashboard from './dashboard/Dashboard';
import PageList from '../../ts/components/admin/pages/PageList';
import { isSubtenant } from '../../ts/utils/tenant';
import PageSwitcher from '../../ts/components/generic/PageSwitcher';
import { ServicesProvider } from '../../ts/components/services/contextProviders/ServicesProvider';
import ServiceController from '../../ts/components/services/ServiceController';
import InitialOrgPage from './flowOrganization/InitialOrgPage';
import AuditLogs from './auditLogs/AuditLogs';
import UserSettings from '../../ts/components/userSettings/UserSettings';
import Features from './features/Features';
import classnames from 'classnames';
import { TAB_TYPES } from '../../ts/constants';
import { ValuesProvider } from '../../ts/components/admin/values/ValuesProvider';
import Values from '../../ts/components/admin/values/Values';
import { TypesProvider } from './admin/types/TypesProvider';
import Types from './admin/types/Types';
import IdentityProviders from '../../ts/components/admin/identityproviders/IdentityProviders';
import { addNotification, notifySuccess, notifyError } from '../actions/reduxActions/notification';
import { EnvironmentsProvider } from './admin/environments/EnvironmentsProvider';
import Environments from './admin/environments/Environments';
import Environment from './admin/environments/Environment';
import { CustomPageComponentsProvider } from './admin/customPageComponents/CustomPageComponentsProvider';
import CustomPageComponents from './admin/customPageComponents/CustomPageComponents';
import translations from '../../ts/translations';
import { useAuth } from '../../ts/components/AuthProvider';
import Home from '../../ts/components/flow/homePage/Home';
import Themes from '../../ts/components/theme/Themes';
import AssetTab from '../../ts/components/assets/AssetTab';
import AnomalyDashboard from '../../ts/components/anomalyDetection/AnomalyDashboard';
import DeveloperDashboard from '../../ts/components/developerDashboard/DeveloperDashboard';
import { getFlag } from '../../ts/utils/flags';
import { AnomalyDashboardProvider } from '../../ts/components/anomalyDetection/AnomalyProvider';

import '../../../css/admin.less';
import '../../../css/modal.less';

const Admin = ({ isActive, tabType, addNotification, notifySuccess, notifyError }) => {
    const { tenant } = useAuth();
    const { id: tenantId, developerName: tenantName, tenantSettings } = tenant;
    const environmentsIsOn = tenantSettings?.environments;

    if (!tenantId) {
        return null;
    }

    let content = null;

    const currentTenantIsSubtenant = isSubtenant(tenantName);

    switch (tabType) {
        case TAB_TYPES.auditing: {
            content = (
                <ErrorBoundary>
                    <AuditLogs tenantId={tenantId} />
                </ErrorBoundary>
            );
            break;
        }

        case TAB_TYPES.tenant: {
            content = (
                <ErrorBoundary>
                    <PageSwitcher pages={getTenantPages(currentTenantIsSubtenant)} />
                </ErrorBoundary>
            );
            break;
        }

        case TAB_TYPES.flows: {
            content = (
                <ErrorBoundary>
                    <Home isEditorActive={isActive} />
                </ErrorBoundary>
            );
            break;
        }

        case TAB_TYPES.services: {
            content = (
                <ErrorBoundary>
                    <ServicesProvider addNotification={addNotification} tenantId={tenantId}>
                        <ServiceController />
                    </ServicesProvider>
                </ErrorBoundary>
            );
            break;
        }

        case TAB_TYPES.types: {
            content = (
                <ErrorBoundary>
                    <TypesProvider addNotification={addNotification} tenantId={tenantId}>
                        <Types />
                    </TypesProvider>
                </ErrorBoundary>
            );
            break;
        }

        case TAB_TYPES.values: {
            content = (
                <ValuesProvider addNotification={addNotification} tenantId={tenantId}>
                    <Values />
                </ValuesProvider>
            );
            break;
        }

        case TAB_TYPES.identityProviders: {
            content = (
                <ErrorBoundary>
                    <IdentityProviders addNotification={addNotification} tenantId={tenantId} />
                </ErrorBoundary>
            );
            break;
        }

        case TAB_TYPES.environments: {
            content = (
                <ErrorBoundary>
                    <EnvironmentsProvider addNotification={addNotification} tenantId={tenantId}>
                        <Environments />
                    </EnvironmentsProvider>
                </ErrorBoundary>
            );
            break;
        }

        case TAB_TYPES.environment: {
            if (environmentsIsOn) {
                content = (
                    <ErrorBoundary>
                        <EnvironmentsProvider addNotification={addNotification} tenantId={tenantId}>
                            <Environment />
                        </EnvironmentsProvider>
                    </ErrorBoundary>
                );
            } else {
                content = <span>{translations.ENVIRONMENT_not_on}</span>;
            }
            break;
        }

        case TAB_TYPES.pages: {
            content = (
                <ErrorBoundary>
                    <PageList tenantId={tenantId} />
                </ErrorBoundary>
            );
            break;
        }

        case TAB_TYPES.assets: {
            content = (
                <ErrorBoundary>
                    <AssetTab addNotification={addNotification} />
                </ErrorBoundary>
            );
            break;
        }
        case TAB_TYPES.themes: {
            content = (
                <ErrorBoundary>
                    <Themes key="themes" isActive={isActive} addNotification={addNotification} />
                </ErrorBoundary>
            );
            break;
        }
        case TAB_TYPES.players: {
            content = (
                <ErrorBoundary>
                    <PlayerEditor key="players" tenantId={tenantId} isActive={isActive} />
                </ErrorBoundary>
            );
            break;
        }
        case TAB_TYPES.api: {
            content = (
                <ErrorBoundary>
                    <Build key="build" />
                </ErrorBoundary>
            );
            break;
        }
        case TAB_TYPES.macros: {
            content = (
                <ErrorBoundary>
                    <MacroEditor key="macro-editor" />
                </ErrorBoundary>
            );
            break;
        }
        case TAB_TYPES.importExport: {
            content = (
                <ErrorBoundary>
                    <Package
                        key="import-export"
                        tenantName={tenantName}
                        tenantId={tenantId}
                        tenant={tenant}
                    />
                </ErrorBoundary>
            );
            break;
        }
        case TAB_TYPES.dashboard: {
            content = (
                <ErrorBoundary>
                    {getFlag('UNIDSH') ? (
                        <DeveloperDashboard notifyError={notifyError} />
                    ) : (
                        <Dashboard tenantId={tenantId} />
                    )}
                </ErrorBoundary>
            );
            break;
        }
        case TAB_TYPES.organization: {
            content = (
                <ErrorBoundary>
                    <InitialOrgPage />
                </ErrorBoundary>
            );
            break;
        }
        case TAB_TYPES.userSettings: {
            content = <UserSettings />;
            break;
        }
        case TAB_TYPES.features: {
            content = <Features />;
            break;
        }

        case TAB_TYPES.components: {
            content = (
                <ErrorBoundary>
                    <CustomPageComponentsProvider
                        notifyError={notifyError}
                        notifySuccess={notifySuccess}
                        tenantId={tenantId}
                    >
                        <CustomPageComponents />
                    </CustomPageComponentsProvider>
                </ErrorBoundary>
            );
            break;
        }

        case TAB_TYPES.anomalyDetection: {
            content = (
                <ErrorBoundary>
                    <AnomalyDashboardProvider notifyError={notifyError}>
                        {/* I've moved this admin-page class up to here for now until the unified dashboard is finished. 
                        It was getting two of these and causing double padding */}
                        <div className="admin-page">
                            <AnomalyDashboard />
                        </div>
                    </AnomalyDashboardProvider>
                </ErrorBoundary>
            );
            break;
        }

        default: {
            content = null;
            break;
        }
    }

    const classes = classnames({
        admin: true,
        active: isActive,
    });
    return (
        <div className={classes}>
            <span className="admin-transition">{content}</span>
        </div>
    );
};

export default connect(null, { addNotification, notifySuccess, notifyError })(Admin);
