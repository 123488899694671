import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import '../../../../css/flow/shared-elements.less';
import { addNotification as addNotificationAction } from '../../actions/reduxActions/notification';
import {
    SYSTEM_TYPE_IDS,
    SYSTEM_VALUE_IDS,
    FRIENDLY_DEPENDENCY_NAMES,
} from '../../../ts/constants';
import { getFlowSharedElements, addElement, removeElement } from '../../../ts/sources/flow';
import { isNullOrEmpty } from '../../../ts/utils/guard';
import { mapFriendlyNameToDisplay } from '../../utils/DisplayValueMappings';
import ButtonDefault from '../../../ts/components/buttons/ButtonDefault';
import Glyphicon from '../../../ts/components/generic/Glyphicon';
import Modal from '../../../ts/components/generic/modal/GenericModal';
import Loader from '../../../ts/components/loader/Loader';
import SearchInput from '../SearchInput';
import SharedElementsTable from './SharedElementsTable';
import { getAllValues } from '../../../ts/sources/value';
import { getAllTypes } from '../../../ts/sources/type';
import { getAllServices } from '../../../ts/sources/service';
import { macroSource } from '../../../ts/sources/macro';
import { getAllPages } from '../../../ts/sources/page';

const SharedElementsModal = ({ tenantId, flowId, addNotification, close, container }) => {
    const [activeTab, setActiveTab] = useState(FRIENDLY_DEPENDENCY_NAMES.VALUE_ELEMENT);
    const [showingImportPage, setShowingImportPage] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [filteredTableData, setFilteredTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const SYSTEM_VALUE_AND_TYPE_IDS = SYSTEM_VALUE_IDS.concat(SYSTEM_TYPE_IDS);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        loadMainPage();
    }, [activeTab]);

    const getElementsInFlow = async () => {
        try {
            let response = await getFlowSharedElements(flowId, activeTab, tenantId);

            response = filterOutSystemElements(response);
            setTableData(response);
            setFilteredTableData(response);
            setIsLoading(false);
        } catch ({ message }) {
            addNotification({
                type: 'error',
                message,
                isPersistent: true,
            });
        }
    };

    const getSharedElementsInTenant = async () => {
        try {
            let response = null;

            const lowerActiveTab = activeTab.toLowerCase();

            switch (lowerActiveTab) {
                case 'value': {
                    response = await getAllValues();
                    break;
                }
                case 'page': {
                    response = await getAllPages();
                    break;
                }
                case 'type': {
                    response = await getAllTypes();
                    break;
                }
                case 'service': {
                    response = await getAllServices();
                    break;
                }
                case 'macro': {
                    response = await macroSource.getAll(tenantId);
                    break;
                }
            }

            response = filterOutSystemElements(response);
            response = filterOutImportedElements(response);
            setTableData(response);
            setFilteredTableData(response);
            setIsLoading(false);
        } catch ({ message }) {
            addNotification({
                type: 'error',
                message,
                isPersistent: true,
            });
        }
    };

    const importElementIntoFlow = (element) => {
        addElement(element.id, flowId, activeTab, tenantId)
            .then(loadMainPage)
            .catch(({ message }) =>
                addNotification({
                    type: 'error',
                    message,
                    isPersistent: true,
                }),
            );
    };

    const filterOutSystemElements = (newData) => {
        return newData.filter((item) => !SYSTEM_VALUE_AND_TYPE_IDS.includes(item.id));
    };

    const filterOutImportedElements = (newData) => {
        const filteredArray = newData.filter((item) => {
            return tableData.every((filter) => {
                return filter.id !== item.id;
            });
        });

        return filteredArray;
    };

    const removeElementFromFlow = (element) => {
        removeElement(element.id, flowId, activeTab, tenantId)
            .then(loadMainPage)
            .catch(({ message }) =>
                addNotification({
                    type: 'error',
                    message,
                    isPersistent: true,
                }),
            );
    };

    const loadImportPage = () => {
        setIsLoading(true);
        setShowingImportPage(true);
        getSharedElementsInTenant();
    };

    const loadMainPage = () => {
        setIsLoading(true);
        setShowingImportPage(false);
        getElementsInFlow();
    };

    const changeActiveTab = (e, tabValue) => {
        if (tabValue !== activeTab) {
            e.preventDefault();
            e.stopPropagation();

            setActiveTab(tabValue);
        }
    };

    const filterBySearchTerms = (searchInput) => {
        if (isNullOrEmpty(searchInput) && tableData.length !== filteredTableData.length) {
            setFilteredTableData(tableData);
        } else {
            const filteredTableData = tableData.filter((row) => {
                const name = row.developerName.toLowerCase();
                return name.includes(searchInput.toLowerCase());
            });

            setFilteredTableData(filteredTableData);
        }
    };

    const renderLoadingSection = <Loader message="Loading data..." />;

    const tableContent = (
        <SharedElementsTable
            tableData={filteredTableData}
            elementType={activeTab}
            isImportPage={showingImportPage}
            importElement={importElementIntoFlow}
            removeElement={removeElementFromFlow}
        />
    );

    const importPageControls = (
        <div className="import-content-header">
            <SearchInput
                className="tab-header-search"
                onSearch={(searchText) => {
                    filterBySearchTerms(searchText);
                }}
                testId="search-input"
            />
            <ButtonDefault className="btn-sm" title="Refresh" onClick={loadImportPage}>
                <Glyphicon glyph="refresh" />
            </ButtonDefault>
        </div>
    );

    const importPageHeader = () => {
        return (
            <h4 className="modal-title" data-testid="import-header">
                Add {mapFriendlyNameToDisplay(activeTab)} To Flow
            </h4>
        );
    };

    const importPageBody = () => {
        return isLoading ? (
            renderLoadingSection
        ) : (
            <>
                {importPageControls}
                {tableContent}
            </>
        );
    };

    const importPageFooter = () => {
        return (
            <ButtonDefault title="Close Modal" onClick={loadMainPage}>
                Back
            </ButtonDefault>
        );
    };

    const importPageContent = (
        <Modal
            renderHeader={importPageHeader}
            renderBody={importPageBody}
            renderFooter={importPageFooter}
            container={container}
            onHide={close}
        />
    );

    const tabContentControls = (
        <div className="tab-content-header">
            <SearchInput
                className="tab-header-search"
                onSearch={(searchText) => {
                    filterBySearchTerms(searchText);
                }}
                testId="search-input"
            />
            <div className="tab-header-controls">
                <ButtonDefault
                    className="btn-sm btn-success"
                    title="Import Existing"
                    onClick={loadImportPage}
                >
                    Import Existing
                </ButtonDefault>
                <ButtonDefault className="btn-sm" title="Refresh" onClick={loadMainPage}>
                    <Glyphicon glyph="refresh" />
                </ButtonDefault>
            </div>
        </div>
    );

    const tabContent = () => {
        return isLoading ? (
            renderLoadingSection
        ) : (
            <>
                {tabContentControls}
                {tableContent}
            </>
        );
    };

    const header = () => {
        return <h4 className="modal-title">Shared Elements</h4>;
    };

    const body = () => {
        return (
            <>
                <ul className="nav nav-tabs">
                    <li
                        className={
                            activeTab === FRIENDLY_DEPENDENCY_NAMES.VALUE_ELEMENT ? 'active' : ''
                        }
                    >
                        <button
                            className="nav-tab"
                            onClick={(e) =>
                                changeActiveTab(e, FRIENDLY_DEPENDENCY_NAMES.VALUE_ELEMENT)
                            }
                            title={`${mapFriendlyNameToDisplay(
                                FRIENDLY_DEPENDENCY_NAMES.VALUE_ELEMENT,
                            )}s`}
                            type="button"
                        >
                            {`${mapFriendlyNameToDisplay(
                                FRIENDLY_DEPENDENCY_NAMES.VALUE_ELEMENT,
                            )}s`}
                        </button>
                    </li>
                    <li className={activeTab === FRIENDLY_DEPENDENCY_NAMES.PAGE ? 'active' : ''}>
                        <button
                            className="nav-tab"
                            onClick={(e) => changeActiveTab(e, FRIENDLY_DEPENDENCY_NAMES.PAGE)}
                            title={`${mapFriendlyNameToDisplay(FRIENDLY_DEPENDENCY_NAMES.PAGE)}s`}
                            type="button"
                        >
                            {`${mapFriendlyNameToDisplay(FRIENDLY_DEPENDENCY_NAMES.PAGE)}s`}
                        </button>
                    </li>
                    <li
                        className={
                            activeTab === FRIENDLY_DEPENDENCY_NAMES.TYPE_ELEMENT ? 'active' : ''
                        }
                    >
                        <button
                            className="nav-tab"
                            onClick={(e) =>
                                changeActiveTab(e, FRIENDLY_DEPENDENCY_NAMES.TYPE_ELEMENT)
                            }
                            title={`${mapFriendlyNameToDisplay(
                                FRIENDLY_DEPENDENCY_NAMES.TYPE_ELEMENT,
                            )}s`}
                            type="button"
                        >
                            {`${mapFriendlyNameToDisplay(FRIENDLY_DEPENDENCY_NAMES.TYPE_ELEMENT)}s`}
                        </button>
                    </li>
                    <li
                        className={
                            activeTab === FRIENDLY_DEPENDENCY_NAMES.SERVICE_ELEMENT ? 'active' : ''
                        }
                    >
                        <button
                            className="nav-tab"
                            onClick={(e) =>
                                changeActiveTab(e, FRIENDLY_DEPENDENCY_NAMES.SERVICE_ELEMENT)
                            }
                            title={`${mapFriendlyNameToDisplay(
                                FRIENDLY_DEPENDENCY_NAMES.SERVICE_ELEMENT,
                            )}s`}
                            type="button"
                        >
                            {`${mapFriendlyNameToDisplay(
                                FRIENDLY_DEPENDENCY_NAMES.SERVICE_ELEMENT,
                            )}s`}
                        </button>
                    </li>
                    <li
                        className={
                            activeTab === FRIENDLY_DEPENDENCY_NAMES.MACRO_ELEMENT ? 'active' : ''
                        }
                    >
                        <button
                            className="nav-tab"
                            onClick={(e) =>
                                changeActiveTab(e, FRIENDLY_DEPENDENCY_NAMES.MACRO_ELEMENT)
                            }
                            title={`${mapFriendlyNameToDisplay(
                                FRIENDLY_DEPENDENCY_NAMES.MACRO_ELEMENT,
                            )}s`}
                            type="button"
                        >
                            {`${mapFriendlyNameToDisplay(
                                FRIENDLY_DEPENDENCY_NAMES.MACRO_ELEMENT,
                            )}s`}
                        </button>
                    </li>
                </ul>
                {tabContent()}
            </>
        );
    };

    const footer = () => {
        return (
            <ButtonDefault title="Close Modal" onClick={close}>
                Close
            </ButtonDefault>
        );
    };

    const mainContent = (
        <Modal
            className="config-modal"
            renderHeader={header}
            renderBody={body}
            renderFooter={footer}
            container={container}
            onHide={close}
        />
    );

    return showingImportPage ? importPageContent : mainContent;
};

const mapStateToProps = (_state, ownProps) => ({
    flowId: ownProps.flowId,
});

const mapDispatchToProps = {
    addNotification: addNotificationAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SharedElementsModal);
