import { useEffect, useState, useRef } from 'react';
import ConfirmModal from '../../generic/modal/ConfirmModal';
import Sortable from '../../generic/Sortable';
import { TAB_TYPES, NOTIFICATION_TYPES } from '../../../constants';

import { useNavigate } from 'react-router-dom';
import translations from '../../../translations';
import { connect } from 'react-redux';
import { addNotification as addNotificationAction } from '../../../../js/actions/reduxActions/notification';
import Table from '../../generic/Table';
import { Trash } from '@phosphor-icons/react';
import PageHeader from '../../generic/PageHeader';
import { getPageList, deletePage } from '../../../sources/page';
import { generateRouteUrl, generateTabKey } from '../../../utils/routing';
import { stringReplace } from '../../../utils/string';
import { isNullOrEmpty } from '../../../utils/guard';
import type { AddNotification, OrderDirection, PageElementResponseAPI, Tab } from '../../../types';

interface Props {
    tenantId: string;
    addNotification: AddNotification;
    tabs: Tab[];
}

interface CellProps {
    item: PageElementResponseAPI;
}

const pageSize = 20;

const PageList = ({ tenantId, addNotification, tabs }: Props) => {
    const modalContainerRef = useRef(null);

    const navigate = useNavigate();

    const onNewPageClick = () => {
        const route = generateRouteUrl({
            tabType: TAB_TYPES.newPage,
            tenantId,
            options: {
                tabKey: generateTabKey(),
            },
        });

        navigate(route);
    };

    const onRefreshClick = () => {
        fetchPages();
    };

    const onEditClick = (pageId: string) => {
        const route = generateRouteUrl({
            tabType: TAB_TYPES.page,
            tenantId,
            options: {
                elementId: pageId,
            },
        });

        navigate(route);
    };

    const onDeleteClick = (page: PageElementResponseAPI) => {
        setSelectedPageElement(page);
        setDeleteModalIsVisible(true);
    };

    const onError = (message: string) => {
        addNotification({
            type: NOTIFICATION_TYPES.error,
            isPersistent: true,
            message,
        });
    };

    const onDeleteConfirm = async () => {
        if (selectedPageElement) {
            setIsDeleting(true);

            try {
                await deletePage(selectedPageElement.id);
                fetchPages();
            } catch (_error) {
                onError(
                    stringReplace(translations.PAGE_unable_to_delete, {
                        pageName: selectedPageElement?.developerName ?? '',
                    }),
                );
            } finally {
                setIsDeleting(false);
                setDeleteModalIsVisible(false);
                setSelectedPageElement(null);
            }
        }
    };

    const onDeleteCancelClick = () => {
        setDeleteModalIsVisible(false);
        setSelectedPageElement(null);
    };

    const onSortClick = ({
        orderBy,
        direction,
    }: {
        orderBy: string;
        direction: OrderDirection;
    }) => {
        setCurrentPage(1);
        setSortProperty(orderBy);
        setSortDirection(direction);
    };

    const onSearch = (term: string) => {
        setCurrentPage(1);
        setSearchQuery(term);
    };

    const onPageClick = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const fetchPages = async () => {
        setIsLoading(true);

        try {
            const filter = {
                search: searchQuery,
                page: currentPage,
                orderBy: sortProperty,
                orderDirection: sortDirection,
            };

            const response = await getPageList(filter);

            setPageElements(response.items);
            setPageElementCount(response._meta.total);
            setCurrentPage(response._meta.page);
        } catch (_error) {
            onError(translations.PAGE_unable_to_load_list);
        } finally {
            setIsLoading(false);
        }
    };

    const [pageElements, setPageElements] = useState<PageElementResponseAPI[]>([]);
    const [selectedPageElement, setSelectedPageElement] = useState<PageElementResponseAPI | null>(
        null,
    );

    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [deleteModalIsVisible, setDeleteModalIsVisible] = useState(false);

    const [searchQuery, setSearchQuery] = useState('');
    const [sortProperty, setSortProperty] = useState('dateModified');
    const [sortDirection, setSortDirection] = useState<OrderDirection>('DESC');

    const [pageElementCount, setPageElementCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Requires dedicated refactor
    useEffect(() => {
        fetchPages();
    }, [tenantId, searchQuery, currentPage, sortProperty, sortDirection]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Requires dedicated refactor
    useEffect(() => {
        if (!isNullOrEmpty(tabs)) {
            const thisTab = tabs.find((t) => t.type === TAB_TYPES.pages);
            if (!isNullOrEmpty(thisTab) && thisTab.isActive) {
                fetchPages();
            }
        }
    }, [tabs]);

    const columns = [
        {
            renderHeader: () => (
                <Sortable
                    defaultDirection={'ASC'}
                    direction={sortProperty === 'developerName' ? sortDirection : null}
                    onSort={(direction) => onSortClick({ orderBy: 'developerName', direction })}
                >
                    {translations.COMMON_TABLE_name}
                </Sortable>
            ),
            renderCell: ({ item }: CellProps) => (
                <button
                    className="link-emulate"
                    onClick={() => onEditClick(item.id)}
                    aria-label={`Edit ${item.developerName}`}
                    type="button"
                >
                    {item.developerName}
                </button>
            ),
        },
        {
            renderHeader: () => (
                <Sortable
                    defaultDirection={'ASC'}
                    direction={sortProperty === 'dateModified' ? sortDirection : null}
                    onSort={(direction) => onSortClick({ orderBy: 'dateModified', direction })}
                >
                    {translations.COMMON_TABLE_last_modified}
                </Sortable>
            ),
            renderCell: ({ item }: CellProps) =>
                new Date(item.dateModified).toLocaleString(undefined, {
                    dateStyle: 'medium',
                    timeStyle: 'short',
                }),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_summary,
            renderCell: ({ item }: CellProps) => item.developerSummary,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item }: CellProps) => (
                <div className="action-btn-wrapper">
                    <button
                        title={`Delete ${item.developerName}`}
                        className="table-icon table-icon-delete"
                        aria-label={`Delete ${item.developerName}`}
                        onClick={() => onDeleteClick(item)}
                        type="button"
                    >
                        <Trash />
                    </button>
                </div>
            ),
            size: '5rem',
        },
    ];

    return (
        <div className="admin-page list-pages flow-wrapper" ref={modalContainerRef}>
            <ConfirmModal
                show={deleteModalIsVisible}
                title={translations.PAGE_delete_confirmation_title}
                messages={[
                    stringReplace(translations.PAGE_delete_confirmation_message, {
                        pageName: selectedPageElement?.developerName ?? '',
                    }),
                    translations.GENERAL_cannot_be_undone,
                ]}
                buttonStyle="danger"
                buttonCaption="Delete"
                onCancel={onDeleteCancelClick}
                onConfirm={onDeleteConfirm}
                isInProgress={isDeleting}
                container={modalContainerRef.current}
            />
            <PageHeader
                title="Pages"
                onAdd={onNewPageClick}
                onRefresh={onRefreshClick}
                onSearch={onSearch}
                searchQuery={searchQuery}
                searchPlaceholderText="Search"
                addText="New Page"
                refreshTitle="Refresh Results"
            />
            <Table
                wrapperClassName="margin-top"
                items={pageElements}
                columns={columns}
                isLoading={isLoading}
                pagination={{
                    page: currentPage,
                    total: pageElementCount,
                    pageSize,
                    changePage: onPageClick,
                }}
            />
        </div>
    );
};

const mapStateToProps = ({ tabs }: { tabs: Tab[] }) => ({
    tabs,
});

const mapDispatchToProps = {
    addNotification: addNotificationAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageList);
