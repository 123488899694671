export const AUDIT_TIME_PERIODS = {
    day: {
        label: 'Day',
        value: 'day',
    },
    month: {
        label: 'Last Month',
        value: 'month',
    },
    threeMonths: {
        label: 'Last 3 Months',
        value: '3months',
    },
    sixMonths: {
        label: 'Last 6 Months',
        value: '6months',
    },
    twelveMonths: {
        label: 'Last 12 Months',
        value: '12months',
    },
};

export const DATE_FNS_FORMAT_NO_ZONE = "yyyy-MM-dd'T'HH:mm:ss.SSS";
