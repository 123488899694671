import { useState } from 'react';
import '../../../css/text-editor.less';

const TextEditor = ({
    onChange,
    text = '',
    isMutiline = false,
    isEditing: isEditingInitial = false,
}) => {
    const [isEditing, setIsEditing] = useState(isEditingInitial);

    return (
        <div
            className="text-editor flex"
            onKeyDown={() => setIsEditing(true)}
            onFocus={() => setIsEditing(true)}
            onClick={() => setIsEditing(true)}
            role="button"
            tabIndex={0}
            data-testid="text-editor"
        >
            {isEditing ? (
                isMutiline ? (
                    <textarea
                        className="flex-grow text-padding text-editor-input"
                        autoFocus={true}
                        value={text}
                        onChange={onChange}
                        onBlur={() => setIsEditing(false)}
                        data-testid="text-editor-textarea"
                    />
                ) : (
                    <input
                        className="flex-grow text-padding text-editor-input"
                        autoFocus={true}
                        value={text}
                        onChange={onChange}
                        onBlur={() => setIsEditing(false)}
                        data-testid="text-editor-input"
                    />
                )
            ) : (
                <span className="flex-grow text-padding text-editor-display">{text}</span>
            )}
            {isEditing ? null : (
                <button
                    className="btn-icon flex-child-right"
                    data-testid="text-editor-button"
                    onClick={() => setIsEditing(true)}
                    type="button"
                >
                    <span className="glyphicon glyphicon-pencil" />
                </button>
            )}
        </div>
    );
};

export default TextEditor;
