import { ExChart } from '@boomi/exosphere';
import translations from '../../translations';
import { useAnomalyDashboard } from './AnomalyProvider';
import { timeIcon, usageIcon, errorIcon } from './AnomalyIcons';

const AnomalyBarChart = () => {
    const { barConfig } = useAnomalyDashboard();

    return (
        <span className="bar-chart-section">
            <span className="legend">
                <h2>{translations.ANOMALY_bar_chart_title}</h2>
                <span className="type-icon">
                    {timeIcon}
                    {usageIcon}
                    {errorIcon}
                </span>
            </span>
            <ExChart
                key={JSON.stringify(barConfig)}
                data-testid="anomalyBarChart"
                options={barConfig}
            />
        </span>
    );
};

export default AnomalyBarChart;
