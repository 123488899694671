import { fetchAndParse } from '../../ts/utils/ajax';
import type { FeatureFlagAPI } from '../types';
import { getTenantId } from '../utils/tenant';

export const getFeatureFlags = () =>
    fetchAndParse<FeatureFlagAPI[]>({
        url: '/api/features/1/tenant',
        method: 'GET',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const enableFeatureFlag = ({
    featureFlagId,
    enabled,
}: {
    tenantId: string;
    featureFlagId: string;
    enabled: boolean;
}) =>
    fetchAndParse<void>({
        url: '/api/features/1/tenant',
        method: 'PUT',
        headers: {
            ManyWhoTenant: getTenantId(),
            'Content-Type': 'application/json',
        },
        body: {
            featureFlagId,
            enabled,
        },
    });
