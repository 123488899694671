import { ExIcon, ExLoader, IconVariant } from '@boomi/exosphere';
import type { AnomalyResponse } from '../../sources/anomalyEvents';
import translations from '../../translations';
import AnomalyBarChart from '../anomalyDetection/AnomalyBarChart';
import Table, { type TableColumnList } from '../generic/Table';
import { useAnomalyDashboard } from '../anomalyDetection/AnomalyProvider';
import { getAnomalyDetails } from '../anomalyDetection/AnomalyOverview';

const AnomalySummary = ({ onViewAll }: { onViewAll: () => void }) => {
    const { paginatedData, isLoadingData } = useAnomalyDashboard();

    const columns: TableColumnList<AnomalyResponse> = [
        {
            renderHeader: () => translations.COMMON_TABLE_flow_name,
            renderCell: ({ item }) => item.flowName,
        },
        {
            renderHeader: () => translations.ANOMALY_table_header_type,
            renderCell: ({ item }) => item.anomalyType,
        },
        {
            renderHeader: () => translations.ANOMALY_table_header_details,
            renderCell: ({ item }) => {
                const {
                    anomalyDeviationText,
                    anomalyDetails,
                    deviationAbsolute,
                    deviationPositive,
                } = getAnomalyDetails(item);

                return (
                    <span className="details-section">
                        <span className="details-summary">
                            <span className="details-deviation" title={anomalyDeviationText}>
                                {deviationPositive ? '+' : '-'}
                                {deviationAbsolute}%
                                <ExIcon
                                    className="margin-left-sml"
                                    label={anomalyDeviationText}
                                    icon={deviationPositive ? 'Up caret' : 'Down caret'}
                                    variant={
                                        deviationPositive
                                            ? IconVariant.SECONDARY
                                            : IconVariant.DANGER
                                    }
                                />
                            </span>
                            <span className="details-text quiet" title={anomalyDetails}>
                                {anomalyDetails}
                            </span>
                        </span>
                    </span>
                );
            },
        },
        {
            renderHeader: () => '',
            renderCell: () => (
                <button className="link-emulate" type="button">
                    {translations.DASHBOARD_inpect}
                </button>
            ),
            size: '5rem',
        },
    ];

    if (isLoadingData) {
        return <ExLoader />;
    }

    return (
        <div className="developer-dash-wrapper anomaly-detection">
            <div className="developer-dash-chart-wrapper">
                <AnomalyBarChart />
            </div>
            <div className="developer-dash-table-wrapper">
                <h2 className="developer-dash-table-header">
                    {translations.DASHBOARD_recent_anomalies}
                </h2>
                {paginatedData && paginatedData.length > 0 ? (
                    <Table
                        columns={columns}
                        items={paginatedData}
                        rowClassName={() => 'generic-row generic-row-tall'}
                        testId="anomaly-summary-table"
                    />
                ) : (
                    <button onClick={onViewAll} className="link-emulate" type="button">
                        {translations.DASHBOARD_no_anomalies}
                    </button>
                )}
                {paginatedData && paginatedData.length > 0 ? (
                    <button
                        onClick={onViewAll}
                        className="developer-dash-view-all link-emulate"
                        type="button"
                    >
                        {translations.DASHBOARD_view_all}
                    </button>
                ) : null}
            </div>
        </div>
    );
};

export default AnomalySummary;
