import { useEffect, useState } from 'react';
import dependentSource from '../../../ts/sources/dependents';
import { FRIENDLY_DEPENDENCY_NAMES } from '../../../ts/constants';
import CopyableText from '../../../ts/components/generic/CopyableText';
import Table from '../../../ts/components/generic/Table';
import translations from '../../../ts/translations';

const PAGE_SIZE = 10;

const Dependents = ({ id, tenantId }) => {
    const [loader, setLoader] = useState(true);
    const [dependents, setDependents] = useState([]);
    const [pager, updatePager] = useState({
        total: 0,
        pageSize: PAGE_SIZE,
        page: 1,
    });

    const fetchDependents = async (page) => {
        setLoader(true);

        const filters = {
            pageSize: pager.pageSize,
            page,
        };

        try {
            const results = await dependentSource.getAll({
                tenantId,
                id,
                filters,
            });

            setDependents(
                results.items.map((dependent) => ({
                    ...dependent,
                    type: FRIENDLY_DEPENDENCY_NAMES[dependent.type],
                })),
            );
            updatePager(results._meta);
        } catch ({ message }) {
            console.error(message);
        } finally {
            setLoader(false);
        }
    };

    const onChangePage = (page) => {
        updatePager({ ...pager, page });
        fetchDependents(page);
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        fetchDependents(1);
    }, []);

    const dependantColumns = [
        {
            renderHeader: () => translations.COMMON_TABLE_name,
            renderCell: ({ item }) => item.developerName,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_type,
            renderCell: ({ item }) => item.type,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_id,
            renderCell: ({ item }) => <CopyableText copyableText={item.id} hasCopyButton={true} />,
        },
    ];

    const { total, pageSize, page } = pager;

    return (
        <>
            <h2>Dependents</h2>
            <Table
                wrapperClassName="margin-top"
                columns={dependantColumns}
                items={dependents}
                pagination={{
                    page,
                    total,
                    pageSize,
                    changePage: onChangePage,
                }}
                isLoading={loader}
            />
        </>
    );
};

export default Dependents;
