import { ExChart } from '@boomi/exosphere';
import translations from '../../translations';
import { useAnomalyDashboard } from './AnomalyProvider';

const AnomalyDonutChart = () => {
    const { donutConfig, timeData, usageData, errorData } = useAnomalyDashboard();

    return (
        <span>
            <h3>{translations.ANOMALY_donut_chart_title}</h3>
            <span className="pie-chart-section">
                <ExChart
                    key={JSON.stringify(donutConfig)}
                    data-testid="anomalyPieChart"
                    options={donutConfig}
                />
                <span className="legend">
                    {translations.ANOMALY_donut_chart_legend}
                    <span>
                        <span className="time-legend time-colour" />
                        <span className="legend-text">
                            {translations.ANOMALY_type_time}
                            <span className="legend-value">{timeData.length}</span>
                        </span>
                    </span>
                    <span>
                        <span className="usage-legend usage-colour" />
                        <span className="legend-text">
                            {translations.ANOMALY_type_usage}
                            <span className="legend-value">{usageData.length}</span>
                        </span>
                    </span>
                    <span>
                        <span className="error-legend error-colour" />
                        <span className="legend-text">
                            {translations.ANOMALY_type_error}
                            <span className="legend-value">{errorData.length}</span>
                        </span>
                    </span>
                </span>
            </span>
        </span>
    );
};

export default AnomalyDonutChart;
