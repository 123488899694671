import type { FormEvent } from 'react';
import type { TenantRuntime } from '../../types/Tenant';
import { formatTenantName } from '../../utils/tenant';

interface Props {
    tenantId: string;
    tenantDeveloperName: string;
    runtimes: TenantRuntime[];
    onChange: (e: FormEvent<HTMLSelectElement>) => void;
    comment: string | null;
    onCommentChanged: (e: FormEvent<HTMLTextAreaElement>) => void;
}

const ActivateRuntime = ({
    tenantId,
    tenantDeveloperName,
    runtimes,
    onChange,
    comment,
    onCommentChanged,
}: Props) => (
    <>
        <p className="activate-runtime-header">
            {'Please select the runtime environment you want to publish to.'}
        </p>
        <div className={'form-group'}>
            <select
                defaultValue={tenantId}
                onChange={onChange}
                className="form-control form-select"
                data-testid="select-runtime"
            >
                <option key={tenantId} value={tenantId}>
                    {formatTenantName(tenantDeveloperName)}
                </option>
                {runtimes.map((runtime) => (
                    <option key={runtime.id} value={runtime.id}>
                        {runtime.developerName}
                    </option>
                ))}
            </select>
            <textarea
                className="form-control margin-top"
                placeholder="Enter a comment for this version"
                rows={3}
                value={comment ?? ''}
                onChange={onCommentChanged}
            />
        </div>
    </>
);

export default ActivateRuntime;
