import ConfirmModal from '../../../../generic/modal/ConfirmModal';
import ComposerPageElement from './page-elements/PageElement';
import SideBar from './left-sidebar/Sidebar';
import PageElementMenu from './context-menu/PageElementMenu';
import classNames from 'classnames';

import { useCallback, useEffect, useRef, useState } from 'react';
import { PAGE_ELEMENT_TYPES, PREVIEW_MODE } from '../../../constants';
import { useComposer } from './ComposerProvider';
import { usePageEditor } from '../../PageEditorProvider';

import '../../../../../../../css/builder/page-editor-v2.less';
import type { ComposerElement, ContextSelection } from '../../../../../types';
import type { ContainerType } from '../../dnd-utils';
import { useDocumentKeyDown } from '../../../../../hooks';

const Composer = () => {
    const {
        dragDropElements,
        generateDragDropElements,
        onPageElementDrop,
        onSetPageElementActive,
        onPageElementRemove,
        elementToDelete,
        setElementToDelete,
        elementToEdit,
        onPageElementCopy,
        onPageElementPaste,
        elementSelected,
        toggleSelectedElement,
    } = useComposer();

    const { state, container } = usePageEditor();
    const { preview, tab, page } = state;
    const { elementId, isActive } = tab || {};

    const composerRef = useRef(null);

    const defaultContextMenu = {
        isOpen: false,
        top: 0,
        left: 0,
        originGuid: null,
    };

    const [contextMenu, setContextMenu] = useState<ContextSelection>(defaultContextMenu);

    const canExecuteKeyboardShortcuts = page.id === elementId && isActive;

    const copyOnKeyDown = () => {
        canExecuteKeyboardShortcuts &&
            elementToEdit &&
            onPageElementCopy({ targetId: elementToEdit });
    };

    const pasteOnKeyDown = () => {
        if (canExecuteKeyboardShortcuts) {
            const elementToPasteInto = dragDropElements.find((dde) => dde.id === elementToEdit);
            const isContainer = elementToPasteInto
                ? Object.prototype.hasOwnProperty.call(elementToPasteInto, 'containerType')
                : false;

            if (isContainer && elementToEdit) {
                onPageElementPaste({ targetId: elementToEdit });
            } else {
                const elementToPasteInto = dragDropElements.find((dde) => dde.parentId === null);
                if (elementToPasteInto) {
                    onPageElementPaste({ targetId: elementToPasteInto.id || '' });
                }
            }
        }
    };

    const deleteOnKeyDown = () => {
        canExecuteKeyboardShortcuts &&
            elementToEdit &&
            setElementToDelete({ targetId: elementToEdit });
    };

    useDocumentKeyDown('CTRL+C', composerRef.current, copyOnKeyDown);
    useDocumentKeyDown('CTRL+V', composerRef.current, pasteOnKeyDown);
    useDocumentKeyDown('DELETE', composerRef.current, deleteOnKeyDown);

    const closeContextMenu = () => {
        setContextMenu(defaultContextMenu);
    };

    const {
        isOpen: contextMenuIsOpen,
        left: contextMenuLeft,
        top: contentMenuTop,
        originGuid: contextMenuId,
    } = contextMenu;

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        // The page elements must be re-generated whenever
        // the page is saved, since there may have been changes made
        // using the metadata editor.
        generateDragDropElements();
    }, [state.page.dateModified]);

    const onElementDelete = () => {
        onPageElementRemove(elementToDelete);
        setElementToDelete(null);
    };

    const nestPageElements = (container: ComposerElement) => {
        const children = dragDropElements
            .filter((childElement) => childElement.parentId === container.id)
            .sort((a, b) => a.order - b.order);

        const pageElementType = Object.prototype.hasOwnProperty.call(container, 'containerType')
            ? PAGE_ELEMENT_TYPES['container']
            : PAGE_ELEMENT_TYPES['component'];

        return (
            <ComposerPageElement
                key={container.id}
                id={container.id as string}
                containerType={container.containerType as ContainerType}
                parentId={container.parentId}
                order={container.order}
                preview={preview}
                onPageElementDrop={onPageElementDrop}
                setActivePageContainer={onSetPageElementActive}
                pageElements={dragDropElements}
                pageElementType={pageElementType}
                elementSelected={elementSelected}
                setElementSelected={toggleSelectedElement}
                openContextMenu={setContextMenu}
                isContextMenuOpen={contextMenuIsOpen}
                elementToEdit={elementToEdit}
                config={container}
            >
                {children.map(nestPageElements)}
            </ComposerPageElement>
        );
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps -- Treat warnings as errors, fix later
    const contextMenuClose = useCallback(closeContextMenu, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Treat warnings as errors, fix later
    const deleteFromContextMenu = useCallback(setElementToDelete, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Treat warnings as errors, fix later
    const addFromContextMenu = useCallback(onPageElementDrop, []);

    if (dragDropElements.length > 0) {
        const elementTargetedByContextMenu = dragDropElements.find(
            (dde) => dde.id === contextMenuId,
        );

        const rootContainer = dragDropElements.find((element) => element.parentId === null);
        const classes = classNames({
            'page-edit': true,
            'classic-composer': true,
            'is-mobile-preview': preview === PREVIEW_MODE['MOBILE'],
            composer: true, // Need this class for the context menu to work
        });

        return (
            <div ref={composerRef} data-testid="classic-composer" className={classes}>
                <div className="page-edit-canvas-wrapper">
                    <SideBar />
                    {dragDropElements && rootContainer ? nestPageElements(rootContainer) : null}
                </div>

                <PageElementMenu
                    contextMenuId={contextMenuId}
                    isOpen={contextMenuIsOpen}
                    top={contentMenuTop}
                    left={contextMenuLeft}
                    closeContextMenu={contextMenuClose}
                    setElementToDelete={deleteFromContextMenu}
                    onPageElementDrop={addFromContextMenu}
                    elementTargeted={elementTargetedByContextMenu}
                    onPageElementCopy={onPageElementCopy}
                    onPageElementPaste={onPageElementPaste}
                />

                <ConfirmModal
                    show={!!elementToDelete}
                    title={elementToDelete?.title ?? 'Delete selected component'}
                    messages={[
                        elementToDelete?.message ??
                            'Are you sure you want to delete the selected component?',
                    ]}
                    buttonStyle="danger"
                    buttonCaption="Delete"
                    onCancel={() => setElementToDelete(null)}
                    onConfirm={onElementDelete}
                    container={container}
                />
            </div>
        );
    }
    return null;
};

export default Composer;
