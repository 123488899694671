import { any, anyPass, flatten, merge, pathOr, pipe, propEq } from 'ramda';
import { getCurrentTenant, updateCurrentTenant } from '../../../ts/sources/tenant';
import { NOTIFICATION_TYPES } from '../../../ts/constants';
import actionTypes from './actionTypes';
import { addNotification } from './notification';

export const fetchCurrentTenant = (tenantId) => async (dispatch) => {
    try {
        // Only users with the administrator role can access the tenant API endpoint
        const tenant = await getCurrentTenant(tenantId);

        dispatch({
            type: actionTypes.CURRENT_TENANT_SET,
            payload: {
                ...tenant,
            },
        });
    } catch (error) {
        dispatch(
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: error.message,
                isPersistent: true,
            }),
        );
    }
};

export const saveTenant = (tenantId, refreshAuthorizedTenant) => async (dispatch, getState) => {
    const { currentTenant } = getState();

    const defaultSecurtySettings = {
        authorizedAdminIPRanges: [],
        authorizedDrawIPRanges: [],
        authorizedPackagingIPRanges: [],
        authorizedRunIPRanges: [],
        authorizedServiceRemoteSites: [],
        isAdminRestrictedByIPRange: false,
        isDrawRestrictedByIPRange: false,
        isPackagingRestrictedByIPRange: false,
        isRunRestrictedByIPRange: false,
        isServiceRestrictedByRemoteSites: false,
    };

    // Ensure all fields are present on the securitySettings (no PATCH support)
    const mergedSecurtySettings = merge(defaultSecurtySettings, currentTenant.securitySettings);
    const payload = merge(currentTenant, { securitySettings: mergedSecurtySettings });

    const isMissingIPSettings = pipe(
        (tenant) =>
            flatten([
                pathOr([], ['securitySettings', 'authorizedAdminIPRanges'], tenant),
                pathOr([], ['securitySettings', 'authorizedDrawIPRanges'], tenant),
                pathOr([], ['securitySettings', 'authorizedPackagingIPRanges'], tenant),
                pathOr([], ['securitySettings', 'authorizedRunIPRanges'], tenant),
            ]),
        any(
            anyPass([
                propEq('developerName', ''),
                propEq('startIPAddress', ''),
                propEq('endIPAddress', ''),
            ]),
        ),
    )(payload);

    if (isMissingIPSettings) {
        return dispatch(
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message:
                    'All IP restrictions must include Name, Start IP Address and End IP Address',
            }),
        );
    }

    try {
        const tenant = await updateCurrentTenant(tenantId, JSON.stringify(payload));
        dispatch(
            addNotification({
                type: NOTIFICATION_TYPES.success,
                message: 'Tenant successfully saved',
            }),
        );

        dispatch({
            type: actionTypes.CURRENT_TENANT_SET,
            payload: {
                ...tenant,
            },
        });

        refreshAuthorizedTenant();
    } catch (error) {
        dispatch(
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: error.message,
                isPersistent: true,
            }),
        );
    }
};

export const changeSecuritySetting = ({ settingName, value }) => ({
    type: actionTypes.CURRENT_TENANT_SECURITY_SETTING_UPDATE,
    payload: {
        settingName,
        value,
    },
});

export const changeSAMLSetting = ({ settingPath, value }) => ({
    type: actionTypes.CURRENT_TENANT_SAML_SETTING_UPDATE,
    payload: {
        settingPath,
        value,
    },
});

export const changeOtlpSetting = ({ settingPath, value }) => ({
    type: actionTypes.CURRENT_TENANT_OTLP_SETTING_UPDATE,
    payload: {
        settingPath,
        value,
    },
});

export const updateDeveloperName = (developerName) => ({
    type: actionTypes.CURRENT_TENANT_DEVELOPER_NAME_UPDATE,
    payload: developerName,
});

export const updateSummary = ({ target }) => ({
    type: actionTypes.CURRENT_TENANT_SUMMARY_UPDATE,
    payload: target.value,
});

export const updateIPRange = ({ IPRange, index, rangeEntry }) => ({
    type: actionTypes.CURRENT_TENANT_IP_ENTRY_CONFIRM,
    payload: {
        IPRange,
        index,
        rangeEntry,
    },
});

export const addIPRange = ({ IPRange, rangeEntry }) => ({
    type: actionTypes.CURRENT_TENANT_IP_RANGE_ADD,
    payload: { IPRange, rangeEntry },
});

export const removeIPRange = ({ IPRange, index }) => ({
    type: actionTypes.CURRENT_TENANT_IP_RANGE_REMOVE,
    payload: {
        IPRange,
        index,
    },
});

export const updateServiceInvokerLogging = (isActive) => ({
    type: actionTypes.CURRENT_TENANT_SERVICE_INVOKER_LOGGING_UPDATE,
    payload: isActive,
});

export const updateReportingEndpoint = (endpoint) => ({
    type: actionTypes.CURRENT_TENANT_REPORTING_ENDPOINT_UPDATE,
    payload: endpoint,
});

export const updateTenantSettingEnvironment = (isActive) => ({
    type: actionTypes.CURRENT_TENANT_ENVIRONMENT_SETTING_UPDATE,
    payload: isActive,
});

export const updateTenantSettingThemes = (isActive) => ({
    type: actionTypes.CURRENT_TENANT_THEME_SETTING_UPDATE,
    payload: isActive,
});

export const addIntegrationAccount = (username, accountId, apiKey) => ({
    type: actionTypes.CURRENT_TENANT_INTEGRATION_ACCOUNT_ADD,
    payload: { username, accountId, apiKey },
});

export const removeIntegrationAccount = (accountId) => ({
    type: actionTypes.CURRENT_TENANT_INTEGRATION_ACCOUNT_REMOVE,
    payload: accountId,
});
