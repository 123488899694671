import '../../../css/footer.less';

const Footer = () => (
    <div className="footer">
        <ul className="list-inline">
            <li>
                <a href="https://status.boomi.com/" rel="noopener noreferrer" target="_blank">
                    Platform and Status Announcements
                </a>
            </li>
            <li>
                &copy; Copyright
                {` ${new Date().getFullYear()} `}
                Boomi, inc.
            </li>
            <li>
                <a href="https://boomi.com/privacy/" rel="noopener noreferrer" target="_blank">
                    Privacy
                </a>
            </li>
        </ul>
    </div>
);

export default Footer;
