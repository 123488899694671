import { BellSimple } from '@phosphor-icons/react';
import { useEffect } from 'react';
import '../../../../css/logo.less';
import '../../../../css/tab-header-dropdown.less';
import '../../../../css/transitions.less';
import boomiLogo from '../../../../img/AtomSphere-Logo.svg';
import FeatureFlagWrapper from '../feature-flags/FeatureFlagWrapper';
import { NOTIFICATION_TYPES } from '../../constants';
import translations from '../../translations';
import { useAuth } from '../AuthProvider';
import SSOExpiryNotification from '../notifications/SSOExpiryNotification';
import HeaderNav from './HeaderNav';
import RegionSwitcher from './RegionSwitcher';
import UserSettingsButton from './UserSettingsButton';
import { isNullOrUndefined } from '../../utils/guard';

interface HeaderProps {
    openTenantSelector: () => void;
}

const Header = ({ openTenantSelector }: HeaderProps) => {
    const {
        tenant,
        ssoMetadata,
        hasWarnedOfExpiry,
        signOutUser,
        addNotification,
        setHasWarnedOfExpiry,
        getSSOMetadata,
    } = useAuth();

    useEffect(() => {
        if (ssoMetadata && !hasWarnedOfExpiry && !isNullOrUndefined(ssoMetadata?.sessionExpiry)) {
            setHasWarnedOfExpiry(true);

            // Every minute check if we have to warn them of the expiry
            const ssoWarningInterval = window.setInterval(() => {
                const ssoExpiryMinutes =
                    (Date.parse(ssoMetadata.sessionExpiry) - Date.now()) / 1_000 / 60;

                if (ssoExpiryMinutes > 30) {
                    return;
                }

                addNotification({
                    type: NOTIFICATION_TYPES.warning,
                    message: (
                        <SSOExpiryNotification
                            ssoMetadata={ssoMetadata}
                            ssoExpiryInMinutes={ssoExpiryMinutes}
                        />
                    ),
                    isPersistent: true,
                });

                clearInterval(ssoWarningInterval);
            }, 60_000);

            // Every 5 seconds check if we should auto logout a user who has passed the max SSO session
            const logoutInterval = window.setInterval(() => {
                const freshMetadata = getSSOMetadata();
                if (freshMetadata && Date.parse(freshMetadata.sessionExpiry) < Date.now()) {
                    signOutUser();
                    clearInterval(logoutInterval);
                }
            }, 5_000);
        }
    });

    const onLogoutClick = () => {
        signOutUser();
    };

    return (
        <div className="tab-header">
            <div className="logo">
                <img src={boomiLogo} alt="Boomi AtomSphere" />
            </div>
            {tenant && (
                <FeatureFlagWrapper tenantId={tenant.id}>
                    <HeaderNav openTenantSelector={openTenantSelector} />
                </FeatureFlagWrapper>
            )}
            <nav className="tab-header-admin header-admin-right">
                {tenant && !tenant.isSso && <RegionSwitcher />}
                {/* SSO users shouldn't be able to see the user settings button for now,
                because it adds confusion and doesn't function fully as expected */}
                {tenant && !tenant.isSso && <UserSettingsButton tenantId={tenant.id} />}
                {/* Pendo is set up to open the Flow Resource Centre when the following is clicked */}
                <div className="dropdown-box" id="pendo-resource-centre">
                    <button className="dropdown-btn" type="button">
                        <BellSimple width="25" height="25" />
                    </button>
                </div>
                <div className="logout">
                    <button className="logout-btn" onClick={onLogoutClick} type="button">
                        {translations.HEADER_sign_out}
                    </button>
                </div>
            </nav>
        </div>
    );
};

export default Header;
