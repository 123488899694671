import { memo } from 'react';
import '../../../../css/collaboration/collaboration.css';
import type { User } from '../../collaboration/CollaborationProvider';

interface Props {
    users: Record<string, User>;
    presentUsers?: string[];
    currentUserId: string;
    onUserDoubleClick?: (userId: string) => void;
}

const Presence = ({ users, presentUsers, currentUserId, onUserDoubleClick }: Props) => {
    if (!(users && presentUsers)) {
        return null;
    }

    const usersExcludingMe = presentUsers.filter((id) => id !== currentUserId);

    const name = (id: string) =>
        `${users[id]?.firstName || 'User'} ${users[id]?.lastName || 'User'}`;
    const initials = (id: string) =>
        `${(users[id]?.firstName || 'U').toUpperCase().slice(0, 1)}${(users[id]?.lastName || 'U')
            .toUpperCase()
            .slice(0, 1)}`;

    const icons = usersExcludingMe.slice(0, 5).map((id: string, index: number) => {
        return (
            <div
                key={id}
                className={`collaboration-user collaboration-user-${index + 1}`}
                title={name(id)}
                onDoubleClick={onUserDoubleClick ? () => onUserDoubleClick(id) : undefined}
            >
                {initials(id)}
            </div>
        );
    });

    if (usersExcludingMe.length > 5) {
        icons.push(
            <div
                key="more"
                className="collaboration-user collaboration-user-more"
                title={usersExcludingMe.slice(-5).map(name).join('\n')}
            >
                {`+${usersExcludingMe.length - 5}`}
            </div>,
        );
    }

    icons.reverse();

    return <div className="collaboration-users">{icons}</div>;
};

const areEqual = (prevProps: Readonly<Props>, nextProps: Readonly<Props>) => {
    return prevProps.presentUsers?.length === nextProps.presentUsers?.length;
};

export default memo(Presence, areEqual);
