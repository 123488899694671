import { MagnifyingGlass, X } from '@phosphor-icons/react';
import { type ChangeEventHandler, type KeyboardEventHandler, useState } from 'react';
import ButtonIcon from '../buttons/ButtonIcon';
import './css/search.less';

interface Props {
    id?: string;
    value: string | undefined;
    onChange: (value: string) => void;
    placeholder?: string;
}

const SearchInput = ({
    id = 'search-input',
    value = '',
    onChange: submit,
    placeholder = 'Search',
}: Props) => {
    const [searchValue, setSearchValue] = useState(value);

    const onKeyup: KeyboardEventHandler<HTMLInputElement> = (event) => {
        if (event.key === 'Enter') {
            submit(searchValue);
        }
    };

    const onChange: ChangeEventHandler<HTMLInputElement> = (event) =>
        setSearchValue(event.target.value);

    const onClickClear = () => {
        setSearchValue('');
        submit('');
    };

    const onClickSearch = () => submit(searchValue);

    return (
        <div className="search-control-wrapper">
            <input
                id={id}
                className="search-input"
                value={searchValue}
                onChange={onChange}
                placeholder={placeholder}
                onKeyUp={onKeyup}
                aria-label="Search"
            />
            {searchValue.length > 0 ? (
                <ButtonIcon
                    className="clear-btn"
                    onClick={onClickClear}
                    aria-label="Clear Search Query and Results"
                >
                    <X className="clear-icon" />
                </ButtonIcon>
            ) : null}
            <button
                title="Search"
                aria-label="Get Search Results"
                className="search-input-button"
                type="button"
                onClick={onClickSearch}
            >
                <MagnifyingGlass height={18} width={18} />
            </button>
        </div>
    );
};

export default SearchInput;
