import type { Filter, ItemCollectionResponse } from '../types';
import { fetchAndParse, filterToQuery } from '../utils/ajax';
import { getTenantId } from '../utils/tenant';

export interface DependencyAPI {
    id: string;
    developerName: string;
    developerSummary: string;
    type: string;
    dependsOnElementId: string;
    dependsOnElementType: string;
    dependsOnElementDeveloperName: string;
    dependsOnElementDeveloperSummary: string;
}

export default {
    getAll: ({ id, filters }: { id: string; filters: Filter }) => {
        const query = filterToQuery(filters);
        return fetchAndParse<ItemCollectionResponse<DependencyAPI>>({
            url: `/api/draw/1/dependents/${id}${query}`,
            method: 'GET',
            headers: {
                ManyWhoTenant: getTenantId(),
            },
        });
    },
};
