import { useRef } from 'react';
import translations from '../../translations';
import AnomalyOverview from './AnomalyOverview';
import AnomalyFilters from './AnomalyFilters';
import AnomalyChartWrapper from './AnomalyChartWrapper';
import { View } from '../developerDashboard/DeveloperDashboard';

import '../../../../css/anomaly-dashboard.less';

const AnomalyDashboard = ({
    setCurrentView,
}: {
    screen?: string;
    setCurrentView?: (view: View) => void;
}) => {
    const modalContainerRef = useRef<HTMLDivElement | null>(null);

    return (
        <div className="anomaly-detection" ref={modalContainerRef}>
            {setCurrentView ? (
                <span className="title-bar">
                    <h1>
                        <button
                            className="link-emulate"
                            onClick={() => setCurrentView(View.summary)}
                            type="button"
                        >
                            {translations.DASHBOARD_header}
                        </button>
                        {' > '}
                        {translations.ANOMALY_anomaly_detection_title}
                    </h1>
                </span>
            ) : (
                <h1 className="anomaly-title">
                    {translations.ANOMALY_anomaly_detection_title}
                    <span className="beta-tag">Beta</span>
                </h1>
            )}
            <AnomalyFilters />
            <AnomalyChartWrapper />
            <AnomalyOverview container={modalContainerRef.current} />
        </div>
    );
};

export default AnomalyDashboard;
