import classNames from 'classnames';
import type React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { closeTab as closeTabAction } from '../../../js/actions/reduxActions/tabs';
import translations from '../../translations';
import ButtonIcon from '../buttons/ButtonIcon';
import ConfirmModal from '../generic/modal/ConfirmModal';
import { useAuth } from '../AuthProvider';
import type { Tab } from '../../types';
import { type NavigateFunction, useNavigate } from 'react-router-dom';

interface Props {
    tab: Tab;
    onSelect: (tabKey: string) => void;
    hideCloseButtons: boolean;
    hideTitles: boolean;
    closeTab: (key: string, tenantId: string, navigate: NavigateFunction) => void;
}

const TabItem = ({ tab, onSelect, hideCloseButtons, hideTitles, closeTab }: Props) => {
    const { tenant } = useAuth();

    const [showConfirm, setShowConfirm] = useState(false);

    const navigate = useNavigate();

    const isAdminTab = tab.type === 'flows';

    const onClose = (event: React.MouseEvent) => {
        if (tab.showConfirmation) {
            setShowConfirm(true);
            return;
        }

        event.preventDefault();
        event.stopPropagation();

        if (tenant?.id) {
            closeTab(tab.key, tenant.id, navigate);
        }
    };

    const renderCloseConfirm = (show: boolean) => {
        const confirmProps = {
            title: translations.TABS_close_page_layout,
            messages: [
                translations.TABS_page_unsaved_warning_1,
                translations.TABS_page_unsaved_warning_2,
            ],
            onCancel: () => setShowConfirm(false),
            onConfirm: (event: React.SyntheticEvent) => {
                if (tenant?.id) {
                    event.stopPropagation();
                    setShowConfirm(false);
                    closeTab(tab.key, tenant.id, navigate);
                }
            },
            show,
            container: document.querySelector(`[data-tab-content="${tab.key}"]`) as HTMLElement,
        };

        return <ConfirmModal {...confirmProps} />;
    };

    const renderIcon = () => {
        const Icon = tab.icon;

        return (
            <div className="glyphicon">
                <Icon width="20" height="20" />
            </div>
        );
    };

    const renderTitle = () => {
        if (hideTitles && tab.isActive === false) {
            return null;
        }

        return <span className="tab-header-item-label">{tab.title}</span>;
    };

    const renderCloseButton = () => {
        if ((hideCloseButtons && tab.isActive === false) || isAdminTab) {
            return null;
        }

        const className = classNames(
            'tab-icon-wrapper',
            'close-tab',
            `close-tab-${tab.type.toLowerCase()}`,
        );

        return (
            <ButtonIcon
                glyph="cross"
                className={className}
                iconClass="tab-icon"
                onClick={onClose}
                title={`Close tab ${tab.title}`}
            />
        );
    };

    const classes = classNames({
        'tab-header-item': true,
        active: tab.isActive,
        'center-content icon-only': hideTitles,
    });

    return (
        <div
            className={classes}
            title={tab.title}
            data-tab-type={tab.type}
            data-tab-key={tab.key}
            onClick={() => onSelect(tab.key)}
            onKeyDown={({ key }) => {
                if (key !== 'Enter' && key !== ' ') {
                    return;
                }
                onSelect(tab.key);
            }}
            tabIndex={0}
            role="button"
        >
            {renderIcon()}
            {renderTitle()}
            {renderCloseButton()}
            {renderCloseConfirm(showConfirm)}
        </div>
    );
};

const mapDispatchToProps = {
    closeTab: closeTabAction,
};

export default connect(null, mapDispatchToProps)(TabItem);
