import { ExLoader } from '@boomi/exosphere';
import { useAnomalyDashboard } from './AnomalyProvider';
import AnomalyBarChart from './AnomalyBarChart';
import AnomalyDonutChart from './AnomalyDonutChart';

const AnomalyChartWrapper = () => {
    const { isLoadingData } = useAnomalyDashboard();
    return isLoadingData ? (
        <ExLoader />
    ) : (
        <span className="chart-area">
            <AnomalyBarChart />
            <AnomalyDonutChart />
        </span>
    );
};

export default AnomalyChartWrapper;
