import { connect } from 'react-redux';
import '../../../css/sidebar.less';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import classNames from 'classnames';

const Sidebar = ({ className, isCollapsed, onCollapse, children }) => {
    const sidebarClassName = classNames('sidebar', className, {
        collapsed: isCollapsed,
    });

    return (
        <div className={sidebarClassName}>
            <div className="sidebar-inner added">
                <button
                    className="sidebar-collapse"
                    onClick={() => onCollapse({ isCollapsed })}
                    data-testid="sidebar-collapse-button"
                    type="button"
                >
                    {isCollapsed ? <CaretRight /> : <CaretLeft />}
                </button>
                <div className="list-unstyled">{children}</div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ sidebar: { isCollapsed } }, ownProps) => {
    // ownProps isCollapsed should override redux state
    return { isCollapsed: ownProps.isCollapsed ?? isCollapsed };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(Sidebar);
