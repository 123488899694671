import { useEffect, useState } from 'react';
import NameInput from '../../../../../js/components/flow/elementConfigurations/common/NameInput';
import { useMapElement } from '../../../../../js/components/flow/elementConfigurations/contextProviders/MapElementProvider';
import translations from '../../../../translations';
import type { MapElementProvider } from '../../../../types';
import ButtonDefault from '../../../buttons/ButtonDefault';
import ButtonPrimary from '../../../buttons/ButtonPrimary';
import FormGroup from '../../../generic/FormGroup';
import ModalBody from '../../../generic/modal/ModalBody';
import ModalFooter from '../../../generic/modal/ModalFooter';
import { useOpenApi } from '../contextProviders/OpenApiProvider';
import ValueSelectorModal from '../../../values/selector/ValueSelectorModal';
import { DATA_ACTION_VALUE_SELECTION_LABEL } from '../../../../../js/components/flow/elementConfigurations/common/constants';
import { getOpenApiSchemaInfoFromValueId } from '../../../../sources/openapi';
import { ExLoader } from '@boomi/exosphere';
import OpenApiParameterList from './OpenApiParameterList';

/**
 * The openapi action configuration screen
 */
const OpenApiDetails = () => {
    const [noSchema, setNoSchema] = useState<boolean>(false);
    const { mapElement, setConfigTitle, container }: MapElementProvider = useMapElement();
    const {
        hasSubmitted,
        operationTypes,
        operationDelete,
        operationLoad,
        operationSave,
        onOperationChange,
        currentOperation,
        openApiActionToEdit,
        setHasSubmitted,
        isValidOpenApiAction,
        onApplyOpenApiAction,
        onReturnToDefaultScreen,
        isActionNameValid,
        onUpdateActionName,
        isValidServiceValue,
        onValueChanged,
        setOpenApiSchemaInfo,
        openApiSchemaInfo,
        onPathChange,
        isValidPath,
    } = useOpenApi();
    const openApiAction = openApiActionToEdit.openApiAction;
    const { index } = openApiActionToEdit;
    const valueLabel =
        currentOperation === operationLoad
            ? DATA_ACTION_VALUE_SELECTION_LABEL.LOAD
            : currentOperation === operationSave
              ? DATA_ACTION_VALUE_SELECTION_LABEL.SAVE
              : currentOperation === operationDelete
                ? DATA_ACTION_VALUE_SELECTION_LABEL.DELETE
                : translations.VALUE;

    useEffect(() => {
        setConfigTitle(`${translations.OPENAPI_actions_for}${mapElement.developerName ?? ''}`);

        return () => setConfigTitle(null);
    }, [mapElement.developerName, setConfigTitle]);

    useEffect(() => {
        setOpenApiSchemaInfo(undefined);
        setNoSchema(false);
        const getInfo = async () => {
            if (
                (openApiAction?.value ?? null) !== null &&
                (openApiAction?.value?.id ?? null) !== null
            ) {
                const valueId = openApiAction?.value?.id as string;
                const info = await getOpenApiSchemaInfoFromValueId({
                    valueId,
                    operationType: currentOperation,
                });
                if (info?.schemaInfo) {
                    setOpenApiSchemaInfo(info.schemaInfo);
                } else {
                    setNoSchema(true);
                }
            }
        };
        getInfo();
    }, [currentOperation, openApiAction?.value, setOpenApiSchemaInfo]);

    const onSave = () => {
        setHasSubmitted(true);

        if (isValidOpenApiAction() && index !== null) {
            onApplyOpenApiAction(index);
        }
    };
    const renderBody = () => (
        <>
            <NameInput
                isValid={isActionNameValid()}
                showValidation={hasSubmitted}
                id="openapi-name"
                name={openApiAction?.developerName ?? ''}
                onUpdateName={onUpdateActionName}
            />
            <FormGroup label="Operation" htmlFor="operation-select">
                <>
                    <select
                        id="operation-select"
                        value={currentOperation}
                        onChange={onOperationChange}
                        placeholder="Select Operation"
                        required
                        className="form-control form-control-width"
                    >
                        {operationTypes.map((operationType) => (
                            <option key={operationType} value={operationType}>
                                {operationType}
                            </option>
                        ))}
                    </select>
                </>
            </FormGroup>
            <FormGroup
                label={valueLabel}
                isRequired
                isValid={isValidServiceValue()}
                showValidation={hasSubmitted}
                validationMessage={translations.MAP_ELEMENT_value_field_validation_message}
            >
                <ValueSelectorModal
                    value={openApiAction?.value ?? null}
                    onChangeAsValueReference={onValueChanged}
                    includeSystemValues={false}
                    container={container}
                />
            </FormGroup>
            {!openApiSchemaInfo && openApiAction?.value && !noSchema && <ExLoader />}
            {noSchema && (
                <span className="help-block error-state">{translations.OPENAPI_no_schema}</span>
            )}
            {openApiSchemaInfo && (
                <FormGroup
                    label="Path"
                    htmlFor="path-select"
                    isRequired
                    showValidation={hasSubmitted}
                    isValid={isValidPath()}
                >
                    <>
                        <select
                            id="path-select"
                            value={openApiAction?.path ?? undefined}
                            onChange={onPathChange}
                            required
                            className="form-control form-control-width"
                        >
                            <option value="">{translations.OPENAPI_select_path}</option>
                            {openApiSchemaInfo.paths.map((path) => (
                                <option key={path.path} value={path.path}>
                                    {path.path}
                                </option>
                            ))}
                        </select>
                    </>
                </FormGroup>
            )}
            {openApiAction?.parameters && openApiAction?.parameters.length > 0 && (
                <OpenApiParameterList />
            )}
        </>
    );

    const renderFooter = () => [
        <ButtonDefault
            key="cancelButton"
            className="flex-child-right"
            onClick={onReturnToDefaultScreen}
        >
            {translations.GRAPH_config_panel_cancel}
        </ButtonDefault>,
        <ButtonPrimary key="addButton" className="margin-left" onClick={onSave}>
            {translations.GRAPH_config_panel_add}
        </ButtonPrimary>,
    ];

    return (
        <>
            <ModalBody>{renderBody()}</ModalBody>
            <ModalFooter>{renderFooter()}</ModalFooter>
        </>
    );
};

export default OpenApiDetails;
