import SecurityIPSetting, {
    type IPRangeEntry,
} from '../../../ts/components/tenant/SecurityIPSetting';

const IP_RANGE = {
    admin: 'authorizedAdminIPRanges',
    draw: 'authorizedDrawIPRanges',
    packaging: 'authorizedPackagingIPRanges',
    run: 'authorizedRunIPRanges',
};

interface SecurityProps {
    isRunRestrictedByIPRange: boolean;
    isDrawRestrictedByIPRange: boolean;
    isAdminRestrictedByIPRange: boolean;
    isPackagingRestrictedByIPRange: boolean;
    authorizedAdminIPRanges: IPRangeEntry[] | null;
    authorizedDrawIPRanges: IPRangeEntry[] | null;
    authorizedPackagingIPRanges: IPRangeEntry[] | null;
    authorizedRunIPRanges: IPRangeEntry[] | null;
}

const Security = ({
    isRunRestrictedByIPRange = false,
    isDrawRestrictedByIPRange = false,
    isAdminRestrictedByIPRange = false,
    isPackagingRestrictedByIPRange = false,
    authorizedAdminIPRanges,
    authorizedDrawIPRanges,
    authorizedPackagingIPRanges,
    authorizedRunIPRanges,
}: SecurityProps) => (
    <div className="form-group">
        <SecurityIPSetting
            IPRange={IP_RANGE.run}
            isActive={isRunRestrictedByIPRange}
            settingPropertyName="isRunRestrictedByIPRange"
            rangeEntries={authorizedRunIPRanges ?? []}
            labelText="Restrict run access by IP address"
        />
        <SecurityIPSetting
            IPRange={IP_RANGE.draw}
            isActive={isDrawRestrictedByIPRange}
            settingPropertyName="isDrawRestrictedByIPRange"
            rangeEntries={authorizedDrawIPRanges ?? []}
            labelText="Restrict draw access by IP address"
        />
        <SecurityIPSetting
            IPRange={IP_RANGE.admin}
            isActive={isAdminRestrictedByIPRange}
            settingPropertyName="isAdminRestrictedByIPRange"
            rangeEntries={authorizedAdminIPRanges ?? []}
            labelText="Restrict admin access by IP address"
        />
        <SecurityIPSetting
            IPRange={IP_RANGE.packaging}
            isActive={isPackagingRestrictedByIPRange}
            settingPropertyName="isPackagingRestrictedByIPRange"
            rangeEntries={authorizedPackagingIPRanges ?? []}
            labelText="Restrict package access by IP address"
        />
    </div>
);

export default Security;
