import { useState, useRef } from 'react';
import translations from '../../../../ts/translations';
import Breadcrumb from '../../../../ts/components/Breadcrumb';
import ButtonDefault from '../../../../ts/components/buttons/ButtonDefault';
import ButtonPrimary from '../../../../ts/components/buttons/ButtonPrimary';
import ConfirmModal from '../../../../ts/components/generic/modal/ConfirmModal';
import Footer from '../../../../ts/components/generic/Footer';
import FormGroup from '../../../../ts/components/generic/FormGroup';
import Dependents from '../Dependents';
import TypeProperties from './TypeProperties';
import { pathOr } from 'ramda';
import { SYSTEM_TYPES, NOTIFICATION_TYPES } from '../../../../ts/constants';
import { isNullOrEmpty } from '../../../../ts/utils/guard';
import { stringReplace } from '../../../../ts/utils/string';
import { useTypes } from './TypesProvider';
import CopyableText from '../../../../ts/components/generic/CopyableText';

const TypeDetail = () => {
    const [deleteModalIsVisible, setDeleteModalIsVisible] = useState(false);

    const {
        removeProperty,
        setPropertyToDelete,
        propertyToDelete,
        saveType,
        typeToEdit,
        updateTypeName,
        updateTypeComments,
        returnToTypeList,
        tenantId,
        breadCrumbs,
        addNotification,
    } = useTypes();

    const modalContainerRef = useRef(null);

    const checkRequiredFields = () => ({
        defaultDeveloperName: pathOr('', ['developerName'], typeToEdit),
        defaultProperties: pathOr([], ['properties'], typeToEdit),
    });

    const [hasSubmitted, setHasSubmitted] = useState(false);

    const onPropertyDelete = (type) => {
        setPropertyToDelete(type);
        setDeleteModalIsVisible(true);
    };

    const onSave = () => {
        if (SYSTEM_TYPES.includes(typeToEdit.developerName)) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: 'Saving system types is not allowed.',
                isPersistent: true,
            });
        } else {
            setHasSubmitted(true);

            const isFormValid = Object.values(checkRequiredFields()).every(
                (field) => !isNullOrEmpty(field),
            );

            if (isFormValid) {
                saveType();
            }
        }
    };

    const { defaultDeveloperName } = checkRequiredFields();

    return (
        <>
            <div className="admin-page" ref={modalContainerRef}>
                <ConfirmModal
                    show={deleteModalIsVisible}
                    title={translations.TYPE_delete_property_confirmation_title}
                    messages={[
                        stringReplace(translations.TYPE_delete_property_confirmation_message, {
                            propertyName: propertyToDelete?.developerName ?? '',
                        }),
                        translations.GENERAL_cannot_be_undone,
                    ]}
                    buttonStyle="danger"
                    buttonCaption="Delete"
                    onCancel={() => setDeleteModalIsVisible(false)}
                    onConfirm={() => {
                        removeProperty();
                        setDeleteModalIsVisible(false);
                    }}
                    container={modalContainerRef.current}
                />
                <h1>Type: {typeToEdit.developerName || ''}</h1>
                {typeToEdit.id ? (
                    <CopyableText
                        wrapperClassName="type-id"
                        copyableText={typeToEdit.id}
                        labelText="ID: "
                        hasCopyButton={true}
                    />
                ) : null}
                {typeToEdit.serviceElementDeveloperName ? (
                    <CopyableText
                        wrapperClassName="connector-name"
                        copyableText={typeToEdit.serviceElementDeveloperName}
                        labelText="Connector Name: "
                        hasCopyButton={true}
                    />
                ) : null}
                {typeToEdit.serviceElementId ? (
                    <CopyableText
                        wrapperClassName="connector-id"
                        copyableText={typeToEdit.serviceElementId}
                        labelText="Connector ID: "
                        hasCopyButton={true}
                    />
                ) : null}
                <div className="margin-bottom margin-top-large" data-testid="type-breadcrumbs">
                    <Breadcrumb trail={breadCrumbs.trail} activeItemId={breadCrumbs.activeItemId} />
                </div>
                <FormGroup
                    label="Name"
                    htmlFor="type-name"
                    isRequired
                    validationMessage="Name field is required"
                    isValid={!isNullOrEmpty(typeToEdit.developerName)}
                    showValidation={hasSubmitted}
                >
                    <input
                        id="type-name"
                        className="form-control form-control-long"
                        value={defaultDeveloperName}
                        onChange={(e) => updateTypeName(e.target.value)}
                        type="text"
                        placeholder="A name for this type"
                        autoFocus
                    />
                </FormGroup>
                <FormGroup label="Comments" htmlFor="type-comments">
                    <textarea
                        id="type-comments"
                        className="form-control form-control-textarea"
                        value={typeToEdit.developerSummary ?? ''}
                        onChange={(e) => updateTypeComments(e.target.value)}
                        placeholder="Comments about this Type"
                    />
                </FormGroup>

                <TypeProperties onPropertyDelete={onPropertyDelete} hasSubmitted={hasSubmitted} />
                {typeToEdit.id ? <Dependents id={typeToEdit.id} tenantId={tenantId} /> : null}
            </div>

            <Footer>
                <ButtonDefault title="Back" className="flex-child-right" onClick={returnToTypeList}>
                    Back
                </ButtonDefault>
                <ButtonPrimary title="Save Type" onClick={onSave}>
                    Save Type
                </ButtonPrimary>
            </Footer>
        </>
    );
};

export default TypeDetail;
