import { MagnifyingGlass } from '@phosphor-icons/react';
import type { FormEventHandler, KeyboardEventHandler } from 'react';

interface GenericSearchBoxProps {
    onSearchChange: FormEventHandler<HTMLInputElement>;
    onSearchKeyUp?: KeyboardEventHandler<HTMLInputElement>;
    searchText: string;
    hasSearchButton: boolean;
    hasAutoFocus: boolean;
}

const GenericSearchBox = ({
    onSearchChange,
    onSearchKeyUp,
    searchText,
    hasSearchButton,
    hasAutoFocus,
}: GenericSearchBoxProps) => {
    const searchButton = hasSearchButton && (
        <span className="input-group-btn">
            <span className="btn btn-inline-icon" aria-label="Search">
                <MagnifyingGlass />
            </span>
        </span>
    );

    return (
        <div className="form-control form-control-with-icon">
            <input
                className="form-control-input"
                placeholder="Search"
                onInput={onSearchChange}
                onKeyUp={(e) => {
                    if (onSearchKeyUp) {
                        onSearchKeyUp(e);
                    }
                }}
                defaultValue={searchText}
                autoFocus={hasAutoFocus}
            />
            {searchButton}
        </div>
    );
};

export default GenericSearchBox;
