import type { FlowLaunchesDashboardResponse } from '../../sources/dashboard';
import type { TimeFilters } from '../../types/dashboard';
import { type ElementRef, useEffect, useRef } from 'react';
import { Chart, type ChartConfiguration } from 'chart.js';
import { formatChartDate } from '../../utils/dashboard';

interface Props {
    flowLaunches: FlowLaunchesDashboardResponse[];
    dateRange: TimeFilters;
    chartClass: string;
}

interface LineData {
    x: string;
    y: number;
}

interface FormatedChartData {
    data: LineData[];
    label: string;
    tension: number;
}

const DeveloperDashboardLaunchesChart = ({ flowLaunches, dateRange, chartClass }: Props) => {
    const canvasRef = useRef<ElementRef<'canvas'>>(null);

    useEffect(() => {
        const getChartData = (
            _flowLaunches: FlowLaunchesDashboardResponse[],
            _dateRange: TimeFilters,
        ) => {
            const formattedChartData: FormatedChartData[] = [];

            _flowLaunches.forEach((fl) => {
                const lineData: LineData[] = [];

                fl.flowLaunchData.forEach((flowData) => {
                    lineData.push({
                        x: formatChartDate(new Date(flowData.bucket), _dateRange),
                        y: flowData.counter,
                    });
                });

                formattedChartData.push({ data: lineData, label: fl.flowName, tension: 0.3 });
            });

            return formattedChartData;
        };

        const context = canvasRef.current?.getContext('2d');

        if (!context) {
            return;
        }

        const chartConfig: ChartConfiguration<'line', { x: string; y: number }[]> = {
            type: 'line',
            data: { datasets: getChartData(flowLaunches, dateRange) },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                elements: { point: { radius: 2, hoverRadius: 5, hitRadius: 6 } }, // This can be adjusted based on feedback.
            },
        };

        const chart = new Chart(context, chartConfig);
        // Cleanup the chart to prevent it from wildly animating when switching away and then back to Dashboard tab.
        return () => {
            chart.destroy();
        };
    }, [flowLaunches, dateRange]);

    return (
        <div className={`dash-chart launches-chart ${chartClass}`}>
            <canvas ref={canvasRef} data-testid="chart-canvas" />
        </div>
    );
};

export default DeveloperDashboardLaunchesChart;
