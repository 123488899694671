import Header from './header/Header';
import Footer from '../../js/components/footer';
import NotificationList from './notifications/NotificationList';
import Tenant from './Tenant';
import AdminSidebar from '../../js/components/sidebars/AdminSidebar';
import FeatureFlagWrapper from './feature-flags/FeatureFlagWrapper';
import TenantSwitcher from './masthead/TenantSwitcher';
import IdleTimeout from './authentication/IdleTimeout';

import { useState } from 'react';
import { CollaborationProvider } from '../collaboration/CollaborationProvider';

/**
 *
 * @param {String} currentLoggedInTenantId
 * @description The base component that renders all parts of the application that needs
 * to display the header and footer.
 */
const Tooling = ({ currentLoggedInTenantId }: { currentLoggedInTenantId: string | undefined }) => {
    const [isTenantSelectorOpen, setIsTenantSelectorOpen] = useState(false);

    const onCloseTenantSelector = () => setIsTenantSelectorOpen(false);
    const openTenantSelector = () => setIsTenantSelectorOpen(true);

    return (
        <div className="draw">
            <Header openTenantSelector={openTenantSelector} />
            <AdminSidebar />
            {isTenantSelectorOpen || !currentLoggedInTenantId ? (
                <TenantSwitcher onClose={onCloseTenantSelector} />
            ) : null}
            {currentLoggedInTenantId && (
                <>
                    <IdleTimeout />
                    <FeatureFlagWrapper tenantId={currentLoggedInTenantId}>
                        <CollaborationProvider tenantId={currentLoggedInTenantId}>
                            <div className="tab-outer">
                                <Tenant isTenantSelectorOpen={isTenantSelectorOpen} />
                            </div>
                        </CollaborationProvider>
                    </FeatureFlagWrapper>
                </>
            )}
            <Footer />
            <NotificationList />
        </div>
    );
};

export default Tooling;
