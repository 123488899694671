import translations from '../../../../../../../translations';
import FormGroup from '../../../../../../generic/FormGroup';
import ConfigSection from '../ConfigSection';
import PageElementAttributes from '../component-configuration/attributes-config/PageElementAttributes';

import { isNullOrEmpty } from '../../../../../../../utils/guard';
import { useComposer } from '../../ComposerProvider';
import { COMPONENT_CONFIGURATION_PATH as cfgPath } from '../../../../../constants';
import { formatAttributes } from '../../../../../utils';
import type { PageContainer } from '../../../../../../../types';
import StylesConfig from '../component-configuration/styles-config/StylesConfig';

const ContainerSection = ({ container }: { container: PageContainer; screen?: string }) => {
    const { onPageElementUpdate } = useComposer();
    const { developerName, label, attributes, className } = container;
    const id = container.id as string;

    const attributesList = [
        { label: 'Can a container be collapsed', value: 'isCollapsible' },
        { label: 'Name of the container collapse group', value: 'collapseGroup' },
        { label: 'Is a container collapsed', value: 'isCollapsed' },
    ];

    const formattedAttributes = formatAttributes(attributes);

    const updateClassName = (className: string) =>
        onPageElementUpdate(id || '', cfgPath['CLASSNAME'], className);

    return (
        <>
            <ConfigSection title="General" dataTestId="general-container-section">
                <FormGroup
                    label="Name"
                    htmlFor="container-name"
                    isRequired
                    isValid={!isNullOrEmpty(developerName)}
                    validationMessage={translations.PAGE_BUILDER_name_field_validation_message}
                    showValidation={true}
                >
                    <input
                        className="form-control"
                        id="container-name"
                        type="text"
                        value={developerName || ''}
                        onChange={({ target }) =>
                            onPageElementUpdate(id, cfgPath['NAME'], target.value)
                        }
                        title="Container name"
                    />
                </FormGroup>
                <FormGroup label="Label" htmlFor="container-label">
                    <input
                        className="form-control"
                        id="container-label"
                        type="text"
                        value={label || ''}
                        onChange={({ target }) =>
                            onPageElementUpdate(id, cfgPath['LABEL'], target.value)
                        }
                        title="Container label"
                    />
                </FormGroup>
            </ConfigSection>

            <StylesConfig className={className} updateClassName={updateClassName} />

            <PageElementAttributes
                id={id}
                attributes={formattedAttributes}
                attributesList={attributesList}
            />
        </>
    );
};

export default ContainerSection;
