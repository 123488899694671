import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import '../../../../css/tenant.less';
import {
    fetchCurrentTenant,
    saveTenant,
    updateSummary,
    updateTenantSettingEnvironment,
    updateTenantSettingThemes,
} from '../../actions/reduxActions/currentTenant';
import { notifyError as notifyErrorAction } from '../../actions/reduxActions/notification';
import translations from '../../../ts/translations';
import { useScrollSpy } from '../../../ts/hooks/scroll';
import Loader from '../../../ts/components/loader/Loader';
import DeveloperName from '../../../ts/components/tenant/DeveloperName';
import ImportTenant from '../../../ts/components/tenant/ImportTenant';
import ExportTenant from '../../../ts/components/tenant/ExportTenant';
import OtlpSettings from './otlp/OtlpSettings';
import Reporting from '../../../ts/components/tenant/Reporting';
import SAMLSettingsComponent from './saml/SAMLSettings';
import SubtenantList from '../../../ts/components/tenant/SubtenantList';
import Security from '../../../ts/components/tenant/Security';
import UserList from '../../../ts/components/tenant/UserList';
import Toggle from '../../../ts/components/inputs/Toggle';
import IntegrationAccounts from '../../../ts/components/tenant/IntegrationAccounts';
import { closeTab } from '../../actions/reduxActions/tabs';
import { TAB_TYPES } from '../../../ts/constants';
import { useAuth } from '../../../ts/components/AuthProvider';
import { useNavigate } from 'react-router-dom';

const Tenant = ({
    currentTenant,
    navigateTo,
    setActiveSectionId,
    hasMenu,
    getCurrentTenant,
    amendSummary,
    storeTenant,
    notifyError,
    updateEnvironmentSetting,
    updateThemeSetting,
    closeTab,
    tabs,
}) => {
    const { user, fetchUser, tenant: authorizedTenant } = useAuth();
    const {
        developerName,
        securitySettings,
        developerSummary,
        id: tenantId,
        observabilitySettings,
        parentTenant,
    } = currentTenant || {};

    const [isLoading, setIsLoading] = useState(true);
    const contentRef = useRef(null);
    const [environments, setEnvironments] = useState(
        authorizedTenant?.tenantSettings?.environments,
    );
    const [themes, setThemes] = useState(authorizedTenant?.tenantSettings?.themes);
    const [isConfigValid, setIsConfigValid] = useState(true);

    useEffect(() => {
        (async () => {
            await getCurrentTenant(authorizedTenant.id);

            setIsLoading(false);
        })();
    }, [getCurrentTenant, authorizedTenant.id]);

    const navigate = useNavigate();

    // using a scrollspy based on the intersection observer to report back to
    // the side-menu the currently active section so the side-menu can update
    // itself (every content page with a side-menu will have to implement this
    // hook)
    useScrollSpy(contentRef, hasMenu, setActiveSectionId);

    const save = () => {
        updateEnvironmentSetting(environments);
        updateThemeSetting(themes);
        storeTenant(tenantId, fetchUser);
    };

    return (
        <>
            <div className="admin-page tenant-page" ref={contentRef}>
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <h2 id="tenant-name">{translations.TENANT_tenant_name_heading_text}</h2>
                        <DeveloperName setIsConfigValid={setIsConfigValid} />

                        <h2 id="tenant-users">{translations.TENANT_tenant_users_heading_text}</h2>
                        <p>{translations.TENANT_user_description}</p>
                        <UserList navigateTo={navigateTo} tenantId={tenantId} userId={user.id} />

                        <h2 id="tenant-environment">{translations.ENVIRONMENT_name}</h2>
                        <p>{translations.ENVIRONMENT_description}</p>
                        <div className="form-group">
                            <label>
                                <Toggle
                                    isOn={environments}
                                    onChange={({ isOn }) => setEnvironments(isOn)}
                                    testId="environment-setting"
                                    isDisabled={currentTenant?.hasMcr}
                                />
                                {translations.ENVIRONMENT_toggle_message}
                            </label>
                        </div>
                        <p data-testid="environment-toggle-disabled-message">
                            {currentTenant?.hasMcr
                                ? translations.ENVIRONMENT_toggle_disabled
                                : null}
                        </p>

                        <h2 id="tenant-themes">{translations.TENANT_theme_heading}</h2>
                        <p>{translations.TENANT_theme_description}</p>
                        <div className="form-group">
                            <label>
                                <Toggle
                                    isOn={themes}
                                    onChange={({ isOn }) => setThemes(isOn)}
                                    testId="theme-setting"
                                />
                                {translations.TENANT_toggle_message}
                            </label>
                        </div>

                        <IntegrationAccounts />

                        {parentTenant ? null : (
                            <>
                                <h2 id="tenant-subtenants">
                                    {translations.TENANT_subtenants_heading_text}
                                </h2>
                                <p>
                                    {translations.TENANT_subtenant_description_1}
                                    <br />
                                    {translations.TENANT_subtenant_description_2}
                                </p>
                                <SubtenantList navigateTo={navigateTo} tenantId={tenantId} />
                            </>
                        )}

                        <h2 id="tenant-single-sign-on">{translations.TENANT_sso_heading_text}</h2>
                        <p>{translations.TENANT_sso_description}</p>
                        <SAMLSettingsComponent
                            isActive={securitySettings ? securitySettings.isSamlEnabled : false}
                            settings={securitySettings ? securitySettings.samlSettings : null}
                            isSso={authorizedTenant.isSso}
                        />

                        <h2 id="tenant-security">{translations.TENANT_security_heading_text}</h2>
                        <p>{translations.TENANT_security_description}</p>
                        <Security {...securitySettings} />

                        <h2 id="tenant-reporting">{translations.TENANT_reporting_heading_text}</h2>
                        <p>{translations.TENANT_reporting_description}</p>
                        <Reporting />

                        <OtlpSettings settings={observabilitySettings} />

                        <h2 id="tenant-export">Export</h2>
                        <ExportTenant
                            tenantId={tenantId}
                            tenantName={developerName}
                            notifyError={notifyError}
                        />
                        <h2 id="tenant-import">Import</h2>
                        <ImportTenant
                            tenantId={tenantId}
                            tenantName={developerName}
                            notifyError={notifyError}
                        />

                        <h2 id="tenant-comments">
                            {translations.TENANT_tenant_comments_heading_text}
                        </h2>
                        <div className="form-group">
                            <label htmlFor="developer-summary">
                                {translations.TENANT_developer_summary_label}
                            </label>
                            <textarea
                                id="developer-summary"
                                maxLength="1000"
                                rows="3"
                                className="form-control tenant-comments"
                                value={developerSummary || ''}
                                onChange={amendSummary}
                            />
                        </div>
                    </>
                )}
            </div>
            <div className="admin-footer">
                <button
                    type="button"
                    className="btn btn-primary outcome tenant-save"
                    onClick={() => {
                        if (!isConfigValid) {
                            return;
                        }

                        save();
                        if (!environments && tabs) {
                            tabs.forEach((tab) => {
                                if (
                                    tab.type === TAB_TYPES.environment ||
                                    tab.type === TAB_TYPES.environments
                                ) {
                                    closeTab(tab.key, tenantId, navigate);
                                }
                            });
                        }
                        if (!themes && tabs) {
                            tabs.forEach((tab) => {
                                if (tab.type === TAB_TYPES.themes) {
                                    closeTab(tab.key, tenantId, navigate);
                                }
                            });
                        }
                        if (themes && tabs) {
                            tabs.forEach((tab) => {
                                if (tab.type === TAB_TYPES.players) {
                                    closeTab(tab.key, tenantId, navigate);
                                }
                            });
                        }
                    }}
                >
                    {translations.TENANT_save_settings_button_label}
                </button>
            </div>
        </>
    );
};

const mapStateToProps = ({ currentTenant, tabs }) => ({
    currentTenant,
    tabs,
});

const mapDispatchToProps = {
    // renamed to avoid shadowing (and appease the linter)
    getCurrentTenant: fetchCurrentTenant,
    amendSummary: updateSummary,
    updateEnvironmentSetting: updateTenantSettingEnvironment,
    updateThemeSetting: updateTenantSettingThemes,
    storeTenant: saveTenant,
    notifyError: notifyErrorAction,
    closeTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tenant);
