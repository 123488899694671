import { useEffect, useState } from 'react';
import { type FlowLaunchesDashboardResponse, getLaunchesForTenant } from '../../sources/dashboard';
import translations from '../../translations';
import type { NotifyError } from '../../types';
import { View } from './DeveloperDashboard';
import { sortFlowLaunchesByCount } from '../../utils/dashboard';
import DeveloperDashboardLaunchesChart from './DeveloperDashboardLaunchesChart';
import FlowLaunchesTable from './FlowLaunchesTable';

interface Props {
    notifyError: NotifyError;
    setCurrentView: (view: View) => void;
}

const DeveloperDashboardLaunchesSummary = ({ notifyError, setCurrentView }: Props) => {
    const [flowsLaunches, setFlowsLaunches] = useState<FlowLaunchesDashboardResponse[]>([]);

    useEffect(() => {
        getLaunchesForTenant('day')
            .then((response) => setFlowsLaunches(response))
            .catch(notifyError);
    }, [notifyError]);

    // We only want the top 5 flow launches for displaying in the table, sorted by count.
    // Flow launches without a name exist so the chart knows it's bounds, we filter these out just for the table.
    const topFlowLaunchesForTable = sortFlowLaunchesByCount(
        flowsLaunches.filter((fl) => fl.flowName !== null),
    ).slice(0, 5);

    return (
        <>
            <span className="title-bar">
                <h2>{translations.DASHBOARD_flow_usage_header}</h2>
            </span>
            <div className="developer-dash-wrapper">
                <div className="developer-dash-chart-wrapper">
                    <DeveloperDashboardLaunchesChart
                        flowLaunches={flowsLaunches}
                        dateRange="day"
                        chartClass="launches-chart"
                    />
                </div>

                <div className="developer-dash-table-wrapper">
                    <h2 className="developer-dash-table-header">
                        {translations.DASHBOARD_top_launches}
                    </h2>
                    {topFlowLaunchesForTable.length > 0 ? (
                        <FlowLaunchesTable flowLaunches={topFlowLaunchesForTable} />
                    ) : (
                        <button
                            className="link-emulate"
                            onClick={() => setCurrentView(View.launches)}
                            type="button"
                        >
                            {translations.DASHBOARD_no_launches}
                        </button>
                    )}
                    {topFlowLaunchesForTable.length > 0 ? (
                        <button
                            className="developer-dash-view-all link-emulate"
                            onClick={() => setCurrentView(View.launches)}
                            type="button"
                        >
                            {translations.DASHBOARD_view_all}
                        </button>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default DeveloperDashboardLaunchesSummary;
