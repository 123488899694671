import { fetchAndParse } from '../../ts/utils/ajax';
import type { OpenApiAPI } from '../types';
import { getTenantId } from '../utils/tenant';

export const getOpenApiSchemaInfoFromValueId = ({
    valueId,
    operationType,
}: {
    valueId: string | null | undefined;
    operationType: string;
}) => {
    if (!valueId) {
        return;
    }
    return fetchAndParse<OpenApiAPI>({
        url: `/api/draw/1/openapi/info/value/${valueId}/${operationType}`,
        method: 'GET',
        headers: {
            ManyWhoTenant: getTenantId(),
            'Content-Type': 'application/json; charset=utf-8',
        },
    });
};
