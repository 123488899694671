import { useEffect, useState } from 'react';
import translations from '../../translations';
import { View } from './DeveloperDashboard';
import type { NotifyError } from '../../types';
import { type FlowLaunchesDashboardResponse, getLaunchesForTenant } from '../../sources/dashboard';
import GenericSearchBox from '../generic/GenericSearchBox';
import { ExSegmentedControl, ExSegmentedControls } from '@boomi/exosphere';
import type { ExSelectionChangeDetail, TimeFilters } from '../../types/dashboard';
import { sortFlowLaunchesByCount } from '../../utils/dashboard';
import DeveloperDashboardLaunchesChart from './DeveloperDashboardLaunchesChart';
import FlowLaunchesTable from './FlowLaunchesTable';

interface Props {
    notifyError: NotifyError;
    setCurrentView: (view: View) => void;
}

const DeveloperDashboardLaunchesFull = ({ notifyError, setCurrentView }: Props) => {
    const [flowsLaunches, setFlowsLaunches] = useState<FlowLaunchesDashboardResponse[]>([]);
    const [filteredLaunches, setFilteredLaunches] = useState<FlowLaunchesDashboardResponse[]>([]);
    const [dateRange, setDateRange] = useState<TimeFilters>('week');
    const [search, setSearch] = useState('');

    useEffect(() => {
        getLaunchesForTenant(dateRange)
            .then((response) => setFlowsLaunches(response))
            .catch(notifyError);
    }, [notifyError, dateRange]);

    useEffect(() => {
        if (search !== '') {
            const filteringFlows = flowsLaunches.filter((flow) => {
                if (!flow.flowName) {
                    return;
                }

                return flow.flowName.toLowerCase().trim().includes(search.toLowerCase().trim());
            });

            setFilteredLaunches(filteringFlows);
        } else {
            setFilteredLaunches(flowsLaunches);
        }
    }, [search, flowsLaunches]);

    const indexFilterTimeMapping: TimeFilters[] = ['day', 'week', 'month', 'year'];

    const tableData = sortFlowLaunchesByCount(
        filteredLaunches.filter((fl) => fl.flowName !== null),
    );

    return (
        <>
            <span className="title-bar">
                <h1>
                    <button
                        className="link-emulate"
                        onClick={() => setCurrentView(View.summary)}
                        type="button"
                    >
                        {translations.DASHBOARD_header}
                    </button>
                    {' > '}
                    {translations.DASHBOARD_launches_header}
                </h1>
            </span>
            <div className="developer-dash-control-bar">
                <div className="developer-dash-search">
                    <GenericSearchBox
                        onSearchChange={(e) => setSearch(e.currentTarget.value)}
                        searchText={search}
                        hasSearchButton
                        hasAutoFocus={false}
                    />
                </div>
                <ExSegmentedControls
                    onSelectionChange={(e: CustomEvent) => {
                        setDateRange(
                            indexFilterTimeMapping[
                                (e.detail as ExSelectionChangeDetail).selectedIndex
                            ],
                        );
                    }}
                >
                    <ExSegmentedControl
                        tooltiptext={translations.DASHBOARD_filter_last_day_tooltip}
                        label={translations.DASHBOARD_filter_last_day}
                        selected={dateRange === 'day'}
                    />
                    <ExSegmentedControl
                        tooltiptext={translations.DASHBOARD_filter_last_week_tooltip}
                        label={translations.DASHBOARD_filter_last_week}
                        selected={dateRange === 'week'}
                    />
                    <ExSegmentedControl
                        tooltiptext={translations.DASHBOARD_filter_last_month_tooltip}
                        label={translations.DASHBOARD_filter_last_month}
                        selected={dateRange === 'month'}
                    />
                    <ExSegmentedControl
                        tooltiptext={translations.DASHBOARD_filter_last_year_tooltip}
                        label={translations.DASHBOARD_filter_last_year}
                        selected={dateRange === 'year'}
                    />
                    <ExSegmentedControl disabled icon="Calendar" />
                </ExSegmentedControls>
            </div>
            <DeveloperDashboardLaunchesChart
                flowLaunches={filteredLaunches}
                dateRange={dateRange}
                chartClass="launches-chart-full"
            />
            <FlowLaunchesTable flowLaunches={tableData} />
        </>
    );
};

export default DeveloperDashboardLaunchesFull;
