import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getFeatureFlags, enableFeatureFlag } from '../../../ts/sources/featureFlags';
import Toggle from '../../../ts/components/inputs/Toggle';
import { notifyError as notifyErrorAction } from '../../actions/reduxActions/notification';

const Features = ({ notifyError }) => {
    const [features, setFeatures] = useState([]);

    const fetchFeatureFlags = () => getFeatureFlags().then(setFeatures);

    const enableFeature = ({ id, enabled }) => {
        const updatedFeatures = features.map((feature) => {
            if (feature.id === id) {
                return {
                    ...feature,
                    enabled,
                };
            }
            return feature;
        });

        setFeatures(updatedFeatures);
        enableFeatureFlag({ featureFlagId: id, enabled })
            .then(fetchFeatureFlags)
            .catch(notifyError);
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        fetchFeatureFlags().catch(notifyError);
    }, []);

    return (
        <span className="admin-transition">
            <div className="flex-column full-height">
                <div className="admin-page">
                    <h1>Features</h1>
                    <p>
                        Below is a list of currently available features for this tenant. Some of
                        these features are still in development and experimental. Enabling a feature
                        could cause unexpected results or corrupt data. The functionality of these
                        features will change without warning.
                    </p>
                    <ul>
                        {features
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((feature) => (
                                <li key={feature.id} className="margin-bottom">
                                    <p>
                                        <strong>{feature.name}</strong>
                                    </p>
                                    <p>{feature.description}</p>
                                    <Toggle
                                        isOn={feature.enabled}
                                        onChange={({ isOn }) =>
                                            enableFeature({ id: feature.id, enabled: isOn })
                                        }
                                    />
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
        </span>
    );
};

const mapDispatchToProps = { notifyError: notifyErrorAction };

export default connect(null, mapDispatchToProps)(Features);
