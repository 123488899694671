import { Component } from 'react';
import CodeEditor from '../../ts/components/generic/CodeEditor';
import Modal from '../../ts/components/generic/modal/GenericModal';
import '../../../css/build.less';
import '../../../css/metadata-editor.less';
import {
    getGroupElement,
    getMapElement,
    saveGroupElement,
    saveMapElement,
} from '../../ts/sources/graph';
import { GRAPH_ELEMENT_TYPES } from '../../ts/constants';
import Loader from '../../ts/components/loader/Loader';
import ButtonPrimary from '../../ts/components/buttons/ButtonPrimary';
import ButtonDefault from '../../ts/components/buttons/ButtonDefault';
import { AlertBannerType, ExAlertBanner } from '@boomi/exosphere';

class MetadataEditor extends Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.save = this.save.bind(this);
        this.onClearAlert = this.onClearAlert.bind(this);
        this.onHide = this.onHide.bind(this);

        this.state = {
            metadata: null,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.isVisible && !prevProps.isVisible) {
            this.setState({ isLoading: true });

            let getElement = null;
            if (this.props.elementType?.toLowerCase() === GRAPH_ELEMENT_TYPES.map) {
                getElement = getMapElement;
            }
            if (this.props.elementType?.toLowerCase() === GRAPH_ELEMENT_TYPES.group) {
                getElement = getGroupElement;
            }
            if (getElement) {
                getElement(this.props.id, this.props.flowId, this.props.editingToken)
                    .then((response) =>
                        this.setState({
                            metadata: JSON.stringify(response, null, 4),
                            isLoading: false,
                        }),
                    )
                    .catch(({ message }) => {
                        this.setState({
                            isLoading: false,
                            alert: { style: 'danger', message, icon: 'exclamation-sign' },
                        });
                    });
            }
        }
    }

    onChange(value) {
        this.setState({ metadata: value });
        this.onClearAlert();
    }

    onHide() {
        this.props.toggleMetadata();
        this.onClearAlert();
    }

    onClearAlert() {
        this.setState({ alert: null });
    }

    async save() {
        this.setState({ isLoading: true });

        try {
            const json = JSON.parse(this.state.metadata);

            let saveElement = null;
            if (this.props.elementType?.toLowerCase() === GRAPH_ELEMENT_TYPES.map) {
                saveElement = saveMapElement;
            }
            if (this.props.elementType?.toLowerCase() === GRAPH_ELEMENT_TYPES.group) {
                saveElement = saveGroupElement;
            }
            if (saveElement) {
                await saveElement(json, this.props.flowId, this.props.editingToken);
                this.setState({
                    isLoading: false,
                    alert: {
                        style: 'success',
                        message: 'Map Element saved successfully',
                        icon: 'ok',
                    },
                });
                setTimeout(() => {
                    this.onClearAlert();
                }, 3000);
            }
        } catch ({ message }) {
            this.setState({
                isLoading: false,
                alert: { style: 'danger', message, icon: 'exclamation-sign' },
            });
        }
    }

    render() {
        // flowKey if editiing within a page or flowId if within a flow
        const uniqueEditorId = this.props.flowKey || this.props.flowId;

        const saveButton = <ButtonPrimary onClick={this.save}>Save</ButtonPrimary>;
        const closeButton = <ButtonDefault onClick={this.onHide}>Close</ButtonDefault>;

        return (
            <Modal
                show={this.props.isVisible}
                onHide={this.onHide}
                container={this.props.container}
                className="metadata-editor config-modal"
                title="Edit Metadata"
                renderBody={() => (
                    <>
                        {this.state.alert && (
                            <ExAlertBanner
                                className="block margin-bottom"
                                type={
                                    this.state.alert.style === 'danger'
                                        ? AlertBannerType.ERROR
                                        : AlertBannerType.SUCCESS
                                }
                                open
                                onClick={this.onClearAlert}
                            >
                                {this.state.alert.message}
                            </ExAlertBanner>
                        )}
                        <CodeEditor
                            value={this.state.metadata}
                            name={`metadata-editor-${uniqueEditorId}`}
                            onChange={this.onChange}
                        />
                        <ExAlertBanner type={AlertBannerType.WARNING} open>
                            Saving changes to the metadata will overwrite any un-saved changes in
                            the editor
                        </ExAlertBanner>
                        {this.state.isLoading && <Loader />}
                    </>
                )}
                renderFooter={() => (
                    <>
                        {closeButton}
                        {saveButton}
                    </>
                )}
            />
        );
    }
}

export default MetadataEditor;
