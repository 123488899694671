import Footer from '../../generic/Footer';
import { useValues } from './ValuesProvider';
import CopyableText from '../../generic/CopyableText';
import Dependents from '../../../../js/components/admin/Dependents';
import { getValue, saveValue } from '../../../sources/value';
import { useEffect, useState, useRef } from 'react';
import ValueEditor, { isDuplicateValueByName, isValid } from '../../values/editor/ValueEditor';
import { ButtonFlavor, ButtonType, ExButton, ExLoader } from '@boomi/exosphere';
import translations from '../../../translations';
import ConfirmModal from '../../generic/modal/ConfirmModal';
import { NOTIFICATION_TYPES, SYSTEM_VALUE_IDS } from '../../../constants';
import { stringReplace } from '../../../utils/string';
import type { ValueElementResponseAPI } from '../../../types';

const ValueDetail = () => {
    const { valueToEdit, returnToValueList, tenantId, addNotification } = useValues();
    const [value, setValue] = useState<ValueElementResponseAPI>(valueToEdit);
    const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const [duplicateWarning, setDuplicateWarning] = useState<boolean>(false);

    const modalContainerRef = useRef(null);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const load = async () => {
            if (!valueToEdit.id) {
                return;
            }

            setSaving(true);

            try {
                setValue(await getValue(valueToEdit.id));
            } catch (error) {
                addNotification({
                    type: NOTIFICATION_TYPES.error,
                    isPersistent: true,
                    message: (error as Error).message,
                });
            } finally {
                setSaving(false);
            }
        };

        load();
    }, []);

    const onSubmit = async () => {
        setDuplicateWarning(false);

        if (SYSTEM_VALUE_IDS.includes(value.id)) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                isPersistent: true,
                message: 'Saving system values is not allowed.',
            });
        } else {
            setHasSubmitted(true);

            if (isValid(value)) {
                setSaving(true);

                try {
                    if (await isDuplicateValueByName(value)) {
                        setDuplicateWarning(true);
                        return;
                    }
                } catch (error) {
                    addNotification({
                        type: NOTIFICATION_TYPES.error,
                        isPersistent: true,
                        message: (error as Error).message,
                    });
                } finally {
                    setSaving(false);
                }

                await onSave();
            }
        }
    };

    const onSave = async () => {
        setSaving(true);

        try {
            await saveValue(value);
            returnToValueList();
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                isPersistent: true,
                message: (error as Error).message,
            });
        } finally {
            setSaving(false);
        }
    };

    const trimmedName = value.developerName.trim();

    return (
        <>
            <div className="admin-page value-detail" ref={modalContainerRef}>
                <h1>Value: {value.developerName || ''}</h1>
                {value.id ? (
                    <CopyableText
                        wrapperClassName="value-id"
                        copyableText={value.id}
                        labelText="ID: "
                        hasCopyButton={true}
                    />
                ) : null}
                <ConfirmModal
                    show={duplicateWarning}
                    title={`Are you sure you want to name your Value: ${trimmedName}?`}
                    messages={[
                        stringReplace(translations.VS_duplicate_value_1, trimmedName),
                        translations.VS_duplicate_value_2,
                    ]}
                    onCancel={() => setDuplicateWarning(false)}
                    onConfirm={onSave}
                    container={modalContainerRef.current}
                />
                <ValueEditor value={value} onChange={setValue} showValidation={hasSubmitted} />
                {valueToEdit.id ? <Dependents id={valueToEdit.id} tenantId={tenantId} /> : null}
                {saving ? <ExLoader /> : null}
            </div>
            <Footer>
                <ExButton
                    title="Back"
                    onClick={returnToValueList}
                    flavor={ButtonFlavor.BRANDED}
                    type={ButtonType.SECONDARY}
                >
                    Back
                </ExButton>
                <ExButton
                    title="Save Value"
                    onClick={onSubmit}
                    flavor={ButtonFlavor.BRANDED}
                    type={ButtonType.PRIMARY}
                >
                    Save Value
                </ExButton>
            </Footer>
        </>
    );
};

export default ValueDetail;
